@use '@angular/material' as mat;
@import "@angular/material/theming";

$custom-typography: mat.define-legacy-typography-config(
  $font-family: 'Montserrat'
);

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($custom-typography);`
@include mat.all-legacy-component-typographies($custom-typography);
@include mat.legacy-core();

@import 'theme-reset';
.app{
    &.green{
        @import "skins/green";
        @include mat.all-legacy-component-themes($green-theme);
        @include theme-reset($green-theme);
    }
    &.blue{
        @import "skins/blue";
        @include mat.all-legacy-component-themes($blue-theme);
        @include theme-reset($blue-theme);
    }
    &.red{
        @import "skins/red";
        @include mat.all-legacy-component-themes($red-theme);
        @include theme-reset($red-theme);
    }
    &.pink{
        @import "skins/pink";
        @include mat.all-legacy-component-themes($pink-theme);
        @include theme-reset($pink-theme);
    }
    &.purple{
        @import "skins/purple";
        @include mat.all-legacy-component-themes($purple-theme);
        @include theme-reset($purple-theme);
    }
    &.grey{
        @import "skins/grey";
        @include mat.all-legacy-component-themes($grey-theme);
        @include theme-reset($grey-theme);
    }
    &.orange-dark{
        @import "skins/orange-dark";
        @include mat.all-legacy-component-themes($orange-dark-theme);
        @include theme-reset($orange-dark-theme);     
    }
}