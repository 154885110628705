/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import "app/theme/styles/spinner";
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import '~swiper/swiper-bundle.min.css';
@import '~snazzy-info-window/dist/snazzy-info-window.css'; 
@import "app/theme/styles/base";
@import "app/theme/styles/spaces";
@import "app/theme/styles/theme";
@import "app/theme/styles/rtl";
 @import 'ngx-sharebuttons/themes/material/material-dark-theme';
 @import 'ngx-sharebuttons/themes/modern/modern-light-theme';
 @import 'ngx-sharebuttons/themes/circles/circles-dark-theme';

.img-set{
    width: 100% !important;
    height: 100% !important;
}
.translateBtn{
    position: absolute !important;
    right: 5px;
    top: 5px;
 }