/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import '~swiper/swiper-bundle.min.css';
@import '~snazzy-info-window/dist/snazzy-info-window.css';
.spinner-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: #fff;
  visibility: visible;
  opacity: 1;
  -webkit-transition: visibility 0.5s, opacity 0.3s linear;
  -moz-transition: visibility 0.5s, opacity 0.3s linear;
  transition: visibility 0.5s, opacity 0.3s linear;
}
.spinner-wrapper.hide {
  visibility: hidden;
  opacity: 0;
}
.spinner-wrapper .spinner-container {
  height: 100%;
  width: 100%;
  display: table;
}
.spinner-wrapper .spinner-container .spinner-outer {
  vertical-align: middle;
  height: 100%;
  display: table-cell;
}
.spinner-wrapper .spinner-container .spinner-outer .spinner {
  position: relative;
  margin: 0 auto;
  height: 50px;
  width: 70px;
  box-sizing: border-box;
  animation: main 2s ease-in infinite;
}
.spinner-wrapper .spinner-container .spinner-outer .spinner .mask {
  box-sizing: border-box;
  overflow: hidden;
  position: absolute;
  border-radius: 3px;
}
.spinner-wrapper .spinner-container .spinner-outer .spinner .plane {
  background: #1976d2;
  width: 100%;
  height: 100%;
  position: absolute;
}
.spinner-wrapper .spinner-container .spinner-outer .spinner .left {
  width: 18px;
  height: 100%;
  left: 0;
}
.spinner-wrapper .spinner-container .spinner-outer .spinner .left .plane {
  bottom: -100%;
  animation: anim1 2s ease-in infinite;
}
.spinner-wrapper .spinner-container .spinner-outer .spinner .top {
  width: 100%;
  height: 18px;
  top: 0;
  border-radius: 1px;
}
.spinner-wrapper .spinner-container .spinner-outer .spinner .top .plane {
  left: -100%;
  animation: anim2 2s ease-in infinite;
}
.spinner-wrapper .spinner-container .spinner-outer .spinner .right {
  width: 18px;
  height: 100%;
  right: 0;
}
.spinner-wrapper .spinner-container .spinner-outer .spinner .right .plane {
  top: -100%;
  animation: anim3 2s ease-in infinite;
}
.spinner-wrapper .spinner-container .spinner-outer .spinner .triangle {
  box-sizing: border-box;
  overflow: hidden;
  position: absolute;
  width: 50px;
  height: 50px;
  top: -25px;
  left: 10px;
  transform: rotate(-45deg);
}
.spinner-wrapper .spinner-container .spinner-outer .spinner .triangle .triangle-plane {
  border: 50px solid transparent;
  border-right: 0;
  border-top-color: #1976d2;
  height: 0;
  width: 0;
  position: absolute;
  animation: anim4 2s ease-in infinite;
}
.spinner-wrapper .spinner-container .spinner-outer .spinner .top-left {
  width: 100%;
  height: 9px;
  transform: rotate(135deg);
  left: -22px;
  top: -28px;
}
.spinner-wrapper .spinner-container .spinner-outer .spinner .top-left .plane {
  left: 100%;
  animation: anim5 2s ease-in infinite;
}
.spinner-wrapper .spinner-container .spinner-outer .spinner .top-right {
  width: 100%;
  height: 9px;
  transform: rotate(45deg);
  right: -22px;
  top: -28px;
}
.spinner-wrapper .spinner-container .spinner-outer .spinner .top-right .plane {
  right: 100%;
  animation: anim6 2s ease-in infinite;
}
.spinner-wrapper .spinner-container .spinner-outer p.spinner-text {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}

@keyframes main {
  0% {
    opacity: 0;
  }
  10%, 80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes anim1 {
  0% {
    bottom: -100%;
  }
  10%, 100% {
    bottom: 0%;
  }
}
@keyframes anim2 {
  0%, 10% {
    left: -100%;
  }
  20%, 100% {
    left: 0%;
  }
}
@keyframes anim3 {
  0%, 20% {
    top: -100%;
  }
  30%, 100% {
    top: 0%;
  }
}
@keyframes anim4 {
  0%, 30% {
    top: -100%;
  }
  40%, 100% {
    top: 0%;
  }
}
@keyframes anim5 {
  0%, 40% {
    left: 100%;
  }
  50%, 100% {
    left: 0%;
  }
}
@keyframes anim6 {
  0%, 50% {
    right: 100%;
  }
  60%, 100% {
    right: 0%;
  }
}
.mat-icon-button1 {
  position: fixed !important;
  right: 112px;
  top: 0px;
  padding: 0;
  min-width: 0;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  line-height: 40px;
  border-radius: 50%;
  background-color: rgb(255, 255, 255) !important;
}

/******* Base styles *******/
* {
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
}

body {
  height: 100%;
  font-family: "Montserrat";
  font-size: 14px;
  line-height: 1.7;
}

p {
  margin-bottom: 16px;
}

a {
  outline: none;
}

.h-100 {
  height: 100% !important;
}

.w-100 {
  width: 100% !important;
}

.mw-100 {
  max-width: 100%;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.d-block {
  display: block;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.transition {
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  transition: 0.3s;
}

.text-truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden !important;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.ws-nowrap {
  white-space: nowrap;
}

.m-auto {
  margin: 0 auto !important;
}

.theme-container {
  max-width: 1300px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
}

.app-menu-panel.mat-menu-panel {
  min-width: 60px;
}
.app-menu-panel.mat-menu-panel .mat-menu-content:not(:empty) {
  padding: 0;
}
.app-menu-panel.mat-menu-panel .mat-menu-item {
  display: flex;
  align-items: center;
  font-size: 12px;
  text-transform: uppercase;
}
.app-menu-panel.mat-menu-panel .mat-menu-item .mat-badge-small.mat-badge-above .mat-badge-content {
  top: 8px;
}

.social-icon {
  display: inherit;
  color: inherit;
}
.social-icon svg.mat-icon.mat-icon-lg {
  height: 37px;
}

/******* Material Icons *******/
.mat-icon-button .mat-icon.mat-icon-xs,
.mat-icon.mat-icon-xs {
  font-size: 14px;
  line-height: 14px;
  height: 14px;
  width: 14px;
  vertical-align: middle;
}

.mat-icon-button .mat-icon.mat-icon-sm,
.mat-icon.mat-icon-sm,
.mat-nav-list .mat-list-item .mat-list-icon.mat-icon-sm {
  font-size: 18px;
  line-height: 18px;
  height: 18px;
  width: 18px;
  vertical-align: middle;
}

.mat-icon-button .mat-icon.mat-icon-lg,
.mat-icon.mat-icon-lg {
  font-size: 36px;
  line-height: 36px;
  height: 36px;
  width: 36px;
  vertical-align: middle;
}

.mat-icon-button .mat-icon.mat-icon-xlg,
.mat-icon.mat-icon-xlg {
  font-size: 48px;
  line-height: 48px;
  height: 48px;
  width: 48px;
  vertical-align: middle;
}

.mat-icon.caret {
  width: 14px;
}

.mat-snack-bar-container button {
  font-size: 30px;
}

.sidenav .mat-drawer-inner-container {
  overflow-x: hidden;
}

.horizontal-menu .mat-button {
  height: 38px;
  line-height: 38px;
  text-transform: uppercase;
}

.horizontal-sub-menu .mat-button {
  display: flex;
}
.horizontal-sub-menu .mat-button, .horizontal-sub-menu .mat-menu-item {
  height: 38px;
  line-height: 38px;
  font-weight: 500;
  text-transform: uppercase;
}

/******* Theme dialog *******/
.theme-dialog .header {
  margin: -24px -24px 0px -24px;
  padding: 8px 8px 8px 24px;
}
.theme-dialog .mat-dialog-container .mat-dialog-content {
  max-height: 80vh;
}
.theme-dialog.fullscreen {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  transform: none !important;
}
.theme-dialog.fullscreen .mat-dialog-container {
  overflow: hidden;
}
.theme-dialog.fullscreen .mat-dialog-container .mat-dialog-content {
  max-height: calc(100vh - 56px);
}

/******* Header *******/
#main-toolbar {
  position: relative;
  height: 72px;
  transition: 0.2s;
  z-index: 9;
}

.top-toolbar.mat-toolbar {
  height: 36px !important;
  font-size: 12px !important;
}
.top-toolbar.mat-toolbar .v-divider {
  width: 1px;
  height: 26px;
  margin: 0 16px;
}
.top-toolbar.mat-toolbar .mat-button {
  font-size: 12px;
  padding: 0 10px;
}

.logo img {
  display: block;
  width: auto;
  max-width: 100%;
}
.logo svg {
  display: block;
}

.user-menu {
  width: 220px;
}
.user-menu .user-info {
  padding: 16px;
}
.user-menu .user-info img {
  border-radius: 4px;
  margin-right: 16px;
}

.flag-name {
  margin-left: 4px;
}

header.toolbar-2 .top-toolbar.mat-toolbar {
  height: auto;
}
header.toolbar-2 .top-toolbar.mat-toolbar .mat-toolbar-row:first-child {
  height: 36px;
}
header.toolbar-2 .top-toolbar.mat-toolbar .mat-toolbar-row:first-child .right-section {
  height: 36px;
}
header.toolbar-2 .top-toolbar.mat-toolbar .mat-toolbar-row:nth-child(2) {
  height: auto;
  font-size: 14px;
  font-style: italic;
  padding-top: 8px;
  padding-bottom: 44px;
}
header.toolbar-2 .top-toolbar.mat-toolbar .mat-toolbar-row:nth-child(2) .item {
  width: 100%;
  white-space: normal;
  line-height: 1.5;
  padding: 0 8px;
}
header.has-bg-image #main-toolbar, header.has-bg-video #main-toolbar {
  box-shadow: none;
}
header.has-bg-image.main-toolbar-fixed #main-toolbar, header.has-bg-video.main-toolbar-fixed #main-toolbar {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
header.has-bg-image:not(.main-toolbar-fixed) #main-toolbar, header.has-bg-video:not(.main-toolbar-fixed) #main-toolbar {
  background: transparent !important;
}

/******* Pages *******/
.main-content-header {
  position: relative;
  z-index: 2;
  margin-top: 24px;
  padding: 24px;
  padding-bottom: 1px !important;
}

main.content-offset-to-top .main-content-header {
  margin-top: 0px;
}

/******* APP *******/
.app {
  height: 100%;
}
.app.main-toolbar-fixed #main-toolbar {
  position: fixed;
  top: 0px;
  width: 100%;
}
.app.main-toolbar-fixed .page-wrapper {
  padding-top: 72px;
}

/******* Properties *******/
.properties-wrapper {
  margin: 8px -8px;
}
.properties-wrapper .item {
  padding: 8px;
}

.price::first-letter {
  padding-right: 4px;
}

.all-properties .search-sidenav {
  float: left;
  position: relative;
  margin-bottom: 16px;
  width: 288px;
  border: 0 !important;
  box-shadow: none !important;
  background-color: transparent !important;
}
.all-properties .search-sidenav .mat-drawer-inner-container {
  padding: 2px;
  height: 100%;
  box-sizing: border-box;
}
.all-properties .search-sidenav .mat-drawer-inner-container .mat-card {
  height: 100%;
  box-sizing: border-box;
}
.all-properties .mat-drawer-content {
  min-height: 600px;
  padding: 2px;
  overflow: hidden;
}
.all-properties .mat-drawer-content.distance {
  padding-left: 16px;
}
.all-properties .mat-drawer-backdrop {
  bottom: 16px;
}
.all-properties .mat-paginator-outer-container {
  font-size: 14px;
}

.search-wrapper {
  margin: -8px;
}
.search-wrapper .mat-form-field {
  width: 100%;
}
.search-wrapper.variant-1 .mat-form-field-wrapper {
  padding-bottom: 8px;
}
.search-wrapper.variant-2 .mat-form-field-wrapper {
  padding-bottom: 0px;
}
.search-wrapper button {
  min-width: 120px;
}
.search-wrapper button.search-btn .mat-button-wrapper {
  display: flex;
  align-items: center;
  place-content: space-between;
  text-transform: uppercase;
}

/******* Single property *******/
.single-property .mat-drawer {
  width: 360px;
  border: 0 !important;
  box-shadow: none !important;
  background-color: transparent !important;
}
.single-property .mat-drawer .mat-drawer-inner-container {
  padding: 2px;
  height: 100%;
  box-sizing: border-box;
}
.single-property .mat-drawer .mat-drawer-inner-container .mat-card {
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
}
.single-property .mat-drawer .mat-drawer-inner-container button.close {
  position: absolute;
  top: 2px;
  right: 2px;
  z-index: 1;
}
.single-property .mat-drawer-content {
  padding: 2px;
}
.single-property .mat-drawer-content.distance {
  padding-right: 16px;
}
.single-property .mat-drawer-content .details .item span:first-child {
  margin-right: 8px;
  font-weight: 500;
  min-width: 114px;
  display: inline-block;
}
.single-property .mat-drawer-content .details .item .list span {
  font-weight: normal;
  min-width: auto;
}
.single-property .mat-drawer-content .details .item .list span:not(.last):after {
  content: ",";
}
.single-property .mat-drawer-content .details .item .list span.last:after {
  content: none;
}
.single-property .mat-drawer-content .sebm-google-map-container {
  height: 350px;
}
.single-property .mat-drawer-content .mat-expansion-panel-body {
  text-align: center;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/******* Comments *******/
.mat-list.reviews.mat-list-base .mat-list-item .mat-list-item-content {
  align-items: flex-start;
}
.mat-list.reviews .mat-list-item .mat-list-avatar.review-author {
  width: 80px;
  height: 80px;
}
.mat-list.reviews .mat-list-item .mat-line.text {
  white-space: unset;
  font-style: italic;
  margin: 10px 0 36px;
}

.comment-form {
  margin: -16px;
  padding: 8px;
}

/******* Pricing *******/
.pricing-tabs.mat-tab-group {
  margin: 0 -8px;
}

.pricing-tabs.mat-tab-group .mat-tab-header {
  border-bottom: none !important;
  margin-bottom: 16px;
}

.pricing-card.mat-card {
  padding: 0;
  overflow: hidden;
  border-width: 4px 0 0 0;
  border-style: solid;
}
.pricing-card.mat-card .pricing-title {
  text-transform: uppercase;
  font-weight: 500;
}
.pricing-card.mat-card .pricing-header {
  min-height: 126px;
}
.pricing-card.mat-card .pricing-header small {
  font-weight: normal;
}
.pricing-card.mat-card .del {
  text-decoration: line-through;
}

/******* Account *******/
.account .mat-drawer {
  width: 280px;
  border: 0 !important;
  box-shadow: none !important;
  background-color: transparent !important;
}
.account .mat-drawer .mat-drawer-inner-container {
  padding: 2px;
  height: 100%;
  box-sizing: border-box;
}
.account .mat-drawer .mat-drawer-inner-container .mat-card {
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
}
.account .mat-drawer .mat-drawer-inner-container .mat-card a {
  color: inherit;
  text-decoration: none;
}
.account .mat-drawer .mat-drawer-inner-container button.close {
  position: absolute;
  top: 2px;
  right: 2px;
  z-index: 1;
}
.account .mat-drawer-content {
  padding: 2px;
  min-height: 400px;
}
.account .mat-drawer-content.distance {
  padding-left: 16px;
}

.table-wrapper {
  display: block;
  overflow-x: auto;
}
.table-wrapper td.mat-cell,
.table-wrapper th.mat-header-cell {
  white-space: nowrap;
  padding: 16px;
}
.table-wrapper a.property-name {
  color: inherit;
  text-decoration: none;
  font-weight: 500;
}

/******* Submit Property *******/
.submit-property .step-content {
  max-width: 760px;
  margin: 0 auto;
}
.submit-property .sebm-google-map-container {
  height: 400px;
}
.submit-property .step-section {
  padding: 20px 10px 0;
  border-radius: 4px;
  margin: 8px 8px 16px;
}

/******* Footer *******/
footer {
  position: relative;
  overflow: hidden;
  background: rgba(33, 33, 33, 0.93);
  color: #fff;
}
footer:before {
  content: "";
  position: absolute;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-image: url("~assets/images/others/homepage.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
footer .content {
  border-width: 0px 0px 1px 0px;
  border-style: solid;
}
footer .content .logo svg {
  fill: #fff;
}
footer .content .desc {
  color: rgba(255, 255, 255, 0.7);
}
footer .content .custom-form .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.3);
}
footer .content .custom-form .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: white;
}
footer .content .custom-form .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
footer .content .custom-form .subscribe-input .mat-form-field-wrapper {
  padding: 0;
  margin: 0;
}
footer .content .custom-form .subscribe-input .mat-form-field-infix {
  top: -4px;
}
footer .content .custom-form .subscribe-input .mat-input-element::placeholder {
  color: rgba(255, 255, 255, 0.7);
}
footer .content .custom-form .subscribe-input .mat-input-element::-moz-placeholder {
  color: rgba(255, 255, 255, 0.7);
}
footer .content .custom-form .subscribe-input .mat-input-element::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}
footer .content .custom-form .subscribe-input .mat-input-element:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}
footer .content .custom-form .subscribe-btn {
  margin-left: -4px;
  border-radius: 0 4px 4px 0;
  text-transform: uppercase;
}
footer .content .sebm-google-map-container {
  height: 308px;
}
footer .copyright {
  color: rgba(255, 255, 255, 0.7);
  font-size: 12px;
}
footer .copyright p {
  margin: 0;
}

/******* Carousel *******/
button.swipe-arrow {
  position: absolute;
  top: 50%;
  margin-top: -22px;
  z-index: 10;
  background-image: none !important;
}
button.swipe-arrow.transparent {
  background: transparent !important;
  box-shadow: none;
}

.swiper-pagination.white .swiper-pagination-bullet {
  background: #fff;
}

.swiper-pagination-bullet-active {
  width: 10px;
  height: 10px;
  vertical-align: -1px;
}

.carousel-outer {
  margin: -2px;
  padding: 2px;
}

.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after,
.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
  content: none;
}

/******* Section *******/
.section {
  position: relative;
  overflow: hidden;
  margin-top: 40px;
  padding: 40px 0;
}
.section:before {
  content: "";
  position: absolute;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  opacity: 0.15;
}
.section.testimonials:before {
  background-image: url("~assets/images/props/flat-1/3-big.jpg");
}
.section.agents:before {
  background-image: url("~assets/images/props/office-2/4-big.jpg");
}
.section.default:before {
  background-image: url("~assets/images/others/default-bg.png");
  background-repeat: repeat;
  background-size: 350px;
  background-position: center;
  opacity: 1;
}
.section .section-title {
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 40px;
  position: relative;
}
.section .section-title:after {
  content: "";
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 160px;
  margin: 0 auto;
  height: 2px;
}

/******* Landing Page *******/
.landing-page .logo svg {
  fill: #fff;
}

/******* Responsive *******/
@media screen and (max-width: 599px) {
  .single-property .mat-drawer {
    width: 320px;
  }
}
@media screen and (max-width: 959px) {
  .top-toolbar.mat-toolbar {
    padding: 0;
  }
  .all-properties .search-sidenav {
    float: none;
    position: absolute;
  }
  .all-properties .search-sidenav .mat-drawer-inner-container {
    padding: 0px;
  }
  .all-properties .search-sidenav .mat-drawer-inner-container .mat-card {
    box-shadow: none;
    border-radius: 0;
  }
  .single-property .mat-drawer .mat-drawer-inner-container, .account .mat-drawer .mat-drawer-inner-container {
    padding: 0px;
  }
  .single-property .mat-drawer .mat-drawer-inner-container .mat-card, .account .mat-drawer .mat-drawer-inner-container .mat-card {
    box-shadow: none;
    border-radius: 0;
  }
  .submit-property .mat-step-label {
    display: none;
  }
  .theme-dialog {
    min-width: 95%;
  }
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border: 0px none #ffffff;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover {
  background: #cccccc;
}
::-webkit-scrollbar-thumb:active {
  background: #888888;
}

::-webkit-scrollbar-track {
  background: #666666;
  border: 0px none #ffffff;
  border-radius: 0px;
}
::-webkit-scrollbar-track:hover {
  background: #666666;
}
::-webkit-scrollbar-track:active {
  background: #333333;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

/* perfect-scrollbar */
.ps {
  -ms-touch-action: auto;
  touch-action: auto;
  overflow: hidden !important;
  -ms-overflow-style: none;
  position: relative;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important;
  }
}
.ps__rail-x {
  display: none;
  opacity: 0;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
  height: 9px;
  bottom: 0px;
  position: absolute;
}

.ps__rail-y {
  display: none;
  opacity: 0;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
  width: 9px;
  right: 0px;
  left: auto !important;
  position: absolute;
  z-index: 998;
}

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  display: block;
  background-color: transparent;
}

.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
  opacity: 0.6;
}

.ps__rail-x:hover,
.ps__rail-y:hover,
.ps__rail-x:focus,
.ps__rail-y:focus {
  background-color: #eee;
  opacity: 0.9;
}

.ps__thumb-x {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color 0.2s linear, height 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, height 0.2s ease-in-out;
  height: 4px;
  bottom: 1px;
  position: absolute;
}

.ps__thumb-y {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color 0.2s linear, width 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
  width: 4px;
  right: 1px;
  position: absolute;
  z-index: 999;
}

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x {
  background-color: #999;
  height: 7px;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y {
  background-color: #999;
  width: 7px;
}

@supports (-ms-overflow-style: none) {
  .ps {
    overflow: auto !important;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important;
  }
}
.bdg-font-size {
  font-size: 0.7rem;
  font-weight: 700;
}

.btn-outline-danger.focus, .btn-outline-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(232, 229, 230, 0.5);
}

h1 {
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

h2 {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

h3 {
  display: block;
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

h4 {
  display: block;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.modal-backdrop {
  display: none !important;
}

.modal-xl {
  width: 100% !important;
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 95% !important;
  }
}
@media (min-width: 992px) {
  .modal-xl {
    max-width: 95% !important;
  }
}
@media (min-width: 576px) {
  .modal-xl {
    max-width: 95% !important;
  }
}
.modal {
  background-color: rgba(47, 47, 44, 0.54);
}

/* The containers */
.containers {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.containers input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.containers:hover input ~ .checkmark {
  background-color: #ccc;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.containers input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.containers .checkmark:after {
  top: 6px;
  left: 6px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: white;
}

/* The container */
.check {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmarks {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.check:hover input ~ .checkmarks {
  background-color: #ccc;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmarks:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.check input:checked ~ .checkmarks:after {
  display: block;
}

/* Style the checkmark/indicator */
.check .checkmarks:after {
  left: 8px;
  top: 1px;
  width: 6px;
  height: 9px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

@media screen and (max-width: 2560px) {
  .mat-icon-button1 {
    position: fixed !important;
    right: 263px;
    top: 0px;
    padding: 0;
    min-width: 0;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    line-height: 40px;
    border-radius: 50%;
    background-color: rgb(255, 255, 255) !important;
  }
}
@media screen and (max-width: 1440px) {
  .mat-icon-button1 {
    position: fixed !important;
    right: 153px;
    top: 0px;
    padding: 0;
    min-width: 0;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    line-height: 40px;
    border-radius: 50%;
    background-color: rgb(255, 255, 255) !important;
  }
}
@media screen and (max-width: 1024px) {
  .mat-icon-button1 {
    position: fixed !important;
    right: 112px;
    top: 0px;
    padding: 0;
    min-width: 0;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    line-height: 40px;
    border-radius: 50%;
    background-color: rgb(255, 255, 255) !important;
  }
}
@media screen and (max-width: 768px) {
  .mat-icon-button1 {
    position: fixed !important;
    right: 85px;
    top: 0px;
    padding: 0;
    min-width: 0;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    line-height: 40px;
    border-radius: 50%;
    background-color: rgb(255, 255, 255) !important;
  }
}
@media screen and (max-width: 425px) {
  .mat-icon-button1 {
    position: fixed !important;
    right: 50px;
    top: 0px;
    padding: 0;
    min-width: 0;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    line-height: 40px;
    border-radius: 50%;
    background-color: rgb(255, 255, 255) !important;
  }
}
@media screen and (max-width: 320px) {
  .mat-icon-button1 {
    position: fixed !important;
    right: 40px;
    top: 0px;
    padding: 0;
    min-width: 0;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    line-height: 40px;
    border-radius: 50%;
    background-color: rgb(255, 255, 255) !important;
  }
}
.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 2.5rem !important;
}

.mt-5,
.my-5 {
  margin-top: 2.5rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 2.5rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 2.5rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 2.5rem !important;
}

.m-6 {
  margin: 4rem !important;
}

.mt-6,
.my-6 {
  margin-top: 4rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 4rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 4rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 4rem !important;
}

.m-7 {
  margin: 6rem !important;
}

.mt-7,
.my-7 {
  margin-top: 6rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 6rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 6rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 6rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 2.5rem !important;
}

.pt-5,
.py-5 {
  padding-top: 2.5rem !important;
}

.pr-5,
.px-5 {
  padding-right: 2.5rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 2.5rem !important;
}

.pl-5,
.px-5 {
  padding-left: 2.5rem !important;
}

.p-6 {
  padding: 4rem !important;
}

.pt-6,
.py-6 {
  padding-top: 4rem !important;
}

.pr-6,
.px-6 {
  padding-right: 4rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 4rem !important;
}

.pl-6,
.px-6 {
  padding-left: 4rem !important;
}

.p-7 {
  padding: 6rem !important;
}

.pt-7,
.py-7 {
  padding-top: 6rem !important;
}

.pr-7,
.px-7 {
  padding-right: 6rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 6rem !important;
}

.pl-7,
.px-7 {
  padding-left: 6rem !important;
}

.mat-h1,
.mat-headline,
.mat-typography .mat-h1,
.mat-typography .mat-headline,
.mat-typography h1 {
  font: 400 24px / 32px Montserrat;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-title,
.mat-typography .mat-h2,
.mat-typography .mat-title,
.mat-typography h2 {
  font: 500 20px / 32px Montserrat;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subheading-2,
.mat-typography .mat-h3,
.mat-typography .mat-subheading-2,
.mat-typography h3 {
  font: 400 16px / 28px Montserrat;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4,
.mat-subheading-1,
.mat-typography .mat-h4,
.mat-typography .mat-subheading-1,
.mat-typography h4 {
  font: 400 15px / 24px Montserrat;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Montserrat;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Montserrat;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-body-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-body-2 {
  font: 500 14px / 24px Montserrat;
  letter-spacing: normal;
}

.mat-body,
.mat-body-1,
.mat-typography .mat-body,
.mat-typography .mat-body-1,
.mat-typography {
  font: 400 14px / 20px Montserrat;
  letter-spacing: normal;
}
.mat-body p,
.mat-body-1 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-1 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 12px / 20px Montserrat;
  letter-spacing: normal;
}

.mat-display-4,
.mat-typography .mat-display-4 {
  font: 300 112px / 112px Montserrat;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3,
.mat-typography .mat-display-3 {
  font: 400 56px / 56px Montserrat;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2,
.mat-typography .mat-display-2 {
  font: 400 45px / 48px Montserrat;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1,
.mat-typography .mat-display-1 {
  font: 400 34px / 40px Montserrat;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Montserrat;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-bottom-sheet-container {
  font: 400 14px / 20px Montserrat;
  letter-spacing: normal;
}

.mat-button-toggle {
  font-family: Montserrat;
}

.mat-calendar {
  font-family: Montserrat;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-expansion-panel-header {
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 14px / 20px Montserrat;
  letter-spacing: normal;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Montserrat;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px / 32px Montserrat;
  letter-spacing: normal;
  margin: 0;
}

.mat-tree {
  font-family: Montserrat;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-option {
  font-family: Montserrat;
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px / 24px Montserrat;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
}

.mat-datepicker-content .mdc-button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Montserrat));
  font-size: var(--mdc-typography-button-font-size, 14px);
  line-height: var(--mdc-typography-button-line-height, 14px);
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: var(--mdc-typography-button-letter-spacing, normal);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}
.mat-card {
  font-family: Montserrat;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-tooltip {
  font-family: Montserrat;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Montserrat;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label] + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label] + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label] + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label] + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label] + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-select {
  font-family: Montserrat;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-checkbox {
  font-family: Montserrat;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-dialog-title {
  font: 500 20px / 32px Montserrat;
  letter-spacing: normal;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-slide-toggle-content {
  font-family: Montserrat;
}

.mat-tab-group {
  font-family: Montserrat;
}

.mat-tab-label, .mat-tab-link {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
}

.mat-radio-button {
  font-family: Montserrat;
}

.mat-slider-thumb-label-text {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
}

.mat-menu-item {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.mat-list-item {
  font-family: Montserrat;
}

.mat-list-option {
  font-family: Montserrat;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Montserrat;
  font-size: 12px;
}

.mat-simple-snackbar {
  font-family: Montserrat;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-table {
  font-family: Montserrat;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.order {
  padding-left: 2rem;
}

.app.green {
  /* When the radio button is checked, add a blue background */
  /* When the checkbox is checked, add a blue background */
}
.app.green .mat-button, .app.green .mat-icon-button, .app.green .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.app.green .mat-button.mat-primary, .app.green .mat-icon-button.mat-primary, .app.green .mat-stroked-button.mat-primary {
  color: #689f38;
}
.app.green .mat-button.mat-accent, .app.green .mat-icon-button.mat-accent, .app.green .mat-stroked-button.mat-accent {
  color: #8e24aa;
}
.app.green .mat-button.mat-warn, .app.green .mat-icon-button.mat-warn, .app.green .mat-stroked-button.mat-warn {
  color: #f44336;
}
.app.green .mat-button.mat-primary.mat-button-disabled, .app.green .mat-button.mat-accent.mat-button-disabled, .app.green .mat-button.mat-warn.mat-button-disabled, .app.green .mat-button.mat-button-disabled.mat-button-disabled, .app.green .mat-icon-button.mat-primary.mat-button-disabled, .app.green .mat-icon-button.mat-accent.mat-button-disabled, .app.green .mat-icon-button.mat-warn.mat-button-disabled, .app.green .mat-icon-button.mat-button-disabled.mat-button-disabled, .app.green .mat-stroked-button.mat-primary.mat-button-disabled, .app.green .mat-stroked-button.mat-accent.mat-button-disabled, .app.green .mat-stroked-button.mat-warn.mat-button-disabled, .app.green .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.app.green .mat-button.mat-primary .mat-button-focus-overlay, .app.green .mat-icon-button.mat-primary .mat-button-focus-overlay, .app.green .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #689f38;
}
.app.green .mat-button.mat-accent .mat-button-focus-overlay, .app.green .mat-icon-button.mat-accent .mat-button-focus-overlay, .app.green .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #8e24aa;
}
.app.green .mat-button.mat-warn .mat-button-focus-overlay, .app.green .mat-icon-button.mat-warn .mat-button-focus-overlay, .app.green .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336;
}
.app.green .mat-button.mat-button-disabled .mat-button-focus-overlay, .app.green .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .app.green .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.app.green .mat-button .mat-ripple-element, .app.green .mat-icon-button .mat-ripple-element, .app.green .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.app.green .mat-button-focus-overlay {
  background: black;
}
.app.green .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.green .mat-flat-button, .app.green .mat-raised-button, .app.green .mat-fab, .app.green .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.app.green .mat-flat-button.mat-primary, .app.green .mat-raised-button.mat-primary, .app.green .mat-fab.mat-primary, .app.green .mat-mini-fab.mat-primary {
  color: white;
}
.app.green .mat-flat-button.mat-accent, .app.green .mat-raised-button.mat-accent, .app.green .mat-fab.mat-accent, .app.green .mat-mini-fab.mat-accent {
  color: white;
}
.app.green .mat-flat-button.mat-warn, .app.green .mat-raised-button.mat-warn, .app.green .mat-fab.mat-warn, .app.green .mat-mini-fab.mat-warn {
  color: white;
}
.app.green .mat-flat-button.mat-primary.mat-button-disabled, .app.green .mat-flat-button.mat-accent.mat-button-disabled, .app.green .mat-flat-button.mat-warn.mat-button-disabled, .app.green .mat-flat-button.mat-button-disabled.mat-button-disabled, .app.green .mat-raised-button.mat-primary.mat-button-disabled, .app.green .mat-raised-button.mat-accent.mat-button-disabled, .app.green .mat-raised-button.mat-warn.mat-button-disabled, .app.green .mat-raised-button.mat-button-disabled.mat-button-disabled, .app.green .mat-fab.mat-primary.mat-button-disabled, .app.green .mat-fab.mat-accent.mat-button-disabled, .app.green .mat-fab.mat-warn.mat-button-disabled, .app.green .mat-fab.mat-button-disabled.mat-button-disabled, .app.green .mat-mini-fab.mat-primary.mat-button-disabled, .app.green .mat-mini-fab.mat-accent.mat-button-disabled, .app.green .mat-mini-fab.mat-warn.mat-button-disabled, .app.green .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.app.green .mat-flat-button.mat-primary, .app.green .mat-raised-button.mat-primary, .app.green .mat-fab.mat-primary, .app.green .mat-mini-fab.mat-primary {
  background-color: #689f38;
}
.app.green .mat-flat-button.mat-accent, .app.green .mat-raised-button.mat-accent, .app.green .mat-fab.mat-accent, .app.green .mat-mini-fab.mat-accent {
  background-color: #8e24aa;
}
.app.green .mat-flat-button.mat-warn, .app.green .mat-raised-button.mat-warn, .app.green .mat-fab.mat-warn, .app.green .mat-mini-fab.mat-warn {
  background-color: #f44336;
}
.app.green .mat-flat-button.mat-primary.mat-button-disabled, .app.green .mat-flat-button.mat-accent.mat-button-disabled, .app.green .mat-flat-button.mat-warn.mat-button-disabled, .app.green .mat-flat-button.mat-button-disabled.mat-button-disabled, .app.green .mat-raised-button.mat-primary.mat-button-disabled, .app.green .mat-raised-button.mat-accent.mat-button-disabled, .app.green .mat-raised-button.mat-warn.mat-button-disabled, .app.green .mat-raised-button.mat-button-disabled.mat-button-disabled, .app.green .mat-fab.mat-primary.mat-button-disabled, .app.green .mat-fab.mat-accent.mat-button-disabled, .app.green .mat-fab.mat-warn.mat-button-disabled, .app.green .mat-fab.mat-button-disabled.mat-button-disabled, .app.green .mat-mini-fab.mat-primary.mat-button-disabled, .app.green .mat-mini-fab.mat-accent.mat-button-disabled, .app.green .mat-mini-fab.mat-warn.mat-button-disabled, .app.green .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.green .mat-flat-button.mat-primary .mat-ripple-element, .app.green .mat-raised-button.mat-primary .mat-ripple-element, .app.green .mat-fab.mat-primary .mat-ripple-element, .app.green .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.green .mat-flat-button.mat-accent .mat-ripple-element, .app.green .mat-raised-button.mat-accent .mat-ripple-element, .app.green .mat-fab.mat-accent .mat-ripple-element, .app.green .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.green .mat-flat-button.mat-warn .mat-ripple-element, .app.green .mat-raised-button.mat-warn .mat-ripple-element, .app.green .mat-fab.mat-warn .mat-ripple-element, .app.green .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.green .mat-stroked-button:not([class*=mat-elevation-z]), .app.green .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.green .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.green .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.green .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.green .mat-fab:not([class*=mat-elevation-z]), .app.green .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.green .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .app.green .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.green .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .app.green .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.green .mat-datepicker-content .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.app.green .mat-datepicker-content .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #689f38;
}
.app.green .mat-datepicker-content .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #8e24aa;
}
.app.green .mat-datepicker-content .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
}
.app.green .mat-datepicker-content .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.app.green .mat-datepicker-content .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.app.green .mat-datepicker-content .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #689f38;
  --mdc-filled-button-label-text-color: #fff;
}
.app.green .mat-datepicker-content .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #8e24aa;
  --mdc-filled-button-label-text-color: #fff;
}
.app.green .mat-datepicker-content .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: #fff;
}
.app.green .mat-datepicker-content .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.app.green .mat-datepicker-content .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.app.green .mat-datepicker-content .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #689f38;
  --mdc-protected-button-label-text-color: #fff;
}
.app.green .mat-datepicker-content .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #8e24aa;
  --mdc-protected-button-label-text-color: #fff;
}
.app.green .mat-datepicker-content .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: #fff;
}
.app.green .mat-datepicker-content .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}
.app.green .mat-datepicker-content .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.app.green .mat-datepicker-content .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.app.green .mat-datepicker-content .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #689f38;
}
.app.green .mat-datepicker-content .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #8e24aa;
}
.app.green .mat-datepicker-content .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
}
.app.green .mat-datepicker-content .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}
.app.green .mat-datepicker-content .mat-mdc-button, .app.green .mat-datepicker-content .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.app.green .mat-datepicker-content .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .app.green .mat-datepicker-content .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.app.green .mat-datepicker-content .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.green .mat-datepicker-content .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .app.green .mat-datepicker-content .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.green .mat-datepicker-content .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.green .mat-datepicker-content .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .app.green .mat-datepicker-content .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.green .mat-datepicker-content .mat-mdc-button.mat-primary, .app.green .mat-datepicker-content .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #689f38;
  --mat-mdc-button-ripple-color: rgba(104, 159, 56, 0.1);
}
.app.green .mat-datepicker-content .mat-mdc-button.mat-accent, .app.green .mat-datepicker-content .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #8e24aa;
  --mat-mdc-button-ripple-color: rgba(142, 36, 170, 0.1);
}
.app.green .mat-datepicker-content .mat-mdc-button.mat-warn, .app.green .mat-datepicker-content .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.app.green .mat-datepicker-content .mat-mdc-raised-button, .app.green .mat-datepicker-content .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.app.green .mat-datepicker-content .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .app.green .mat-datepicker-content .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.app.green .mat-datepicker-content .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.green .mat-datepicker-content .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .app.green .mat-datepicker-content .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.green .mat-datepicker-content .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.green .mat-datepicker-content .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .app.green .mat-datepicker-content .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.green .mat-datepicker-content .mat-mdc-raised-button.mat-primary, .app.green .mat-datepicker-content .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.app.green .mat-datepicker-content .mat-mdc-raised-button.mat-accent, .app.green .mat-datepicker-content .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.app.green .mat-datepicker-content .mat-mdc-raised-button.mat-warn, .app.green .mat-datepicker-content .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.app.green .mat-datepicker-content .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.app.green .mat-datepicker-content .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.app.green .mat-datepicker-content .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.green .mat-datepicker-content .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.green .mat-datepicker-content .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.green .mat-datepicker-content .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #689f38;
  --mat-mdc-button-ripple-color: rgba(104, 159, 56, 0.1);
}
.app.green .mat-datepicker-content .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #8e24aa;
  --mat-mdc-button-ripple-color: rgba(142, 36, 170, 0.1);
}
.app.green .mat-datepicker-content .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.app.green .mat-datepicker-content .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #689f38;
}
.app.green .mat-datepicker-content .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #8e24aa;
}
.app.green .mat-datepicker-content .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #f44336;
}
.app.green .mat-datepicker-content .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}
.app.green .mat-datepicker-content .mat-mdc-button.mat-mdc-button-base,
.app.green .mat-datepicker-content .mat-mdc-raised-button.mat-mdc-button-base,
.app.green .mat-datepicker-content .mat-mdc-unelevated-button.mat-mdc-button-base,
.app.green .mat-datepicker-content .mat-mdc-outlined-button.mat-mdc-button-base {
  height: 36px;
}
.app.green .mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base {
  width: 48px;
  height: 48px;
  padding: 12px;
}
.app.green .mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__focus-ring {
  max-height: 48px;
  max-width: 48px;
}
.app.green .mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 40px;
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 4px;
  margin-left: 4px;
}
.app.green .mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.app.green .mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 48px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 48px;
  transform: translate(-50%, -50%);
}
.app.green .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.app.green .mat-option:hover:not(.mat-option-disabled), .app.green .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.app.green .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.app.green .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.app.green .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.app.green .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #689f38;
}
.app.green .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #8e24aa;
}
.app.green .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}
.app.green .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.green .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.green .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.green .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.app.green .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.app.green .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.app.green .mat-primary .mat-pseudo-checkbox-checked,
.app.green .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #689f38;
}
.app.green .mat-pseudo-checkbox-checked,
.app.green .mat-pseudo-checkbox-indeterminate,
.app.green .mat-accent .mat-pseudo-checkbox-checked,
.app.green .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #8e24aa;
}
.app.green .mat-warn .mat-pseudo-checkbox-checked,
.app.green .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}
.app.green .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.app.green .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.app.green .mat-app-background, .app.green.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app.green .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.green .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.green .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.green .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.green .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.green .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.app.green .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.green .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.app.green .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.green .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.app.green .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.app.green .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.app.green .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.green .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.app.green .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.app.green .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.app.green .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.green .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.app.green .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.app.green .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.app.green .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.app.green .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.app.green .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.app.green .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.app.green .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.app.green .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.green .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.green .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.green .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.app.green .mat-progress-bar-background {
  fill: #d6e3ca;
}
.app.green .mat-progress-bar-buffer {
  background-color: #d6e3ca;
}
.app.green .mat-progress-bar-fill::after {
  background-color: #689f38;
}
.app.green .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #dfc5e6;
}
.app.green .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #dfc5e6;
}
.app.green .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #8e24aa;
}
.app.green .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f9ccc9;
}
.app.green .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f9ccc9;
}
.app.green .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336;
}
.app.green .mat-progress-spinner circle, .app.green .mat-spinner circle {
  stroke: #689f38;
}
.app.green .mat-progress-spinner.mat-accent circle, .app.green .mat-spinner.mat-accent circle {
  stroke: #8e24aa;
}
.app.green .mat-progress-spinner.mat-warn circle, .app.green .mat-spinner.mat-warn circle {
  stroke: #f44336;
}
.app.green .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.app.green .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.app.green .mat-input-element:disabled,
.app.green .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.app.green .mat-input-element {
  caret-color: #689f38;
}
.app.green .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.green .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.green .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.green .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.green .mat-form-field.mat-accent .mat-input-element {
  caret-color: #8e24aa;
}
.app.green .mat-form-field.mat-warn .mat-input-element,
.app.green .mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}
.app.green .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}
.app.green .mat-form-field-disabled .mat-form-field-prefix,
.app.green .mat-form-field-disabled .mat-form-field-suffix {
  color: rgba(0, 0, 0, 0.38);
}
.app.green .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.app.green .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.app.green .mat-form-field.mat-focused .mat-form-field-label {
  color: #689f38;
}
.app.green .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #8e24aa;
}
.app.green .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}
.app.green .mat-focused .mat-form-field-required-marker {
  color: #8e24aa;
}
.app.green .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.app.green .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #689f38;
}
.app.green .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #8e24aa;
}
.app.green .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}
.app.green .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #689f38;
}
.app.green .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #8e24aa;
}
.app.green .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}
.app.green .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}
.app.green .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.app.green .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}
.app.green .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.app.green .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}
.app.green .mat-error {
  color: #f44336;
}
.app.green .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.green .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.app.green .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.app.green .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app.green .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.app.green .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app.green .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.app.green .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.app.green .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.app.green .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.green .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.app.green .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.app.green .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.app.green .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #689f38;
}
.app.green .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #8e24aa;
}
.app.green .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}
.app.green .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}
.app.green .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.green .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.app.green .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.app.green .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.green .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.app.green .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.app.green .mat-select-panel {
  background: white;
}
.app.green .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.green .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.app.green .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #689f38;
}
.app.green .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #8e24aa;
}
.app.green .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}
.app.green .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}
.app.green .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.app.green .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.app.green .mat-checkbox-checkmark {
  fill: #fafafa;
}
.app.green .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.app.green .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.app.green .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .app.green .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #689f38;
}
.app.green .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .app.green .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #8e24aa;
}
.app.green .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .app.green .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}
.app.green .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .app.green .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.app.green .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.app.green .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.green .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.app.green .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.app.green .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #689f38;
}
.app.green .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.app.green .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #8e24aa;
}
.app.green .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.app.green .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}
.app.green .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.green .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.green .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.app.green .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.app.green .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.green .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.app.green .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.app.green .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.green .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.app.green .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.app.green .mat-chip.mat-standard-chip::after {
  background: black;
}
.app.green .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #689f38;
  color: white;
}
.app.green .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.green .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.green .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white;
}
.app.green .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.green .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.green .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #8e24aa;
  color: white;
}
.app.green .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.green .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.green .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #8e24aa;
}
.app.green .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(142, 36, 170, 0.54);
}
.app.green .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #8e24aa;
}
.app.green .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #689f38;
}
.app.green .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(104, 159, 56, 0.54);
}
.app.green .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #689f38;
}
.app.green .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336;
}
.app.green .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54);
}
.app.green .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336;
}
.app.green .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.app.green .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.app.green .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.green .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.app.green .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #689f38;
}
.app.green .mat-radio-button.mat-primary .mat-radio-inner-circle,
.app.green .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.green .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .app.green .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #689f38;
}
.app.green .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #8e24aa;
}
.app.green .mat-radio-button.mat-accent .mat-radio-inner-circle,
.app.green .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.green .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .app.green .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #8e24aa;
}
.app.green .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}
.app.green .mat-radio-button.mat-warn .mat-radio-inner-circle,
.app.green .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.green .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .app.green .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}
.app.green .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.app.green .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.green .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.app.green .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.green .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.app.green .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.app.green .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.green .mat-slider.mat-primary .mat-slider-track-fill,
.app.green .mat-slider.mat-primary .mat-slider-thumb,
.app.green .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #689f38;
}
.app.green .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.app.green .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(104, 159, 56, 0.2);
}
.app.green .mat-slider.mat-accent .mat-slider-track-fill,
.app.green .mat-slider.mat-accent .mat-slider-thumb,
.app.green .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #8e24aa;
}
.app.green .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.app.green .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(142, 36, 170, 0.2);
}
.app.green .mat-slider.mat-warn .mat-slider-track-fill,
.app.green .mat-slider.mat-warn .mat-slider-thumb,
.app.green .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #f44336;
}
.app.green .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.app.green .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}
.app.green .mat-slider:hover .mat-slider-track-background,
.app.green .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.green .mat-slider.mat-slider-disabled .mat-slider-track-background,
.app.green .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.app.green .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.green .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.green .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.green .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.app.green .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.app.green .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.app.green .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.green .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.app.green .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .app.green .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.green .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .app.green .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.app.green .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.app.green .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.app.green .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.app.green .mat-menu-panel {
  background: white;
}
.app.green .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.green .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.app.green .mat-menu-item[disabled],
.app.green .mat-menu-item[disabled] .mat-menu-submenu-icon,
.app.green .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.app.green .mat-menu-item .mat-icon-no-color,
.app.green .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.app.green .mat-menu-item:hover:not([disabled]),
.app.green .mat-menu-item.cdk-program-focused:not([disabled]),
.app.green .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.app.green .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.app.green .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.app.green .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.app.green .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.app.green .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.app.green .mat-list-option:hover, .app.green .mat-list-option:focus,
.app.green .mat-nav-list .mat-list-item:hover,
.app.green .mat-nav-list .mat-list-item:focus,
.app.green .mat-action-list .mat-list-item:hover,
.app.green .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.app.green .mat-list-single-selected-option, .app.green .mat-list-single-selected-option:hover, .app.green .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.app.green .mat-paginator {
  background: white;
}
.app.green .mat-paginator,
.app.green .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.app.green .mat-paginator-decrement,
.app.green .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.app.green .mat-paginator-first,
.app.green .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.app.green .mat-icon-button[disabled] .mat-paginator-decrement,
.app.green .mat-icon-button[disabled] .mat-paginator-increment,
.app.green .mat-icon-button[disabled] .mat-paginator-first,
.app.green .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.green .mat-paginator-container {
  min-height: 56px;
}
.app.green .mat-tab-nav-bar,
.app.green .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.app.green .mat-tab-group-inverted-header .mat-tab-nav-bar,
.app.green .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.app.green .mat-tab-label, .app.green .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.app.green .mat-tab-label.mat-tab-disabled, .app.green .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.app.green .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.app.green .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.green .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.app.green .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.app.green .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused, .app.green .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused,
.app.green .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused,
.app.green .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused, .app.green .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused, .app.green .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused,
.app.green .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused,
.app.green .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused {
  background-color: rgba(241, 248, 233, 0.3);
}
.app.green .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.green .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.green .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.green .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled, .app.green .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.green .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.green .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.green .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.green .mat-tab-group.mat-primary .mat-ink-bar, .app.green .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #689f38;
}
.app.green .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .app.green .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .app.green .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .app.green .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.app.green .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused, .app.green .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused,
.app.green .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused,
.app.green .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused, .app.green .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused, .app.green .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused,
.app.green .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused,
.app.green .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused {
  background-color: rgba(225, 190, 231, 0.3);
}
.app.green .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.green .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.green .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.green .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled, .app.green .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.green .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.green .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.green .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.green .mat-tab-group.mat-accent .mat-ink-bar, .app.green .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #8e24aa;
}
.app.green .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .app.green .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .app.green .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .app.green .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.app.green .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused, .app.green .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused,
.app.green .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused,
.app.green .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused, .app.green .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused, .app.green .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused,
.app.green .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused,
.app.green .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused {
  background-color: rgba(255, 82, 82, 0.3);
}
.app.green .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.green .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.green .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.green .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled, .app.green .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.green .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.green .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.green .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.green .mat-tab-group.mat-warn .mat-ink-bar, .app.green .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336;
}
.app.green .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .app.green .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .app.green .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .app.green .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.app.green .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused, .app.green .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused,
.app.green .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused,
.app.green .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused, .app.green .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused, .app.green .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused,
.app.green .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused,
.app.green .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused {
  background-color: rgba(241, 248, 233, 0.3);
}
.app.green .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.green .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.green .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.green .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled, .app.green .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.green .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.green .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.green .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.green .mat-tab-group.mat-background-primary > .mat-tab-header, .app.green .mat-tab-group.mat-background-primary > .mat-tab-link-container, .app.green .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .app.green .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .app.green .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .app.green .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #689f38;
}
.app.green .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .app.green .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .app.green .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .app.green .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.app.green .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.green .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .app.green .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.green .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.green .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.green .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.green .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.app.green .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .app.green .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.green .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.green .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.app.green .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.app.green .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.green .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.green .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.green .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.app.green .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.app.green .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.app.green .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .app.green .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.app.green .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.app.green .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.app.green .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused, .app.green .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused,
.app.green .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused,
.app.green .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused, .app.green .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused, .app.green .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused,
.app.green .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused,
.app.green .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused {
  background-color: rgba(225, 190, 231, 0.3);
}
.app.green .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.green .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.green .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.green .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled, .app.green .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.green .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.green .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.green .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.green .mat-tab-group.mat-background-accent > .mat-tab-header, .app.green .mat-tab-group.mat-background-accent > .mat-tab-link-container, .app.green .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .app.green .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .app.green .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .app.green .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #8e24aa;
}
.app.green .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .app.green .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .app.green .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .app.green .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.app.green .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.green .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .app.green .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.green .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.green .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.green .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.green .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.app.green .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .app.green .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.green .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.green .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.app.green .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.app.green .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.green .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.green .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.green .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.app.green .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.app.green .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.app.green .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .app.green .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.app.green .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.app.green .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.app.green .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused, .app.green .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused,
.app.green .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused,
.app.green .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused, .app.green .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused, .app.green .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused,
.app.green .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused,
.app.green .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused {
  background-color: rgba(255, 82, 82, 0.3);
}
.app.green .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.green .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.green .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.green .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled, .app.green .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.green .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.green .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.green .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.green .mat-tab-group.mat-background-warn > .mat-tab-header, .app.green .mat-tab-group.mat-background-warn > .mat-tab-link-container, .app.green .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .app.green .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .app.green .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .app.green .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #f44336;
}
.app.green .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .app.green .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .app.green .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .app.green .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.app.green .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.green .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .app.green .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.green .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.green .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.green .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.green .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.app.green .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .app.green .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.green .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.green .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.app.green .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.app.green .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.green .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.green .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.green .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.app.green .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.app.green .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.app.green .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .app.green .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.app.green .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.app.green .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.app.green .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.green .mat-simple-snackbar-action {
  color: #8e24aa;
}
.app.green .mat-table {
  background: white;
}
.app.green .mat-table thead, .app.green .mat-table tbody, .app.green .mat-table tfoot,
.app.green mat-header-row, .app.green mat-row, .app.green mat-footer-row,
.app.green [mat-header-row], .app.green [mat-row], .app.green [mat-footer-row],
.app.green .mat-table-sticky {
  background: inherit;
}
.app.green mat-row, .app.green mat-header-row, .app.green mat-footer-row,
.app.green th.mat-header-cell, .app.green td.mat-cell, .app.green td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.app.green .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.app.green .mat-cell, .app.green .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.app.green .mat-badge {
  position: relative;
}
.app.green .mat-badge.mat-badge {
  overflow: visible;
}
.app.green .mat-badge-hidden .mat-badge-content {
  display: none;
}
.app.green .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.app.green .ng-animate-disabled .mat-badge-content,
.app.green .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.app.green .mat-badge-content.mat-badge-active {
  transform: none;
}
.app.green .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.app.green .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.app.green .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.app.green .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .app.green .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.app.green .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .app.green .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.app.green .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .app.green .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.app.green .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .app.green .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.app.green .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.app.green .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.app.green .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.app.green .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .app.green .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.app.green .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .app.green .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.app.green .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .app.green .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.app.green .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .app.green .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.app.green .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.app.green .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.app.green .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.app.green .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .app.green .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.app.green .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .app.green .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.app.green .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .app.green .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.app.green .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .app.green .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.app.green .mat-badge-content {
  color: white;
  background: #689f38;
}
.cdk-high-contrast-active .app.green .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.app.green .mat-badge-accent .mat-badge-content {
  background: #8e24aa;
  color: white;
}
.app.green .mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}
.app.green .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.app.green .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.green .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.app.green .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.green .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.app.green .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.app.green .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.app.green .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.green .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.app.green .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.app.green .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.app.green [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.app.green .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.app.green .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.app.green .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.app.green .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.app.green .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.app.green .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.app.green .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.app.green .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.app.green .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}
.app.green .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.app.green .mat-datepicker-toggle,
.app.green .mat-datepicker-content .mat-calendar-next-button,
.app.green .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.app.green .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.app.green .mat-calendar-table-header,
.app.green .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.green .mat-calendar-body-cell-content,
.app.green .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.app.green .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.app.green .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.app.green .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.app.green .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.green .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.app.green .mat-calendar-body-in-range::before {
  background: rgba(104, 159, 56, 0.2);
}
.app.green .mat-calendar-body-comparison-identical,
.app.green .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.green .mat-calendar-body-comparison-bridge-start::before,
.app.green [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(104, 159, 56, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.green .mat-calendar-body-comparison-bridge-end::before,
.app.green [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(104, 159, 56, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.green .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.green .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.green .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.green .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.green .mat-calendar-body-selected {
  background-color: #689f38;
  color: white;
}
.app.green .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(104, 159, 56, 0.4);
}
.app.green .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.green .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.green .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(104, 159, 56, 0.3);
}
@media (hover: hover) {
  .app.green .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(104, 159, 56, 0.3);
  }
}
.app.green .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.green .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(142, 36, 170, 0.2);
}
.app.green .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.app.green .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.green .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.app.green .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(142, 36, 170, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.green .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.app.green .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(142, 36, 170, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.green .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.green .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.green .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.green .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.green .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #8e24aa;
  color: white;
}
.app.green .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(142, 36, 170, 0.4);
}
.app.green .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.green .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.green .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(142, 36, 170, 0.3);
}
@media (hover: hover) {
  .app.green .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(142, 36, 170, 0.3);
  }
}
.app.green .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.app.green .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.app.green .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.green .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.app.green .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.green .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.app.green .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.green .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.green .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.green .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.green .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.green .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.app.green .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.app.green .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.green .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.green .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}
@media (hover: hover) {
  .app.green .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 67, 54, 0.3);
  }
}
.app.green .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.app.green .mat-datepicker-toggle-active {
  color: #689f38;
}
.app.green .mat-datepicker-toggle-active.mat-accent {
  color: #8e24aa;
}
.app.green .mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}
.app.green .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.app.green .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  width: 40px;
  height: 40px;
  padding: 8px;
}
.app.green .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.app.green .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 40px;
  height: 40px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  margin-left: 0px;
}
.app.green .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.app.green .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 40px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 40px;
  transform: translate(-50%, -50%);
}
.app.green .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}
.app.green .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.green .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.app.green .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.green .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.green .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.green .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .app.green .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .app.green .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .app.green .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.app.green .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.app.green .mat-expansion-panel-header-description,
.app.green .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.app.green .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.app.green .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.app.green .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.app.green .mat-expansion-panel-header {
  height: 48px;
}
.app.green .mat-expansion-panel-header.mat-expanded {
  height: 64px;
}
.app.green .mat-icon.mat-primary {
  color: #689f38;
}
.app.green .mat-icon.mat-accent {
  color: #8e24aa;
}
.app.green .mat-icon.mat-warn {
  color: #f44336;
}
.app.green .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app.green .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.green .mat-drawer.mat-drawer-push {
  background-color: white;
}
.app.green .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.green .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app.green .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.app.green [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.app.green [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app.green .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.app.green .mat-step-header.cdk-keyboard-focused, .app.green .mat-step-header.cdk-program-focused, .app.green .mat-step-header:hover:not([aria-disabled]), .app.green .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.app.green .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .app.green .mat-step-header:hover {
    background: none;
  }
}
.app.green .mat-step-header .mat-step-label,
.app.green .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.app.green .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.app.green .mat-step-header .mat-step-icon-selected,
.app.green .mat-step-header .mat-step-icon-state-done,
.app.green .mat-step-header .mat-step-icon-state-edit {
  background-color: #689f38;
  color: white;
}
.app.green .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.app.green .mat-step-header.mat-accent .mat-step-icon-selected,
.app.green .mat-step-header.mat-accent .mat-step-icon-state-done,
.app.green .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #8e24aa;
  color: white;
}
.app.green .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.app.green .mat-step-header.mat-warn .mat-step-icon-selected,
.app.green .mat-step-header.mat-warn .mat-step-icon-state-done,
.app.green .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
.app.green .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.app.green .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.app.green .mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}
.app.green .mat-stepper-horizontal, .app.green .mat-stepper-vertical {
  background-color: white;
}
.app.green .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.app.green .mat-horizontal-stepper-header::before,
.app.green .mat-horizontal-stepper-header::after,
.app.green .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.green .mat-horizontal-stepper-header {
  height: 72px;
}
.app.green .mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.app.green .mat-vertical-stepper-header {
  padding: 24px 24px;
}
.app.green .mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}
.app.green .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .app.green .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}
.app.green .mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}
.app.green .mat-sort-header-arrow {
  color: #757575;
}
.app.green .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.app.green .mat-toolbar.mat-primary {
  background: #689f38;
  color: white;
}
.app.green .mat-toolbar.mat-accent {
  background: #8e24aa;
  color: white;
}
.app.green .mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.app.green .mat-toolbar .mat-form-field-underline,
.app.green .mat-toolbar .mat-form-field-ripple,
.app.green .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.app.green .mat-toolbar .mat-form-field-label,
.app.green .mat-toolbar .mat-focused .mat-form-field-label,
.app.green .mat-toolbar .mat-select-value,
.app.green .mat-toolbar .mat-select-arrow,
.app.green .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.app.green .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.app.green .mat-toolbar-multiple-rows {
  min-height: 44px;
}
.app.green .mat-toolbar-row, .app.green .mat-toolbar-single-row {
  height: 44px;
}
@media (max-width: 599px) {
  .app.green .mat-toolbar-multiple-rows {
    min-height: 56px;
  }
  .app.green .mat-toolbar-row, .app.green .mat-toolbar-single-row {
    height: 56px;
  }
}
.app.green .mat-tree {
  background: white;
}
.app.green .mat-tree-node,
.app.green .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.app.green .mat-tree-node {
  min-height: 48px;
}
.app.green .bg-primary {
  background-color: var(--primary);
  color: white;
}
.app.green .bg-accent {
  background-color: var(--accent);
  color: white;
}
.app.green .bg-warn {
  background-color: var(--warn);
  color: white;
}
.app.green .text-muted {
  color: rgba(0, 0, 0, 0.54) !important;
}
.app.green .primary-color {
  color: #689f38;
}
.app.green .secondary-color {
  color: #dcedc8;
}
.app.green .v-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.green .divider {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.app.green .top-toolbar.mat-toolbar .v-divider {
  background-color: rgba(241, 248, 233, 0.2);
}
.app.green .border-bottom-lighter {
  border-bottom: 1px solid rgba(241, 248, 233, 0.2);
}
.app.green .border-lighter {
  border-color: rgba(241, 248, 233, 0.2);
}
.app.green .border-accent {
  border-color: #8e24aa;
}
.app.green .border-primary {
  border-color: #689f38 !important;
}
.app.green #top-toolbar {
  background-color: #8bc34a;
  color: white;
}
.app.green .toolbar-2 #top-toolbar {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.green #main-toolbar {
  background: white;
}
.app.green .toolbar-2 #main-toolbar {
  color: var(--primary);
}
.app.green header .logo svg {
  fill: #689f38;
}
.app.green header.toolbar-1.has-bg-image:not(.main-toolbar-fixed) .logo svg, .app.green header.toolbar-1.has-bg-video:not(.main-toolbar-fixed) .logo svg {
  fill: #fff;
}
.app.green header.toolbar-1.has-bg-image:not(.main-toolbar-fixed) .horizontal-menu .mat-button, .app.green header.toolbar-1.has-bg-video:not(.main-toolbar-fixed) .horizontal-menu .mat-button {
  color: #fff;
}
.app.green header.toolbar-1.has-bg-image:not(.main-toolbar-fixed) .horizontal-menu .mat-button.horizontal-active-link, .app.green header.toolbar-1.has-bg-video:not(.main-toolbar-fixed) .horizontal-menu .mat-button.horizontal-active-link {
  background: rgba(104, 159, 56, 0.38);
}
.app.green header.toolbar-1.has-bg-image:not(.main-toolbar-fixed) .mat-icon, .app.green header.toolbar-1.has-bg-video:not(.main-toolbar-fixed) .mat-icon {
  color: #fff;
}
.app.green header.toolbar-2 .horizontal-menu .mat-button {
  color: #fff;
}
.app.green header.toolbar-2 .horizontal-menu .mat-button.horizontal-active-link {
  color: #fff;
}
.app.green header.toolbar-2.has-bg-image:not(.main-toolbar-fixed) .horizontal-menu .mat-button.horizontal-active-link, .app.green header.toolbar-2.has-bg-video:not(.main-toolbar-fixed) .horizontal-menu .mat-button.horizontal-active-link {
  background: rgba(104, 159, 56, 0.38);
}
.app.green .header-carousel .slide-info .mat-card {
  background: rgba(255, 255, 255, 0.85);
}
.app.green .header-carousel .slide-info .mat-card:hover {
  background: white;
}
.app.green .user-menu .user-info {
  color: rgba(0, 0, 0, 0.87);
}
.app.green main.content-offset-to-top .main-content-header {
  border-top: 5px solid #689f38;
}
.app.green .map-info-window .si-content-wrapper {
  padding: 2px;
  color: var(--primary);
}
.app.green .map-info-window .si-content-wrapper .si-content {
  max-width: 100% !important;
  max-height: 100% !important;
}
.app.green .map-info-window .si-content-wrapper .si-content a {
  color: inherit;
  text-decoration: none;
}
.app.green .map-info-window .si-pointer-bg-top {
  border-top-color: #689f38;
}
.app.green .map-info-window .si-pointer-border-top {
  border-top-color: #689f38;
}
.app.green .map-info-window .si-close-button {
  opacity: 1;
  right: 4px;
  top: 4px;
  padding: 0 6px;
  border-radius: 50%;
  color: var(--primary);
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.green .map-info-window.si-has-border .si-content-wrapper {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.green .property-item .title a,
.app.green .compare-item .title a {
  color: rgba(0, 0, 0, 0.87);
}
.app.green .property-item .title a:hover,
.app.green .compare-item .title a:hover {
  color: #689f38;
}
.app.green .features p:after,
.app.green .compare-item .details .item {
  border-bottom: 1px dotted rgba(0, 0, 0, 0.12);
}
.app.green .small-carousel .swiper-slide.active-thumb img {
  border-color: #689f38;
}
.app.green .ps__thumb-y {
  background-color: #33691e;
}
.app.green .active-link {
  background-color: #689f38;
  color: #fff;
}
.app.green .horizontal-active-link {
  background: rgba(0, 0, 0, 0.04);
  color: #689f38;
}
.app.green .horizontal-sub-menu .mat-button, .app.green .horizontal-sub-menu .mat-menu-item {
  color: rgba(0, 0, 0, 0.87);
}
.app.green .horizontal-sub-menu .mat-button.horizontal-active-link, .app.green .horizontal-sub-menu .mat-menu-item.horizontal-active-link {
  color: #689f38;
}
.app.green .section .section-title:after,
.app.green .section.testimonials .swiper-pagination-bullet-active {
  background-color: #689f38;
}
.app.green .custom-table.mat-table .mat-header-row,
.app.green .custom-table.mat-table .mat-row {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.green .submit-property .step-section,
.app.green .edit-property .step-section {
  background: rgba(250, 250, 250, 0.7);
}
.app.green .input-file-container .mat-orphan-label {
  color: rgba(0, 0, 0, 0.87) !important;
}
.app.green .nav-pills .nav-link.active, .app.green .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #689f38;
}
.app.green .btn-danger {
  color: #fff;
  background-color: #689f38;
  border-color: #689f38;
}
.app.green .btn-danger:not(:disabled):not(.disabled):active, .app.green .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #689f38;
  border-color: #689f38;
}
.app.green .btn-danger:hover {
  color: #fff;
  background-color: #689f38;
  border-color: #689f38;
}
.app.green .btn-outline-danger {
  color: #689f38;
  border-color: #689f38;
}
.app.green .btn-outline-danger:not(:disabled):not(.disabled):active, .app.green .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #689f38;
  border-color: #689f38;
}
.app.green .btn-outline-danger:hover {
  color: #fff;
  background-color: #689f38;
  border-color: #689f38;
}
.app.green .pr-clr {
  color: #689f38;
}
.app.green .bg-primary {
  background-color: #689f38 !important;
}
.app.green a {
  color: #689f38;
  text-decoration: none;
  background-color: transparent;
}
.app.green .containers input:checked ~ .checkmark {
  background-color: #689f38;
}
.app.green .check input:checked ~ .checkmarks {
  background-color: #689f38;
}
.app.green .btn-outline-danger:not(:disabled):not(.disabled).active, .app.green .btn-outline-danger:not(:disabled):not(.disabled):active, .app.green .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #689f38;
  border-color: #689f38;
}
.app.blue {
  /* When the radio button is checked, add a blue background */
  /* When the checkbox is checked, add a blue background */
}
.app.blue .mat-button, .app.blue .mat-icon-button, .app.blue .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.app.blue .mat-button.mat-primary, .app.blue .mat-icon-button.mat-primary, .app.blue .mat-stroked-button.mat-primary {
  color: #1976d2;
}
.app.blue .mat-button.mat-accent, .app.blue .mat-icon-button.mat-accent, .app.blue .mat-stroked-button.mat-accent {
  color: #e91e63;
}
.app.blue .mat-button.mat-warn, .app.blue .mat-icon-button.mat-warn, .app.blue .mat-stroked-button.mat-warn {
  color: #ff5252;
}
.app.blue .mat-button.mat-primary.mat-button-disabled, .app.blue .mat-button.mat-accent.mat-button-disabled, .app.blue .mat-button.mat-warn.mat-button-disabled, .app.blue .mat-button.mat-button-disabled.mat-button-disabled, .app.blue .mat-icon-button.mat-primary.mat-button-disabled, .app.blue .mat-icon-button.mat-accent.mat-button-disabled, .app.blue .mat-icon-button.mat-warn.mat-button-disabled, .app.blue .mat-icon-button.mat-button-disabled.mat-button-disabled, .app.blue .mat-stroked-button.mat-primary.mat-button-disabled, .app.blue .mat-stroked-button.mat-accent.mat-button-disabled, .app.blue .mat-stroked-button.mat-warn.mat-button-disabled, .app.blue .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.app.blue .mat-button.mat-primary .mat-button-focus-overlay, .app.blue .mat-icon-button.mat-primary .mat-button-focus-overlay, .app.blue .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #1976d2;
}
.app.blue .mat-button.mat-accent .mat-button-focus-overlay, .app.blue .mat-icon-button.mat-accent .mat-button-focus-overlay, .app.blue .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #e91e63;
}
.app.blue .mat-button.mat-warn .mat-button-focus-overlay, .app.blue .mat-icon-button.mat-warn .mat-button-focus-overlay, .app.blue .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #ff5252;
}
.app.blue .mat-button.mat-button-disabled .mat-button-focus-overlay, .app.blue .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .app.blue .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.app.blue .mat-button .mat-ripple-element, .app.blue .mat-icon-button .mat-ripple-element, .app.blue .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.app.blue .mat-button-focus-overlay {
  background: black;
}
.app.blue .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.blue .mat-flat-button, .app.blue .mat-raised-button, .app.blue .mat-fab, .app.blue .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.app.blue .mat-flat-button.mat-primary, .app.blue .mat-raised-button.mat-primary, .app.blue .mat-fab.mat-primary, .app.blue .mat-mini-fab.mat-primary {
  color: white;
}
.app.blue .mat-flat-button.mat-accent, .app.blue .mat-raised-button.mat-accent, .app.blue .mat-fab.mat-accent, .app.blue .mat-mini-fab.mat-accent {
  color: white;
}
.app.blue .mat-flat-button.mat-warn, .app.blue .mat-raised-button.mat-warn, .app.blue .mat-fab.mat-warn, .app.blue .mat-mini-fab.mat-warn {
  color: white;
}
.app.blue .mat-flat-button.mat-primary.mat-button-disabled, .app.blue .mat-flat-button.mat-accent.mat-button-disabled, .app.blue .mat-flat-button.mat-warn.mat-button-disabled, .app.blue .mat-flat-button.mat-button-disabled.mat-button-disabled, .app.blue .mat-raised-button.mat-primary.mat-button-disabled, .app.blue .mat-raised-button.mat-accent.mat-button-disabled, .app.blue .mat-raised-button.mat-warn.mat-button-disabled, .app.blue .mat-raised-button.mat-button-disabled.mat-button-disabled, .app.blue .mat-fab.mat-primary.mat-button-disabled, .app.blue .mat-fab.mat-accent.mat-button-disabled, .app.blue .mat-fab.mat-warn.mat-button-disabled, .app.blue .mat-fab.mat-button-disabled.mat-button-disabled, .app.blue .mat-mini-fab.mat-primary.mat-button-disabled, .app.blue .mat-mini-fab.mat-accent.mat-button-disabled, .app.blue .mat-mini-fab.mat-warn.mat-button-disabled, .app.blue .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.app.blue .mat-flat-button.mat-primary, .app.blue .mat-raised-button.mat-primary, .app.blue .mat-fab.mat-primary, .app.blue .mat-mini-fab.mat-primary {
  background-color: #1976d2;
}
.app.blue .mat-flat-button.mat-accent, .app.blue .mat-raised-button.mat-accent, .app.blue .mat-fab.mat-accent, .app.blue .mat-mini-fab.mat-accent {
  background-color: #e91e63;
}
.app.blue .mat-flat-button.mat-warn, .app.blue .mat-raised-button.mat-warn, .app.blue .mat-fab.mat-warn, .app.blue .mat-mini-fab.mat-warn {
  background-color: #ff5252;
}
.app.blue .mat-flat-button.mat-primary.mat-button-disabled, .app.blue .mat-flat-button.mat-accent.mat-button-disabled, .app.blue .mat-flat-button.mat-warn.mat-button-disabled, .app.blue .mat-flat-button.mat-button-disabled.mat-button-disabled, .app.blue .mat-raised-button.mat-primary.mat-button-disabled, .app.blue .mat-raised-button.mat-accent.mat-button-disabled, .app.blue .mat-raised-button.mat-warn.mat-button-disabled, .app.blue .mat-raised-button.mat-button-disabled.mat-button-disabled, .app.blue .mat-fab.mat-primary.mat-button-disabled, .app.blue .mat-fab.mat-accent.mat-button-disabled, .app.blue .mat-fab.mat-warn.mat-button-disabled, .app.blue .mat-fab.mat-button-disabled.mat-button-disabled, .app.blue .mat-mini-fab.mat-primary.mat-button-disabled, .app.blue .mat-mini-fab.mat-accent.mat-button-disabled, .app.blue .mat-mini-fab.mat-warn.mat-button-disabled, .app.blue .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.blue .mat-flat-button.mat-primary .mat-ripple-element, .app.blue .mat-raised-button.mat-primary .mat-ripple-element, .app.blue .mat-fab.mat-primary .mat-ripple-element, .app.blue .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.blue .mat-flat-button.mat-accent .mat-ripple-element, .app.blue .mat-raised-button.mat-accent .mat-ripple-element, .app.blue .mat-fab.mat-accent .mat-ripple-element, .app.blue .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.blue .mat-flat-button.mat-warn .mat-ripple-element, .app.blue .mat-raised-button.mat-warn .mat-ripple-element, .app.blue .mat-fab.mat-warn .mat-ripple-element, .app.blue .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.blue .mat-stroked-button:not([class*=mat-elevation-z]), .app.blue .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-fab:not([class*=mat-elevation-z]), .app.blue .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .app.blue .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .app.blue .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-datepicker-content .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.app.blue .mat-datepicker-content .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #1976d2;
}
.app.blue .mat-datepicker-content .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #e91e63;
}
.app.blue .mat-datepicker-content .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #ff5252;
}
.app.blue .mat-datepicker-content .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.app.blue .mat-datepicker-content .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.app.blue .mat-datepicker-content .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #1976d2;
  --mdc-filled-button-label-text-color: #fff;
}
.app.blue .mat-datepicker-content .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #e91e63;
  --mdc-filled-button-label-text-color: #fff;
}
.app.blue .mat-datepicker-content .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #ff5252;
  --mdc-filled-button-label-text-color: #fff;
}
.app.blue .mat-datepicker-content .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.app.blue .mat-datepicker-content .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.app.blue .mat-datepicker-content .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #1976d2;
  --mdc-protected-button-label-text-color: #fff;
}
.app.blue .mat-datepicker-content .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #e91e63;
  --mdc-protected-button-label-text-color: #fff;
}
.app.blue .mat-datepicker-content .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #ff5252;
  --mdc-protected-button-label-text-color: #fff;
}
.app.blue .mat-datepicker-content .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}
.app.blue .mat-datepicker-content .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.app.blue .mat-datepicker-content .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.app.blue .mat-datepicker-content .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #1976d2;
}
.app.blue .mat-datepicker-content .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #e91e63;
}
.app.blue .mat-datepicker-content .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #ff5252;
}
.app.blue .mat-datepicker-content .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}
.app.blue .mat-datepicker-content .mat-mdc-button, .app.blue .mat-datepicker-content .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.app.blue .mat-datepicker-content .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .app.blue .mat-datepicker-content .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.app.blue .mat-datepicker-content .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.blue .mat-datepicker-content .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .app.blue .mat-datepicker-content .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.blue .mat-datepicker-content .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.blue .mat-datepicker-content .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .app.blue .mat-datepicker-content .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.blue .mat-datepicker-content .mat-mdc-button.mat-primary, .app.blue .mat-datepicker-content .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #1976d2;
  --mat-mdc-button-ripple-color: rgba(25, 118, 210, 0.1);
}
.app.blue .mat-datepicker-content .mat-mdc-button.mat-accent, .app.blue .mat-datepicker-content .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #e91e63;
  --mat-mdc-button-ripple-color: rgba(233, 30, 99, 0.1);
}
.app.blue .mat-datepicker-content .mat-mdc-button.mat-warn, .app.blue .mat-datepicker-content .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #ff5252;
  --mat-mdc-button-ripple-color: rgba(255, 82, 82, 0.1);
}
.app.blue .mat-datepicker-content .mat-mdc-raised-button, .app.blue .mat-datepicker-content .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.app.blue .mat-datepicker-content .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .app.blue .mat-datepicker-content .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.app.blue .mat-datepicker-content .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.blue .mat-datepicker-content .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .app.blue .mat-datepicker-content .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.blue .mat-datepicker-content .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.blue .mat-datepicker-content .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .app.blue .mat-datepicker-content .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.blue .mat-datepicker-content .mat-mdc-raised-button.mat-primary, .app.blue .mat-datepicker-content .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.app.blue .mat-datepicker-content .mat-mdc-raised-button.mat-accent, .app.blue .mat-datepicker-content .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.app.blue .mat-datepicker-content .mat-mdc-raised-button.mat-warn, .app.blue .mat-datepicker-content .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.app.blue .mat-datepicker-content .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.app.blue .mat-datepicker-content .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.app.blue .mat-datepicker-content .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.blue .mat-datepicker-content .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.blue .mat-datepicker-content .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.blue .mat-datepicker-content .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #1976d2;
  --mat-mdc-button-ripple-color: rgba(25, 118, 210, 0.1);
}
.app.blue .mat-datepicker-content .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #e91e63;
  --mat-mdc-button-ripple-color: rgba(233, 30, 99, 0.1);
}
.app.blue .mat-datepicker-content .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #ff5252;
  --mat-mdc-button-ripple-color: rgba(255, 82, 82, 0.1);
}
.app.blue .mat-datepicker-content .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #1976d2;
}
.app.blue .mat-datepicker-content .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #e91e63;
}
.app.blue .mat-datepicker-content .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #ff5252;
}
.app.blue .mat-datepicker-content .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}
.app.blue .mat-datepicker-content .mat-mdc-button.mat-mdc-button-base,
.app.blue .mat-datepicker-content .mat-mdc-raised-button.mat-mdc-button-base,
.app.blue .mat-datepicker-content .mat-mdc-unelevated-button.mat-mdc-button-base,
.app.blue .mat-datepicker-content .mat-mdc-outlined-button.mat-mdc-button-base {
  height: 36px;
}
.app.blue .mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base {
  width: 48px;
  height: 48px;
  padding: 12px;
}
.app.blue .mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__focus-ring {
  max-height: 48px;
  max-width: 48px;
}
.app.blue .mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 40px;
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 4px;
  margin-left: 4px;
}
.app.blue .mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.app.blue .mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 48px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 48px;
  transform: translate(-50%, -50%);
}
.app.blue .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.app.blue .mat-option:hover:not(.mat-option-disabled), .app.blue .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.app.blue .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.app.blue .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.app.blue .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.app.blue .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #1976d2;
}
.app.blue .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e91e63;
}
.app.blue .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff5252;
}
.app.blue .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.blue .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.blue .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.blue .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.app.blue .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.app.blue .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.app.blue .mat-primary .mat-pseudo-checkbox-checked,
.app.blue .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #1976d2;
}
.app.blue .mat-pseudo-checkbox-checked,
.app.blue .mat-pseudo-checkbox-indeterminate,
.app.blue .mat-accent .mat-pseudo-checkbox-checked,
.app.blue .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #e91e63;
}
.app.blue .mat-warn .mat-pseudo-checkbox-checked,
.app.blue .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #ff5252;
}
.app.blue .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.app.blue .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.app.blue .mat-app-background, .app.blue.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app.blue .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.app.blue .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.blue .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.app.blue .mat-progress-bar-background {
  fill: #c2d9f0;
}
.app.blue .mat-progress-bar-buffer {
  background-color: #c2d9f0;
}
.app.blue .mat-progress-bar-fill::after {
  background-color: #1976d2;
}
.app.blue .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #f6c3d4;
}
.app.blue .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #f6c3d4;
}
.app.blue .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #e91e63;
}
.app.blue .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #fbd0d0;
}
.app.blue .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #fbd0d0;
}
.app.blue .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #ff5252;
}
.app.blue .mat-progress-spinner circle, .app.blue .mat-spinner circle {
  stroke: #1976d2;
}
.app.blue .mat-progress-spinner.mat-accent circle, .app.blue .mat-spinner.mat-accent circle {
  stroke: #e91e63;
}
.app.blue .mat-progress-spinner.mat-warn circle, .app.blue .mat-spinner.mat-warn circle {
  stroke: #ff5252;
}
.app.blue .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.app.blue .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.app.blue .mat-input-element:disabled,
.app.blue .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.app.blue .mat-input-element {
  caret-color: #1976d2;
}
.app.blue .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.blue .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.blue .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.blue .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.blue .mat-form-field.mat-accent .mat-input-element {
  caret-color: #e91e63;
}
.app.blue .mat-form-field.mat-warn .mat-input-element,
.app.blue .mat-form-field-invalid .mat-input-element {
  caret-color: #ff5252;
}
.app.blue .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #ff5252;
}
.app.blue .mat-form-field-disabled .mat-form-field-prefix,
.app.blue .mat-form-field-disabled .mat-form-field-suffix {
  color: rgba(0, 0, 0, 0.38);
}
.app.blue .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.app.blue .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.app.blue .mat-form-field.mat-focused .mat-form-field-label {
  color: #1976d2;
}
.app.blue .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #e91e63;
}
.app.blue .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #ff5252;
}
.app.blue .mat-focused .mat-form-field-required-marker {
  color: #e91e63;
}
.app.blue .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.app.blue .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #1976d2;
}
.app.blue .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #e91e63;
}
.app.blue .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #ff5252;
}
.app.blue .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #1976d2;
}
.app.blue .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #e91e63;
}
.app.blue .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #ff5252;
}
.app.blue .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #ff5252;
}
.app.blue .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.app.blue .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #ff5252;
}
.app.blue .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.app.blue .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #ff5252;
}
.app.blue .mat-error {
  color: #ff5252;
}
.app.blue .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.blue .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.app.blue .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.app.blue .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app.blue .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.app.blue .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app.blue .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.app.blue .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.app.blue .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.app.blue .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.blue .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.app.blue .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.app.blue .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.app.blue .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #1976d2;
}
.app.blue .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #e91e63;
}
.app.blue .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #ff5252;
}
.app.blue .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #ff5252;
}
.app.blue .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.blue .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.app.blue .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.app.blue .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.blue .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.app.blue .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.app.blue .mat-select-panel {
  background: white;
}
.app.blue .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.app.blue .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #1976d2;
}
.app.blue .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #e91e63;
}
.app.blue .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #ff5252;
}
.app.blue .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #ff5252;
}
.app.blue .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.app.blue .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.app.blue .mat-checkbox-checkmark {
  fill: #fafafa;
}
.app.blue .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.app.blue .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.app.blue .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .app.blue .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #1976d2;
}
.app.blue .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .app.blue .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #e91e63;
}
.app.blue .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .app.blue .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #ff5252;
}
.app.blue .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .app.blue .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.app.blue .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.app.blue .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.blue .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.app.blue .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.app.blue .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #1976d2;
}
.app.blue .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.app.blue .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #e91e63;
}
.app.blue .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.app.blue .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #ff5252;
}
.app.blue .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.blue .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.app.blue .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.app.blue .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.blue .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.app.blue .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.app.blue .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.app.blue .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.app.blue .mat-chip.mat-standard-chip::after {
  background: black;
}
.app.blue .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #1976d2;
  color: white;
}
.app.blue .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.blue .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.blue .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #ff5252;
  color: white;
}
.app.blue .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.blue .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.blue .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #e91e63;
  color: white;
}
.app.blue .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.blue .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.blue .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #e91e63;
}
.app.blue .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(233, 30, 99, 0.54);
}
.app.blue .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #e91e63;
}
.app.blue .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #1976d2;
}
.app.blue .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(25, 118, 210, 0.54);
}
.app.blue .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #1976d2;
}
.app.blue .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff5252;
}
.app.blue .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 82, 82, 0.54);
}
.app.blue .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #ff5252;
}
.app.blue .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.app.blue .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.app.blue .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.blue .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.app.blue .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #1976d2;
}
.app.blue .mat-radio-button.mat-primary .mat-radio-inner-circle,
.app.blue .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.blue .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .app.blue .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #1976d2;
}
.app.blue .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e91e63;
}
.app.blue .mat-radio-button.mat-accent .mat-radio-inner-circle,
.app.blue .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.blue .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .app.blue .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #e91e63;
}
.app.blue .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff5252;
}
.app.blue .mat-radio-button.mat-warn .mat-radio-inner-circle,
.app.blue .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.blue .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .app.blue .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #ff5252;
}
.app.blue .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.app.blue .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.blue .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.app.blue .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.blue .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.app.blue .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.app.blue .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.blue .mat-slider.mat-primary .mat-slider-track-fill,
.app.blue .mat-slider.mat-primary .mat-slider-thumb,
.app.blue .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #1976d2;
}
.app.blue .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.app.blue .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(25, 118, 210, 0.2);
}
.app.blue .mat-slider.mat-accent .mat-slider-track-fill,
.app.blue .mat-slider.mat-accent .mat-slider-thumb,
.app.blue .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #e91e63;
}
.app.blue .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.app.blue .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(233, 30, 99, 0.2);
}
.app.blue .mat-slider.mat-warn .mat-slider-track-fill,
.app.blue .mat-slider.mat-warn .mat-slider-thumb,
.app.blue .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #ff5252;
}
.app.blue .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.app.blue .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(255, 82, 82, 0.2);
}
.app.blue .mat-slider:hover .mat-slider-track-background,
.app.blue .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.blue .mat-slider.mat-slider-disabled .mat-slider-track-background,
.app.blue .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.app.blue .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.blue .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.blue .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.blue .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.app.blue .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.app.blue .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.app.blue .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.blue .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.app.blue .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .app.blue .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.blue .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .app.blue .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.app.blue .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.app.blue .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.app.blue .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.app.blue .mat-menu-panel {
  background: white;
}
.app.blue .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.app.blue .mat-menu-item[disabled],
.app.blue .mat-menu-item[disabled] .mat-menu-submenu-icon,
.app.blue .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.app.blue .mat-menu-item .mat-icon-no-color,
.app.blue .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.app.blue .mat-menu-item:hover:not([disabled]),
.app.blue .mat-menu-item.cdk-program-focused:not([disabled]),
.app.blue .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.app.blue .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.app.blue .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.app.blue .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.app.blue .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.app.blue .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.app.blue .mat-list-option:hover, .app.blue .mat-list-option:focus,
.app.blue .mat-nav-list .mat-list-item:hover,
.app.blue .mat-nav-list .mat-list-item:focus,
.app.blue .mat-action-list .mat-list-item:hover,
.app.blue .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.app.blue .mat-list-single-selected-option, .app.blue .mat-list-single-selected-option:hover, .app.blue .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.app.blue .mat-paginator {
  background: white;
}
.app.blue .mat-paginator,
.app.blue .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.app.blue .mat-paginator-decrement,
.app.blue .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.app.blue .mat-paginator-first,
.app.blue .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.app.blue .mat-icon-button[disabled] .mat-paginator-decrement,
.app.blue .mat-icon-button[disabled] .mat-paginator-increment,
.app.blue .mat-icon-button[disabled] .mat-paginator-first,
.app.blue .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.blue .mat-paginator-container {
  min-height: 56px;
}
.app.blue .mat-tab-nav-bar,
.app.blue .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.app.blue .mat-tab-group-inverted-header .mat-tab-nav-bar,
.app.blue .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.app.blue .mat-tab-label, .app.blue .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.app.blue .mat-tab-label.mat-tab-disabled, .app.blue .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.app.blue .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.app.blue .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.blue .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.app.blue .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.app.blue .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused, .app.blue .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused,
.app.blue .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused,
.app.blue .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused, .app.blue .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused, .app.blue .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused,
.app.blue .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused,
.app.blue .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused {
  background-color: rgba(227, 242, 253, 0.3);
}
.app.blue .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.blue .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.blue .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.blue .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled, .app.blue .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.blue .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.blue .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.blue .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.blue .mat-tab-group.mat-primary .mat-ink-bar, .app.blue .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #1976d2;
}
.app.blue .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .app.blue .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .app.blue .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .app.blue .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.app.blue .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused, .app.blue .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused,
.app.blue .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused,
.app.blue .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused, .app.blue .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused, .app.blue .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused,
.app.blue .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused,
.app.blue .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused {
  background-color: rgba(255, 64, 129, 0.3);
}
.app.blue .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.blue .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.blue .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.blue .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled, .app.blue .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.blue .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.blue .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.blue .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.blue .mat-tab-group.mat-accent .mat-ink-bar, .app.blue .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #e91e63;
}
.app.blue .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .app.blue .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .app.blue .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .app.blue .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.app.blue .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused, .app.blue .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused,
.app.blue .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused,
.app.blue .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused, .app.blue .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused, .app.blue .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused,
.app.blue .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused,
.app.blue .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused {
  background-color: rgba(255, 205, 210, 0.3);
}
.app.blue .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.blue .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.blue .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.blue .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled, .app.blue .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.blue .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.blue .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.blue .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.blue .mat-tab-group.mat-warn .mat-ink-bar, .app.blue .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #ff5252;
}
.app.blue .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .app.blue .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .app.blue .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .app.blue .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.app.blue .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused, .app.blue .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused,
.app.blue .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused,
.app.blue .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused, .app.blue .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused, .app.blue .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused,
.app.blue .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused,
.app.blue .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused {
  background-color: rgba(227, 242, 253, 0.3);
}
.app.blue .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.blue .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.blue .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.blue .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled, .app.blue .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.blue .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.blue .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.blue .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.blue .mat-tab-group.mat-background-primary > .mat-tab-header, .app.blue .mat-tab-group.mat-background-primary > .mat-tab-link-container, .app.blue .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .app.blue .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .app.blue .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .app.blue .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #1976d2;
}
.app.blue .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .app.blue .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .app.blue .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .app.blue .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.app.blue .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.blue .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .app.blue .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.blue .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.blue .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.blue .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.blue .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.app.blue .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .app.blue .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.blue .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.blue .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.app.blue .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.app.blue .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.blue .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.blue .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.blue .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.app.blue .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.app.blue .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.app.blue .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .app.blue .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.app.blue .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.app.blue .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.app.blue .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused, .app.blue .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused,
.app.blue .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused,
.app.blue .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused, .app.blue .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused, .app.blue .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused,
.app.blue .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused,
.app.blue .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused {
  background-color: rgba(255, 64, 129, 0.3);
}
.app.blue .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.blue .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.blue .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.blue .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled, .app.blue .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.blue .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.blue .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.blue .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.blue .mat-tab-group.mat-background-accent > .mat-tab-header, .app.blue .mat-tab-group.mat-background-accent > .mat-tab-link-container, .app.blue .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .app.blue .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .app.blue .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .app.blue .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #e91e63;
}
.app.blue .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .app.blue .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .app.blue .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .app.blue .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.app.blue .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.blue .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .app.blue .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.blue .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.blue .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.blue .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.blue .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.app.blue .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .app.blue .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.blue .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.blue .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.app.blue .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.app.blue .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.blue .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.blue .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.blue .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.app.blue .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.app.blue .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.app.blue .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .app.blue .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.app.blue .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.app.blue .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.app.blue .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused, .app.blue .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused,
.app.blue .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused,
.app.blue .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused, .app.blue .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused, .app.blue .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused,
.app.blue .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused,
.app.blue .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused {
  background-color: rgba(255, 205, 210, 0.3);
}
.app.blue .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.blue .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.blue .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.blue .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled, .app.blue .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.blue .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.blue .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.blue .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.blue .mat-tab-group.mat-background-warn > .mat-tab-header, .app.blue .mat-tab-group.mat-background-warn > .mat-tab-link-container, .app.blue .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .app.blue .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .app.blue .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .app.blue .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #ff5252;
}
.app.blue .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .app.blue .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .app.blue .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .app.blue .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.app.blue .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.blue .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .app.blue .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.blue .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.blue .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.blue .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.blue .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.app.blue .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .app.blue .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.blue .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.blue .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.app.blue .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.app.blue .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.blue .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.blue .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.blue .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.app.blue .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.app.blue .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.app.blue .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .app.blue .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.app.blue .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.app.blue .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.app.blue .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-simple-snackbar-action {
  color: #e91e63;
}
.app.blue .mat-table {
  background: white;
}
.app.blue .mat-table thead, .app.blue .mat-table tbody, .app.blue .mat-table tfoot,
.app.blue mat-header-row, .app.blue mat-row, .app.blue mat-footer-row,
.app.blue [mat-header-row], .app.blue [mat-row], .app.blue [mat-footer-row],
.app.blue .mat-table-sticky {
  background: inherit;
}
.app.blue mat-row, .app.blue mat-header-row, .app.blue mat-footer-row,
.app.blue th.mat-header-cell, .app.blue td.mat-cell, .app.blue td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.app.blue .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.app.blue .mat-cell, .app.blue .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.app.blue .mat-badge {
  position: relative;
}
.app.blue .mat-badge.mat-badge {
  overflow: visible;
}
.app.blue .mat-badge-hidden .mat-badge-content {
  display: none;
}
.app.blue .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.app.blue .ng-animate-disabled .mat-badge-content,
.app.blue .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.app.blue .mat-badge-content.mat-badge-active {
  transform: none;
}
.app.blue .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.app.blue .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.app.blue .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.app.blue .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .app.blue .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.app.blue .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .app.blue .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.app.blue .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .app.blue .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.app.blue .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .app.blue .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.app.blue .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.app.blue .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.app.blue .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.app.blue .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .app.blue .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.app.blue .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .app.blue .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.app.blue .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .app.blue .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.app.blue .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .app.blue .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.app.blue .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.app.blue .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.app.blue .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.app.blue .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .app.blue .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.app.blue .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .app.blue .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.app.blue .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .app.blue .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.app.blue .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .app.blue .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.app.blue .mat-badge-content {
  color: white;
  background: #1976d2;
}
.cdk-high-contrast-active .app.blue .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.app.blue .mat-badge-accent .mat-badge-content {
  background: #e91e63;
  color: white;
}
.app.blue .mat-badge-warn .mat-badge-content {
  color: white;
  background: #ff5252;
}
.app.blue .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.app.blue .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.blue .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.app.blue .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.app.blue .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.app.blue .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.app.blue .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.blue .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.app.blue .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.app.blue .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.app.blue [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.app.blue .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.app.blue .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.app.blue .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.app.blue .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.app.blue .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.app.blue .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.app.blue .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.app.blue .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.app.blue .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}
.app.blue .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.app.blue .mat-datepicker-toggle,
.app.blue .mat-datepicker-content .mat-calendar-next-button,
.app.blue .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.app.blue .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.app.blue .mat-calendar-table-header,
.app.blue .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.blue .mat-calendar-body-cell-content,
.app.blue .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.app.blue .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.app.blue .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.app.blue .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.app.blue .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.blue .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.app.blue .mat-calendar-body-in-range::before {
  background: rgba(25, 118, 210, 0.2);
}
.app.blue .mat-calendar-body-comparison-identical,
.app.blue .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.blue .mat-calendar-body-comparison-bridge-start::before,
.app.blue [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(25, 118, 210, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.blue .mat-calendar-body-comparison-bridge-end::before,
.app.blue [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(25, 118, 210, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.blue .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.blue .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.blue .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.blue .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.blue .mat-calendar-body-selected {
  background-color: #1976d2;
  color: white;
}
.app.blue .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(25, 118, 210, 0.4);
}
.app.blue .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.blue .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.blue .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(25, 118, 210, 0.3);
}
@media (hover: hover) {
  .app.blue .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(25, 118, 210, 0.3);
  }
}
.app.blue .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.blue .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(233, 30, 99, 0.2);
}
.app.blue .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.app.blue .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.blue .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.app.blue .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(233, 30, 99, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.blue .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.app.blue .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(233, 30, 99, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.blue .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.blue .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.blue .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.blue .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.blue .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #e91e63;
  color: white;
}
.app.blue .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(233, 30, 99, 0.4);
}
.app.blue .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.blue .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.blue .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(233, 30, 99, 0.3);
}
@media (hover: hover) {
  .app.blue .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(233, 30, 99, 0.3);
  }
}
.app.blue .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(255, 82, 82, 0.2);
}
.app.blue .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.app.blue .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.blue .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.app.blue .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 82, 82, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.blue .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.app.blue .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 82, 82, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.blue .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.blue .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.blue .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.blue .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.blue .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #ff5252;
  color: white;
}
.app.blue .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 82, 82, 0.4);
}
.app.blue .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.blue .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.blue .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 82, 82, 0.3);
}
@media (hover: hover) {
  .app.blue .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 82, 82, 0.3);
  }
}
.app.blue .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-datepicker-toggle-active {
  color: #1976d2;
}
.app.blue .mat-datepicker-toggle-active.mat-accent {
  color: #e91e63;
}
.app.blue .mat-datepicker-toggle-active.mat-warn {
  color: #ff5252;
}
.app.blue .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.app.blue .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  width: 40px;
  height: 40px;
  padding: 8px;
}
.app.blue .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.app.blue .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 40px;
  height: 40px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  margin-left: 0px;
}
.app.blue .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.app.blue .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 40px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 40px;
  transform: translate(-50%, -50%);
}
.app.blue .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}
.app.blue .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.blue .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.app.blue .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.blue .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.blue .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .app.blue .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .app.blue .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .app.blue .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.app.blue .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.app.blue .mat-expansion-panel-header-description,
.app.blue .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.app.blue .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.app.blue .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.app.blue .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.app.blue .mat-expansion-panel-header {
  height: 48px;
}
.app.blue .mat-expansion-panel-header.mat-expanded {
  height: 64px;
}
.app.blue .mat-icon.mat-primary {
  color: #1976d2;
}
.app.blue .mat-icon.mat-accent {
  color: #e91e63;
}
.app.blue .mat-icon.mat-warn {
  color: #ff5252;
}
.app.blue .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app.blue .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.blue .mat-drawer.mat-drawer-push {
  background-color: white;
}
.app.blue .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.app.blue [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.app.blue [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app.blue .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.app.blue .mat-step-header.cdk-keyboard-focused, .app.blue .mat-step-header.cdk-program-focused, .app.blue .mat-step-header:hover:not([aria-disabled]), .app.blue .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.app.blue .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .app.blue .mat-step-header:hover {
    background: none;
  }
}
.app.blue .mat-step-header .mat-step-label,
.app.blue .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.app.blue .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.app.blue .mat-step-header .mat-step-icon-selected,
.app.blue .mat-step-header .mat-step-icon-state-done,
.app.blue .mat-step-header .mat-step-icon-state-edit {
  background-color: #1976d2;
  color: white;
}
.app.blue .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.app.blue .mat-step-header.mat-accent .mat-step-icon-selected,
.app.blue .mat-step-header.mat-accent .mat-step-icon-state-done,
.app.blue .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #e91e63;
  color: white;
}
.app.blue .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.app.blue .mat-step-header.mat-warn .mat-step-icon-selected,
.app.blue .mat-step-header.mat-warn .mat-step-icon-state-done,
.app.blue .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #ff5252;
  color: white;
}
.app.blue .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #ff5252;
}
.app.blue .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.app.blue .mat-step-header .mat-step-label.mat-step-label-error {
  color: #ff5252;
}
.app.blue .mat-stepper-horizontal, .app.blue .mat-stepper-vertical {
  background-color: white;
}
.app.blue .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.app.blue .mat-horizontal-stepper-header::before,
.app.blue .mat-horizontal-stepper-header::after,
.app.blue .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.blue .mat-horizontal-stepper-header {
  height: 72px;
}
.app.blue .mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.app.blue .mat-vertical-stepper-header {
  padding: 24px 24px;
}
.app.blue .mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}
.app.blue .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .app.blue .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}
.app.blue .mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}
.app.blue .mat-sort-header-arrow {
  color: #757575;
}
.app.blue .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.app.blue .mat-toolbar.mat-primary {
  background: #1976d2;
  color: white;
}
.app.blue .mat-toolbar.mat-accent {
  background: #e91e63;
  color: white;
}
.app.blue .mat-toolbar.mat-warn {
  background: #ff5252;
  color: white;
}
.app.blue .mat-toolbar .mat-form-field-underline,
.app.blue .mat-toolbar .mat-form-field-ripple,
.app.blue .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.app.blue .mat-toolbar .mat-form-field-label,
.app.blue .mat-toolbar .mat-focused .mat-form-field-label,
.app.blue .mat-toolbar .mat-select-value,
.app.blue .mat-toolbar .mat-select-arrow,
.app.blue .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.app.blue .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.app.blue .mat-toolbar-multiple-rows {
  min-height: 44px;
}
.app.blue .mat-toolbar-row, .app.blue .mat-toolbar-single-row {
  height: 44px;
}
@media (max-width: 599px) {
  .app.blue .mat-toolbar-multiple-rows {
    min-height: 56px;
  }
  .app.blue .mat-toolbar-row, .app.blue .mat-toolbar-single-row {
    height: 56px;
  }
}
.app.blue .mat-tree {
  background: white;
}
.app.blue .mat-tree-node,
.app.blue .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.app.blue .mat-tree-node {
  min-height: 48px;
}
.app.blue .bg-primary {
  background-color: var(--primary);
  color: white;
}
.app.blue .bg-accent {
  background-color: var(--accent);
  color: white;
}
.app.blue .bg-warn {
  background-color: var(--warn);
  color: white;
}
.app.blue .text-muted {
  color: rgba(0, 0, 0, 0.54) !important;
}
.app.blue .primary-color {
  color: #1976d2;
}
.app.blue .secondary-color {
  color: #bbdefb;
}
.app.blue .v-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.blue .divider {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.app.blue .top-toolbar.mat-toolbar .v-divider {
  background-color: rgba(227, 242, 253, 0.2);
}
.app.blue .border-bottom-lighter {
  border-bottom: 1px solid rgba(227, 242, 253, 0.2);
}
.app.blue .border-lighter {
  border-color: rgba(227, 242, 253, 0.2);
}
.app.blue .border-accent {
  border-color: #e91e63;
}
.app.blue .border-primary {
  border-color: #1976d2 !important;
}
.app.blue #top-toolbar {
  background-color: #2196f3;
  color: white;
}
.app.blue .toolbar-2 #top-toolbar {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.blue #main-toolbar {
  background: white;
}
.app.blue .toolbar-2 #main-toolbar {
  color: var(--primary);
}
.app.blue header .logo svg {
  fill: #1976d2;
}
.app.blue header.toolbar-1.has-bg-image:not(.main-toolbar-fixed) .logo svg, .app.blue header.toolbar-1.has-bg-video:not(.main-toolbar-fixed) .logo svg {
  fill: #fff;
}
.app.blue header.toolbar-1.has-bg-image:not(.main-toolbar-fixed) .horizontal-menu .mat-button, .app.blue header.toolbar-1.has-bg-video:not(.main-toolbar-fixed) .horizontal-menu .mat-button {
  color: #fff;
}
.app.blue header.toolbar-1.has-bg-image:not(.main-toolbar-fixed) .horizontal-menu .mat-button.horizontal-active-link, .app.blue header.toolbar-1.has-bg-video:not(.main-toolbar-fixed) .horizontal-menu .mat-button.horizontal-active-link {
  background: rgba(25, 118, 210, 0.38);
}
.app.blue header.toolbar-1.has-bg-image:not(.main-toolbar-fixed) .mat-icon, .app.blue header.toolbar-1.has-bg-video:not(.main-toolbar-fixed) .mat-icon {
  color: #fff;
}
.app.blue header.toolbar-2 .horizontal-menu .mat-button {
  color: #fff;
}
.app.blue header.toolbar-2 .horizontal-menu .mat-button.horizontal-active-link {
  color: #fff;
}
.app.blue header.toolbar-2.has-bg-image:not(.main-toolbar-fixed) .horizontal-menu .mat-button.horizontal-active-link, .app.blue header.toolbar-2.has-bg-video:not(.main-toolbar-fixed) .horizontal-menu .mat-button.horizontal-active-link {
  background: rgba(25, 118, 210, 0.38);
}
.app.blue .header-carousel .slide-info .mat-card {
  background: rgba(255, 255, 255, 0.85);
}
.app.blue .header-carousel .slide-info .mat-card:hover {
  background: white;
}
.app.blue .user-menu .user-info {
  color: rgba(0, 0, 0, 0.87);
}
.app.blue main.content-offset-to-top .main-content-header {
  border-top: 5px solid #1976d2;
}
.app.blue .map-info-window .si-content-wrapper {
  padding: 2px;
  color: var(--primary);
}
.app.blue .map-info-window .si-content-wrapper .si-content {
  max-width: 100% !important;
  max-height: 100% !important;
}
.app.blue .map-info-window .si-content-wrapper .si-content a {
  color: inherit;
  text-decoration: none;
}
.app.blue .map-info-window .si-pointer-bg-top {
  border-top-color: #1976d2;
}
.app.blue .map-info-window .si-pointer-border-top {
  border-top-color: #1976d2;
}
.app.blue .map-info-window .si-close-button {
  opacity: 1;
  right: 4px;
  top: 4px;
  padding: 0 6px;
  border-radius: 50%;
  color: var(--primary);
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.blue .map-info-window.si-has-border .si-content-wrapper {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.blue .property-item .title a,
.app.blue .compare-item .title a {
  color: rgba(0, 0, 0, 0.87);
}
.app.blue .property-item .title a:hover,
.app.blue .compare-item .title a:hover {
  color: #1976d2;
}
.app.blue .features p:after,
.app.blue .compare-item .details .item {
  border-bottom: 1px dotted rgba(0, 0, 0, 0.12);
}
.app.blue .small-carousel .swiper-slide.active-thumb img {
  border-color: #1976d2;
}
.app.blue .ps__thumb-y {
  background-color: #0d47a1;
}
.app.blue .active-link {
  background-color: #1976d2;
  color: #fff;
}
.app.blue .horizontal-active-link {
  background: rgba(0, 0, 0, 0.04);
  color: #1976d2;
}
.app.blue .horizontal-sub-menu .mat-button, .app.blue .horizontal-sub-menu .mat-menu-item {
  color: rgba(0, 0, 0, 0.87);
}
.app.blue .horizontal-sub-menu .mat-button.horizontal-active-link, .app.blue .horizontal-sub-menu .mat-menu-item.horizontal-active-link {
  color: #1976d2;
}
.app.blue .section .section-title:after,
.app.blue .section.testimonials .swiper-pagination-bullet-active {
  background-color: #1976d2;
}
.app.blue .custom-table.mat-table .mat-header-row,
.app.blue .custom-table.mat-table .mat-row {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.blue .submit-property .step-section,
.app.blue .edit-property .step-section {
  background: rgba(250, 250, 250, 0.7);
}
.app.blue .input-file-container .mat-orphan-label {
  color: rgba(0, 0, 0, 0.87) !important;
}
.app.blue .nav-pills .nav-link.active, .app.blue .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #1976d2;
}
.app.blue .btn-danger {
  color: #fff;
  background-color: #1976d2;
  border-color: #1976d2;
}
.app.blue .btn-danger:not(:disabled):not(.disabled):active, .app.blue .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #1976d2;
  border-color: #1976d2;
}
.app.blue .btn-danger:hover {
  color: #fff;
  background-color: #1976d2;
  border-color: #1976d2;
}
.app.blue .btn-outline-danger {
  color: #1976d2;
  border-color: #1976d2;
}
.app.blue .btn-outline-danger:not(:disabled):not(.disabled):active, .app.blue .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #1976d2;
  border-color: #1976d2;
}
.app.blue .btn-outline-danger:hover {
  color: #fff;
  background-color: #1976d2;
  border-color: #1976d2;
}
.app.blue .pr-clr {
  color: #1976d2;
}
.app.blue .bg-primary {
  background-color: #1976d2 !important;
}
.app.blue a {
  color: #1976d2;
  text-decoration: none;
  background-color: transparent;
}
.app.blue .containers input:checked ~ .checkmark {
  background-color: #1976d2;
}
.app.blue .check input:checked ~ .checkmarks {
  background-color: #1976d2;
}
.app.blue .btn-outline-danger:not(:disabled):not(.disabled).active, .app.blue .btn-outline-danger:not(:disabled):not(.disabled):active, .app.blue .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #1976d2;
  border-color: #1976d2;
}
.app.red {
  /* When the radio button is checked, add a blue background */
  /* When the checkbox is checked, add a blue background */
}
.app.red .mat-button, .app.red .mat-icon-button, .app.red .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.app.red .mat-button.mat-primary, .app.red .mat-icon-button.mat-primary, .app.red .mat-stroked-button.mat-primary {
  color: #d32f2f;
}
.app.red .mat-button.mat-accent, .app.red .mat-icon-button.mat-accent, .app.red .mat-stroked-button.mat-accent {
  color: #43a047;
}
.app.red .mat-button.mat-warn, .app.red .mat-icon-button.mat-warn, .app.red .mat-stroked-button.mat-warn {
  color: #ff3d00;
}
.app.red .mat-button.mat-primary.mat-button-disabled, .app.red .mat-button.mat-accent.mat-button-disabled, .app.red .mat-button.mat-warn.mat-button-disabled, .app.red .mat-button.mat-button-disabled.mat-button-disabled, .app.red .mat-icon-button.mat-primary.mat-button-disabled, .app.red .mat-icon-button.mat-accent.mat-button-disabled, .app.red .mat-icon-button.mat-warn.mat-button-disabled, .app.red .mat-icon-button.mat-button-disabled.mat-button-disabled, .app.red .mat-stroked-button.mat-primary.mat-button-disabled, .app.red .mat-stroked-button.mat-accent.mat-button-disabled, .app.red .mat-stroked-button.mat-warn.mat-button-disabled, .app.red .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.app.red .mat-button.mat-primary .mat-button-focus-overlay, .app.red .mat-icon-button.mat-primary .mat-button-focus-overlay, .app.red .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #d32f2f;
}
.app.red .mat-button.mat-accent .mat-button-focus-overlay, .app.red .mat-icon-button.mat-accent .mat-button-focus-overlay, .app.red .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #43a047;
}
.app.red .mat-button.mat-warn .mat-button-focus-overlay, .app.red .mat-icon-button.mat-warn .mat-button-focus-overlay, .app.red .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #ff3d00;
}
.app.red .mat-button.mat-button-disabled .mat-button-focus-overlay, .app.red .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .app.red .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.app.red .mat-button .mat-ripple-element, .app.red .mat-icon-button .mat-ripple-element, .app.red .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.app.red .mat-button-focus-overlay {
  background: black;
}
.app.red .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.red .mat-flat-button, .app.red .mat-raised-button, .app.red .mat-fab, .app.red .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.app.red .mat-flat-button.mat-primary, .app.red .mat-raised-button.mat-primary, .app.red .mat-fab.mat-primary, .app.red .mat-mini-fab.mat-primary {
  color: white;
}
.app.red .mat-flat-button.mat-accent, .app.red .mat-raised-button.mat-accent, .app.red .mat-fab.mat-accent, .app.red .mat-mini-fab.mat-accent {
  color: white;
}
.app.red .mat-flat-button.mat-warn, .app.red .mat-raised-button.mat-warn, .app.red .mat-fab.mat-warn, .app.red .mat-mini-fab.mat-warn {
  color: white;
}
.app.red .mat-flat-button.mat-primary.mat-button-disabled, .app.red .mat-flat-button.mat-accent.mat-button-disabled, .app.red .mat-flat-button.mat-warn.mat-button-disabled, .app.red .mat-flat-button.mat-button-disabled.mat-button-disabled, .app.red .mat-raised-button.mat-primary.mat-button-disabled, .app.red .mat-raised-button.mat-accent.mat-button-disabled, .app.red .mat-raised-button.mat-warn.mat-button-disabled, .app.red .mat-raised-button.mat-button-disabled.mat-button-disabled, .app.red .mat-fab.mat-primary.mat-button-disabled, .app.red .mat-fab.mat-accent.mat-button-disabled, .app.red .mat-fab.mat-warn.mat-button-disabled, .app.red .mat-fab.mat-button-disabled.mat-button-disabled, .app.red .mat-mini-fab.mat-primary.mat-button-disabled, .app.red .mat-mini-fab.mat-accent.mat-button-disabled, .app.red .mat-mini-fab.mat-warn.mat-button-disabled, .app.red .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.app.red .mat-flat-button.mat-primary, .app.red .mat-raised-button.mat-primary, .app.red .mat-fab.mat-primary, .app.red .mat-mini-fab.mat-primary {
  background-color: #d32f2f;
}
.app.red .mat-flat-button.mat-accent, .app.red .mat-raised-button.mat-accent, .app.red .mat-fab.mat-accent, .app.red .mat-mini-fab.mat-accent {
  background-color: #43a047;
}
.app.red .mat-flat-button.mat-warn, .app.red .mat-raised-button.mat-warn, .app.red .mat-fab.mat-warn, .app.red .mat-mini-fab.mat-warn {
  background-color: #ff3d00;
}
.app.red .mat-flat-button.mat-primary.mat-button-disabled, .app.red .mat-flat-button.mat-accent.mat-button-disabled, .app.red .mat-flat-button.mat-warn.mat-button-disabled, .app.red .mat-flat-button.mat-button-disabled.mat-button-disabled, .app.red .mat-raised-button.mat-primary.mat-button-disabled, .app.red .mat-raised-button.mat-accent.mat-button-disabled, .app.red .mat-raised-button.mat-warn.mat-button-disabled, .app.red .mat-raised-button.mat-button-disabled.mat-button-disabled, .app.red .mat-fab.mat-primary.mat-button-disabled, .app.red .mat-fab.mat-accent.mat-button-disabled, .app.red .mat-fab.mat-warn.mat-button-disabled, .app.red .mat-fab.mat-button-disabled.mat-button-disabled, .app.red .mat-mini-fab.mat-primary.mat-button-disabled, .app.red .mat-mini-fab.mat-accent.mat-button-disabled, .app.red .mat-mini-fab.mat-warn.mat-button-disabled, .app.red .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.red .mat-flat-button.mat-primary .mat-ripple-element, .app.red .mat-raised-button.mat-primary .mat-ripple-element, .app.red .mat-fab.mat-primary .mat-ripple-element, .app.red .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.red .mat-flat-button.mat-accent .mat-ripple-element, .app.red .mat-raised-button.mat-accent .mat-ripple-element, .app.red .mat-fab.mat-accent .mat-ripple-element, .app.red .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.red .mat-flat-button.mat-warn .mat-ripple-element, .app.red .mat-raised-button.mat-warn .mat-ripple-element, .app.red .mat-fab.mat-warn .mat-ripple-element, .app.red .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.red .mat-stroked-button:not([class*=mat-elevation-z]), .app.red .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.red .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.red .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.red .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.red .mat-fab:not([class*=mat-elevation-z]), .app.red .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.red .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .app.red .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.red .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .app.red .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.red .mat-datepicker-content .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.app.red .mat-datepicker-content .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #d32f2f;
}
.app.red .mat-datepicker-content .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #43a047;
}
.app.red .mat-datepicker-content .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #ff3d00;
}
.app.red .mat-datepicker-content .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.app.red .mat-datepicker-content .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.app.red .mat-datepicker-content .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #d32f2f;
  --mdc-filled-button-label-text-color: #fff;
}
.app.red .mat-datepicker-content .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #43a047;
  --mdc-filled-button-label-text-color: #fff;
}
.app.red .mat-datepicker-content .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #ff3d00;
  --mdc-filled-button-label-text-color: #fff;
}
.app.red .mat-datepicker-content .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.app.red .mat-datepicker-content .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.app.red .mat-datepicker-content .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #d32f2f;
  --mdc-protected-button-label-text-color: #fff;
}
.app.red .mat-datepicker-content .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #43a047;
  --mdc-protected-button-label-text-color: #fff;
}
.app.red .mat-datepicker-content .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #ff3d00;
  --mdc-protected-button-label-text-color: #fff;
}
.app.red .mat-datepicker-content .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}
.app.red .mat-datepicker-content .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.app.red .mat-datepicker-content .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.app.red .mat-datepicker-content .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #d32f2f;
}
.app.red .mat-datepicker-content .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #43a047;
}
.app.red .mat-datepicker-content .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #ff3d00;
}
.app.red .mat-datepicker-content .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}
.app.red .mat-datepicker-content .mat-mdc-button, .app.red .mat-datepicker-content .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.app.red .mat-datepicker-content .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .app.red .mat-datepicker-content .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.app.red .mat-datepicker-content .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.red .mat-datepicker-content .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .app.red .mat-datepicker-content .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.red .mat-datepicker-content .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.red .mat-datepicker-content .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .app.red .mat-datepicker-content .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.red .mat-datepicker-content .mat-mdc-button.mat-primary, .app.red .mat-datepicker-content .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #d32f2f;
  --mat-mdc-button-ripple-color: rgba(211, 47, 47, 0.1);
}
.app.red .mat-datepicker-content .mat-mdc-button.mat-accent, .app.red .mat-datepicker-content .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #43a047;
  --mat-mdc-button-ripple-color: rgba(67, 160, 71, 0.1);
}
.app.red .mat-datepicker-content .mat-mdc-button.mat-warn, .app.red .mat-datepicker-content .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #ff3d00;
  --mat-mdc-button-ripple-color: rgba(255, 61, 0, 0.1);
}
.app.red .mat-datepicker-content .mat-mdc-raised-button, .app.red .mat-datepicker-content .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.app.red .mat-datepicker-content .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .app.red .mat-datepicker-content .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.app.red .mat-datepicker-content .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.red .mat-datepicker-content .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .app.red .mat-datepicker-content .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.red .mat-datepicker-content .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.red .mat-datepicker-content .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .app.red .mat-datepicker-content .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.red .mat-datepicker-content .mat-mdc-raised-button.mat-primary, .app.red .mat-datepicker-content .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.app.red .mat-datepicker-content .mat-mdc-raised-button.mat-accent, .app.red .mat-datepicker-content .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.app.red .mat-datepicker-content .mat-mdc-raised-button.mat-warn, .app.red .mat-datepicker-content .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.app.red .mat-datepicker-content .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.app.red .mat-datepicker-content .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.app.red .mat-datepicker-content .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.red .mat-datepicker-content .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.red .mat-datepicker-content .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.red .mat-datepicker-content .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #d32f2f;
  --mat-mdc-button-ripple-color: rgba(211, 47, 47, 0.1);
}
.app.red .mat-datepicker-content .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #43a047;
  --mat-mdc-button-ripple-color: rgba(67, 160, 71, 0.1);
}
.app.red .mat-datepicker-content .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #ff3d00;
  --mat-mdc-button-ripple-color: rgba(255, 61, 0, 0.1);
}
.app.red .mat-datepicker-content .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #d32f2f;
}
.app.red .mat-datepicker-content .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #43a047;
}
.app.red .mat-datepicker-content .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #ff3d00;
}
.app.red .mat-datepicker-content .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}
.app.red .mat-datepicker-content .mat-mdc-button.mat-mdc-button-base,
.app.red .mat-datepicker-content .mat-mdc-raised-button.mat-mdc-button-base,
.app.red .mat-datepicker-content .mat-mdc-unelevated-button.mat-mdc-button-base,
.app.red .mat-datepicker-content .mat-mdc-outlined-button.mat-mdc-button-base {
  height: 36px;
}
.app.red .mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base {
  width: 48px;
  height: 48px;
  padding: 12px;
}
.app.red .mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__focus-ring {
  max-height: 48px;
  max-width: 48px;
}
.app.red .mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 40px;
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 4px;
  margin-left: 4px;
}
.app.red .mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.app.red .mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 48px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 48px;
  transform: translate(-50%, -50%);
}
.app.red .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.app.red .mat-option:hover:not(.mat-option-disabled), .app.red .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.app.red .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.app.red .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.app.red .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.app.red .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #d32f2f;
}
.app.red .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #43a047;
}
.app.red .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff3d00;
}
.app.red .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.red .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.red .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.red .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.app.red .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.app.red .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.app.red .mat-primary .mat-pseudo-checkbox-checked,
.app.red .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #d32f2f;
}
.app.red .mat-pseudo-checkbox-checked,
.app.red .mat-pseudo-checkbox-indeterminate,
.app.red .mat-accent .mat-pseudo-checkbox-checked,
.app.red .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #43a047;
}
.app.red .mat-warn .mat-pseudo-checkbox-checked,
.app.red .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #ff3d00;
}
.app.red .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.app.red .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.app.red .mat-app-background, .app.red.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app.red .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.red .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.red .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.red .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.red .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.red .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.app.red .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.red .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.app.red .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.red .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.app.red .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.app.red .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.app.red .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.red .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.app.red .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.app.red .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.app.red .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.red .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.app.red .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.app.red .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.app.red .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.app.red .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.app.red .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.app.red .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.app.red .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.app.red .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.red .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.red .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.red .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.app.red .mat-progress-bar-background {
  fill: #f0c7c7;
}
.app.red .mat-progress-bar-buffer {
  background-color: #f0c7c7;
}
.app.red .mat-progress-bar-fill::after {
  background-color: #d32f2f;
}
.app.red .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #cce4cd;
}
.app.red .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #cce4cd;
}
.app.red .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #43a047;
}
.app.red .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #fbcbbc;
}
.app.red .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #fbcbbc;
}
.app.red .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #ff3d00;
}
.app.red .mat-progress-spinner circle, .app.red .mat-spinner circle {
  stroke: #d32f2f;
}
.app.red .mat-progress-spinner.mat-accent circle, .app.red .mat-spinner.mat-accent circle {
  stroke: #43a047;
}
.app.red .mat-progress-spinner.mat-warn circle, .app.red .mat-spinner.mat-warn circle {
  stroke: #ff3d00;
}
.app.red .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.app.red .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.app.red .mat-input-element:disabled,
.app.red .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.app.red .mat-input-element {
  caret-color: #d32f2f;
}
.app.red .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.red .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.red .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.red .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.red .mat-form-field.mat-accent .mat-input-element {
  caret-color: #43a047;
}
.app.red .mat-form-field.mat-warn .mat-input-element,
.app.red .mat-form-field-invalid .mat-input-element {
  caret-color: #ff3d00;
}
.app.red .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #ff3d00;
}
.app.red .mat-form-field-disabled .mat-form-field-prefix,
.app.red .mat-form-field-disabled .mat-form-field-suffix {
  color: rgba(0, 0, 0, 0.38);
}
.app.red .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.app.red .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.app.red .mat-form-field.mat-focused .mat-form-field-label {
  color: #d32f2f;
}
.app.red .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #43a047;
}
.app.red .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #ff3d00;
}
.app.red .mat-focused .mat-form-field-required-marker {
  color: #43a047;
}
.app.red .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.app.red .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #d32f2f;
}
.app.red .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #43a047;
}
.app.red .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #ff3d00;
}
.app.red .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #d32f2f;
}
.app.red .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #43a047;
}
.app.red .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #ff3d00;
}
.app.red .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #ff3d00;
}
.app.red .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.app.red .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #ff3d00;
}
.app.red .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.app.red .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #ff3d00;
}
.app.red .mat-error {
  color: #ff3d00;
}
.app.red .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.red .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.app.red .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.app.red .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app.red .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.app.red .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app.red .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.app.red .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.app.red .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.app.red .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.red .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.app.red .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.app.red .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.app.red .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #d32f2f;
}
.app.red .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #43a047;
}
.app.red .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #ff3d00;
}
.app.red .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #ff3d00;
}
.app.red .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.red .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.app.red .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.app.red .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.red .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.app.red .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.app.red .mat-select-panel {
  background: white;
}
.app.red .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.red .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.app.red .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #d32f2f;
}
.app.red .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #43a047;
}
.app.red .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #ff3d00;
}
.app.red .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #ff3d00;
}
.app.red .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.app.red .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.app.red .mat-checkbox-checkmark {
  fill: #fafafa;
}
.app.red .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.app.red .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.app.red .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .app.red .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #d32f2f;
}
.app.red .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .app.red .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #43a047;
}
.app.red .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .app.red .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #ff3d00;
}
.app.red .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .app.red .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.app.red .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.app.red .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.red .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.app.red .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.app.red .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #d32f2f;
}
.app.red .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.app.red .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #43a047;
}
.app.red .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.app.red .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #ff3d00;
}
.app.red .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.red .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.red .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.app.red .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.app.red .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.red .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.app.red .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.app.red .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.red .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.app.red .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.app.red .mat-chip.mat-standard-chip::after {
  background: black;
}
.app.red .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #d32f2f;
  color: white;
}
.app.red .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.red .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.red .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #ff3d00;
  color: white;
}
.app.red .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.red .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.red .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #43a047;
  color: white;
}
.app.red .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.red .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.red .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #43a047;
}
.app.red .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(67, 160, 71, 0.54);
}
.app.red .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #43a047;
}
.app.red .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #d32f2f;
}
.app.red .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(211, 47, 47, 0.54);
}
.app.red .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #d32f2f;
}
.app.red .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff3d00;
}
.app.red .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 61, 0, 0.54);
}
.app.red .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #ff3d00;
}
.app.red .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.app.red .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.app.red .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.red .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.app.red .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #d32f2f;
}
.app.red .mat-radio-button.mat-primary .mat-radio-inner-circle,
.app.red .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.red .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .app.red .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #d32f2f;
}
.app.red .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #43a047;
}
.app.red .mat-radio-button.mat-accent .mat-radio-inner-circle,
.app.red .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.red .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .app.red .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #43a047;
}
.app.red .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff3d00;
}
.app.red .mat-radio-button.mat-warn .mat-radio-inner-circle,
.app.red .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.red .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .app.red .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #ff3d00;
}
.app.red .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.app.red .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.red .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.app.red .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.red .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.app.red .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.app.red .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.red .mat-slider.mat-primary .mat-slider-track-fill,
.app.red .mat-slider.mat-primary .mat-slider-thumb,
.app.red .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #d32f2f;
}
.app.red .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.app.red .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(211, 47, 47, 0.2);
}
.app.red .mat-slider.mat-accent .mat-slider-track-fill,
.app.red .mat-slider.mat-accent .mat-slider-thumb,
.app.red .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #43a047;
}
.app.red .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.app.red .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(67, 160, 71, 0.2);
}
.app.red .mat-slider.mat-warn .mat-slider-track-fill,
.app.red .mat-slider.mat-warn .mat-slider-thumb,
.app.red .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #ff3d00;
}
.app.red .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.app.red .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(255, 61, 0, 0.2);
}
.app.red .mat-slider:hover .mat-slider-track-background,
.app.red .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.red .mat-slider.mat-slider-disabled .mat-slider-track-background,
.app.red .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.app.red .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.red .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.red .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.red .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.app.red .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.app.red .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.app.red .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.red .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.app.red .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .app.red .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.red .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .app.red .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.app.red .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.app.red .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.app.red .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.app.red .mat-menu-panel {
  background: white;
}
.app.red .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.red .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.app.red .mat-menu-item[disabled],
.app.red .mat-menu-item[disabled] .mat-menu-submenu-icon,
.app.red .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.app.red .mat-menu-item .mat-icon-no-color,
.app.red .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.app.red .mat-menu-item:hover:not([disabled]),
.app.red .mat-menu-item.cdk-program-focused:not([disabled]),
.app.red .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.app.red .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.app.red .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.app.red .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.app.red .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.app.red .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.app.red .mat-list-option:hover, .app.red .mat-list-option:focus,
.app.red .mat-nav-list .mat-list-item:hover,
.app.red .mat-nav-list .mat-list-item:focus,
.app.red .mat-action-list .mat-list-item:hover,
.app.red .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.app.red .mat-list-single-selected-option, .app.red .mat-list-single-selected-option:hover, .app.red .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.app.red .mat-paginator {
  background: white;
}
.app.red .mat-paginator,
.app.red .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.app.red .mat-paginator-decrement,
.app.red .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.app.red .mat-paginator-first,
.app.red .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.app.red .mat-icon-button[disabled] .mat-paginator-decrement,
.app.red .mat-icon-button[disabled] .mat-paginator-increment,
.app.red .mat-icon-button[disabled] .mat-paginator-first,
.app.red .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.red .mat-tab-nav-bar,
.app.red .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.app.red .mat-tab-group-inverted-header .mat-tab-nav-bar,
.app.red .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.app.red .mat-tab-label, .app.red .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.app.red .mat-tab-label.mat-tab-disabled, .app.red .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.app.red .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.app.red .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.red .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.app.red .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.app.red .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused, .app.red .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused,
.app.red .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused,
.app.red .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused, .app.red .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused, .app.red .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused,
.app.red .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused,
.app.red .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused {
  background-color: rgba(255, 235, 238, 0.3);
}
.app.red .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.red .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.red .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.red .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled, .app.red .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.red .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.red .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.red .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.red .mat-tab-group.mat-primary .mat-ink-bar, .app.red .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #d32f2f;
}
.app.red .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .app.red .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .app.red .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .app.red .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.app.red .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused, .app.red .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused,
.app.red .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused,
.app.red .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused, .app.red .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused, .app.red .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused,
.app.red .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused,
.app.red .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused {
  background-color: rgba(200, 230, 201, 0.3);
}
.app.red .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.red .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.red .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.red .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled, .app.red .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.red .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.red .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.red .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.red .mat-tab-group.mat-accent .mat-ink-bar, .app.red .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #43a047;
}
.app.red .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .app.red .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .app.red .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .app.red .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.app.red .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused, .app.red .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused,
.app.red .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused,
.app.red .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused, .app.red .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused, .app.red .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused,
.app.red .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused,
.app.red .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused {
  background-color: rgba(255, 204, 188, 0.3);
}
.app.red .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.red .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.red .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.red .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled, .app.red .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.red .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.red .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.red .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.red .mat-tab-group.mat-warn .mat-ink-bar, .app.red .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #ff3d00;
}
.app.red .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .app.red .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .app.red .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .app.red .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.app.red .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused, .app.red .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused,
.app.red .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused,
.app.red .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused, .app.red .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused, .app.red .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused,
.app.red .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused,
.app.red .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused {
  background-color: rgba(255, 235, 238, 0.3);
}
.app.red .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.red .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.red .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.red .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled, .app.red .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.red .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.red .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.red .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.red .mat-tab-group.mat-background-primary > .mat-tab-header, .app.red .mat-tab-group.mat-background-primary > .mat-tab-link-container, .app.red .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .app.red .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .app.red .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .app.red .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #d32f2f;
}
.app.red .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .app.red .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .app.red .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .app.red .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.app.red .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.red .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .app.red .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.red .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.red .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.red .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.red .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.app.red .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .app.red .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.red .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.red .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.app.red .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.app.red .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.red .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.red .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.red .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.app.red .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.app.red .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.app.red .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .app.red .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.app.red .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.app.red .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.app.red .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused, .app.red .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused,
.app.red .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused,
.app.red .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused, .app.red .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused, .app.red .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused,
.app.red .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused,
.app.red .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused {
  background-color: rgba(200, 230, 201, 0.3);
}
.app.red .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.red .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.red .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.red .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled, .app.red .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.red .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.red .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.red .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.red .mat-tab-group.mat-background-accent > .mat-tab-header, .app.red .mat-tab-group.mat-background-accent > .mat-tab-link-container, .app.red .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .app.red .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .app.red .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .app.red .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #43a047;
}
.app.red .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .app.red .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .app.red .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .app.red .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.app.red .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.red .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .app.red .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.red .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.red .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.red .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.red .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.app.red .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .app.red .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.red .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.red .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.app.red .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.app.red .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.red .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.red .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.red .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.app.red .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.app.red .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.app.red .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .app.red .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.app.red .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.app.red .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.app.red .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused, .app.red .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused,
.app.red .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused,
.app.red .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused, .app.red .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused, .app.red .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused,
.app.red .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused,
.app.red .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused {
  background-color: rgba(255, 204, 188, 0.3);
}
.app.red .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.red .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.red .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.red .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled, .app.red .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.red .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.red .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.red .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.red .mat-tab-group.mat-background-warn > .mat-tab-header, .app.red .mat-tab-group.mat-background-warn > .mat-tab-link-container, .app.red .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .app.red .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .app.red .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .app.red .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #ff3d00;
}
.app.red .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .app.red .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .app.red .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .app.red .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.app.red .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.red .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .app.red .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.red .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.red .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.red .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.red .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.app.red .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .app.red .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.red .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.red .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.app.red .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.app.red .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.red .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.red .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.red .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.app.red .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.app.red .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.app.red .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .app.red .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.app.red .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.app.red .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.app.red .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.red .mat-simple-snackbar-action {
  color: #43a047;
}
.app.red .mat-table {
  background: white;
}
.app.red .mat-table thead, .app.red .mat-table tbody, .app.red .mat-table tfoot,
.app.red mat-header-row, .app.red mat-row, .app.red mat-footer-row,
.app.red [mat-header-row], .app.red [mat-row], .app.red [mat-footer-row],
.app.red .mat-table-sticky {
  background: inherit;
}
.app.red mat-row, .app.red mat-header-row, .app.red mat-footer-row,
.app.red th.mat-header-cell, .app.red td.mat-cell, .app.red td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.app.red .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.app.red .mat-cell, .app.red .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.app.red .mat-badge {
  position: relative;
}
.app.red .mat-badge.mat-badge {
  overflow: visible;
}
.app.red .mat-badge-hidden .mat-badge-content {
  display: none;
}
.app.red .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.app.red .ng-animate-disabled .mat-badge-content,
.app.red .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.app.red .mat-badge-content.mat-badge-active {
  transform: none;
}
.app.red .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.app.red .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.app.red .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.app.red .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .app.red .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.app.red .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .app.red .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.app.red .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .app.red .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.app.red .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .app.red .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.app.red .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.app.red .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.app.red .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.app.red .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .app.red .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.app.red .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .app.red .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.app.red .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .app.red .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.app.red .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .app.red .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.app.red .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.app.red .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.app.red .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.app.red .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .app.red .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.app.red .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .app.red .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.app.red .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .app.red .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.app.red .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .app.red .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.app.red .mat-badge-content {
  color: white;
  background: #d32f2f;
}
.cdk-high-contrast-active .app.red .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.app.red .mat-badge-accent .mat-badge-content {
  background: #43a047;
  color: white;
}
.app.red .mat-badge-warn .mat-badge-content {
  color: white;
  background: #ff3d00;
}
.app.red .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.app.red .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.red .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.app.red .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.red .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.app.red .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.app.red .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.app.red .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.red .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.app.red .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.app.red .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.app.red [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.app.red .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.app.red .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.app.red .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.app.red .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.app.red .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.app.red .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.app.red .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.app.red .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.app.red .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.app.red .mat-datepicker-toggle,
.app.red .mat-datepicker-content .mat-calendar-next-button,
.app.red .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.app.red .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.app.red .mat-calendar-table-header,
.app.red .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.red .mat-calendar-body-cell-content,
.app.red .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.app.red .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.app.red .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.app.red .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.app.red .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.red .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.app.red .mat-calendar-body-in-range::before {
  background: rgba(211, 47, 47, 0.2);
}
.app.red .mat-calendar-body-comparison-identical,
.app.red .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.red .mat-calendar-body-comparison-bridge-start::before,
.app.red [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(211, 47, 47, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.red .mat-calendar-body-comparison-bridge-end::before,
.app.red [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(211, 47, 47, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.red .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.red .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.red .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.red .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.red .mat-calendar-body-selected {
  background-color: #d32f2f;
  color: white;
}
.app.red .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(211, 47, 47, 0.4);
}
.app.red .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.red .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.red .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(211, 47, 47, 0.3);
}
@media (hover: hover) {
  .app.red .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(211, 47, 47, 0.3);
  }
}
.app.red .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.red .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(67, 160, 71, 0.2);
}
.app.red .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.app.red .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.red .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.app.red .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(67, 160, 71, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.red .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.app.red .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(67, 160, 71, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.red .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.red .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.red .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.red .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.red .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #43a047;
  color: white;
}
.app.red .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(67, 160, 71, 0.4);
}
.app.red .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.red .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.red .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(67, 160, 71, 0.3);
}
@media (hover: hover) {
  .app.red .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(67, 160, 71, 0.3);
  }
}
.app.red .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(255, 61, 0, 0.2);
}
.app.red .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.app.red .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.red .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.app.red .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 61, 0, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.red .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.app.red .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 61, 0, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.red .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.red .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.red .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.red .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.red .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #ff3d00;
  color: white;
}
.app.red .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 61, 0, 0.4);
}
.app.red .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.red .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.red .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 61, 0, 0.3);
}
@media (hover: hover) {
  .app.red .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 61, 0, 0.3);
  }
}
.app.red .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.app.red .mat-datepicker-toggle-active {
  color: #d32f2f;
}
.app.red .mat-datepicker-toggle-active.mat-accent {
  color: #43a047;
}
.app.red .mat-datepicker-toggle-active.mat-warn {
  color: #ff3d00;
}
.app.red .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.app.red .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  width: 40px;
  height: 40px;
  padding: 8px;
}
.app.red .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.app.red .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 40px;
  height: 40px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  margin-left: 0px;
}
.app.red .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.app.red .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 40px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 40px;
  transform: translate(-50%, -50%);
}
.app.red .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}
.app.red .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.red .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.app.red .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.red .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.red .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.red .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .app.red .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .app.red .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .app.red .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.app.red .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.app.red .mat-expansion-panel-header-description,
.app.red .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.app.red .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.app.red .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.app.red .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.app.red .mat-icon.mat-primary {
  color: #d32f2f;
}
.app.red .mat-icon.mat-accent {
  color: #43a047;
}
.app.red .mat-icon.mat-warn {
  color: #ff3d00;
}
.app.red .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app.red .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.red .mat-drawer.mat-drawer-push {
  background-color: white;
}
.app.red .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.red .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app.red .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.app.red [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.app.red [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app.red .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.app.red .mat-step-header.cdk-keyboard-focused, .app.red .mat-step-header.cdk-program-focused, .app.red .mat-step-header:hover:not([aria-disabled]), .app.red .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.app.red .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .app.red .mat-step-header:hover {
    background: none;
  }
}
.app.red .mat-step-header .mat-step-label,
.app.red .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.app.red .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.app.red .mat-step-header .mat-step-icon-selected,
.app.red .mat-step-header .mat-step-icon-state-done,
.app.red .mat-step-header .mat-step-icon-state-edit {
  background-color: #d32f2f;
  color: white;
}
.app.red .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.app.red .mat-step-header.mat-accent .mat-step-icon-selected,
.app.red .mat-step-header.mat-accent .mat-step-icon-state-done,
.app.red .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #43a047;
  color: white;
}
.app.red .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.app.red .mat-step-header.mat-warn .mat-step-icon-selected,
.app.red .mat-step-header.mat-warn .mat-step-icon-state-done,
.app.red .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #ff3d00;
  color: white;
}
.app.red .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #ff3d00;
}
.app.red .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.app.red .mat-step-header .mat-step-label.mat-step-label-error {
  color: #ff3d00;
}
.app.red .mat-stepper-horizontal, .app.red .mat-stepper-vertical {
  background-color: white;
}
.app.red .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.app.red .mat-horizontal-stepper-header::before,
.app.red .mat-horizontal-stepper-header::after,
.app.red .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.red .mat-sort-header-arrow {
  color: #757575;
}
.app.red .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.app.red .mat-toolbar.mat-primary {
  background: #d32f2f;
  color: white;
}
.app.red .mat-toolbar.mat-accent {
  background: #43a047;
  color: white;
}
.app.red .mat-toolbar.mat-warn {
  background: #ff3d00;
  color: white;
}
.app.red .mat-toolbar .mat-form-field-underline,
.app.red .mat-toolbar .mat-form-field-ripple,
.app.red .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.app.red .mat-toolbar .mat-form-field-label,
.app.red .mat-toolbar .mat-focused .mat-form-field-label,
.app.red .mat-toolbar .mat-select-value,
.app.red .mat-toolbar .mat-select-arrow,
.app.red .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.app.red .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.app.red .mat-tree {
  background: white;
}
.app.red .mat-tree-node,
.app.red .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.app.red .bg-primary {
  background-color: var(--primary);
  color: white;
}
.app.red .bg-accent {
  background-color: var(--accent);
  color: white;
}
.app.red .bg-warn {
  background-color: var(--warn);
  color: white;
}
.app.red .text-muted {
  color: rgba(0, 0, 0, 0.54) !important;
}
.app.red .primary-color {
  color: #d32f2f;
}
.app.red .secondary-color {
  color: #ffcdd2;
}
.app.red .v-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.red .divider {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.app.red .top-toolbar.mat-toolbar .v-divider {
  background-color: rgba(255, 235, 238, 0.2);
}
.app.red .border-bottom-lighter {
  border-bottom: 1px solid rgba(255, 235, 238, 0.2);
}
.app.red .border-lighter {
  border-color: rgba(255, 235, 238, 0.2);
}
.app.red .border-accent {
  border-color: #43a047;
}
.app.red .border-primary {
  border-color: #d32f2f !important;
}
.app.red #top-toolbar {
  background-color: #f44336;
  color: white;
}
.app.red .toolbar-2 #top-toolbar {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.red #main-toolbar {
  background: white;
}
.app.red .toolbar-2 #main-toolbar {
  color: var(--primary);
}
.app.red header .logo svg {
  fill: #d32f2f;
}
.app.red header.toolbar-1.has-bg-image:not(.main-toolbar-fixed) .logo svg, .app.red header.toolbar-1.has-bg-video:not(.main-toolbar-fixed) .logo svg {
  fill: #fff;
}
.app.red header.toolbar-1.has-bg-image:not(.main-toolbar-fixed) .horizontal-menu .mat-button, .app.red header.toolbar-1.has-bg-video:not(.main-toolbar-fixed) .horizontal-menu .mat-button {
  color: #fff;
}
.app.red header.toolbar-1.has-bg-image:not(.main-toolbar-fixed) .horizontal-menu .mat-button.horizontal-active-link, .app.red header.toolbar-1.has-bg-video:not(.main-toolbar-fixed) .horizontal-menu .mat-button.horizontal-active-link {
  background: rgba(211, 47, 47, 0.38);
}
.app.red header.toolbar-1.has-bg-image:not(.main-toolbar-fixed) .mat-icon, .app.red header.toolbar-1.has-bg-video:not(.main-toolbar-fixed) .mat-icon {
  color: #fff;
}
.app.red header.toolbar-2 .horizontal-menu .mat-button {
  color: #fff;
}
.app.red header.toolbar-2 .horizontal-menu .mat-button.horizontal-active-link {
  color: #fff;
}
.app.red header.toolbar-2.has-bg-image:not(.main-toolbar-fixed) .horizontal-menu .mat-button.horizontal-active-link, .app.red header.toolbar-2.has-bg-video:not(.main-toolbar-fixed) .horizontal-menu .mat-button.horizontal-active-link {
  background: rgba(211, 47, 47, 0.38);
}
.app.red .header-carousel .slide-info .mat-card {
  background: rgba(255, 255, 255, 0.85);
}
.app.red .header-carousel .slide-info .mat-card:hover {
  background: white;
}
.app.red .user-menu .user-info {
  color: rgba(0, 0, 0, 0.87);
}
.app.red main.content-offset-to-top .main-content-header {
  border-top: 5px solid #d32f2f;
}
.app.red .map-info-window .si-content-wrapper {
  padding: 2px;
  color: var(--primary);
}
.app.red .map-info-window .si-content-wrapper .si-content {
  max-width: 100% !important;
  max-height: 100% !important;
}
.app.red .map-info-window .si-content-wrapper .si-content a {
  color: inherit;
  text-decoration: none;
}
.app.red .map-info-window .si-pointer-bg-top {
  border-top-color: #d32f2f;
}
.app.red .map-info-window .si-pointer-border-top {
  border-top-color: #d32f2f;
}
.app.red .map-info-window .si-close-button {
  opacity: 1;
  right: 4px;
  top: 4px;
  padding: 0 6px;
  border-radius: 50%;
  color: var(--primary);
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.red .map-info-window.si-has-border .si-content-wrapper {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.red .property-item .title a,
.app.red .compare-item .title a {
  color: rgba(0, 0, 0, 0.87);
}
.app.red .property-item .title a:hover,
.app.red .compare-item .title a:hover {
  color: #d32f2f;
}
.app.red .features p:after,
.app.red .compare-item .details .item {
  border-bottom: 1px dotted rgba(0, 0, 0, 0.12);
}
.app.red .small-carousel .swiper-slide.active-thumb img {
  border-color: #d32f2f;
}
.app.red .ps__thumb-y {
  background-color: #b71c1c;
}
.app.red .active-link {
  background-color: #d32f2f;
  color: #fff;
}
.app.red .horizontal-active-link {
  background: rgba(0, 0, 0, 0.04);
  color: #d32f2f;
}
.app.red .horizontal-sub-menu .mat-button, .app.red .horizontal-sub-menu .mat-menu-item {
  color: rgba(0, 0, 0, 0.87);
}
.app.red .horizontal-sub-menu .mat-button.horizontal-active-link, .app.red .horizontal-sub-menu .mat-menu-item.horizontal-active-link {
  color: #d32f2f;
}
.app.red .section .section-title:after,
.app.red .section.testimonials .swiper-pagination-bullet-active {
  background-color: #d32f2f;
}
.app.red .custom-table.mat-table .mat-header-row,
.app.red .custom-table.mat-table .mat-row {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.red .submit-property .step-section,
.app.red .edit-property .step-section {
  background: rgba(250, 250, 250, 0.7);
}
.app.red .input-file-container .mat-orphan-label {
  color: rgba(0, 0, 0, 0.87) !important;
}
.app.red .nav-pills .nav-link.active, .app.red .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #d32f2f;
}
.app.red .btn-danger {
  color: #fff;
  background-color: #d32f2f;
  border-color: #d32f2f;
}
.app.red .btn-danger:not(:disabled):not(.disabled):active, .app.red .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #d32f2f;
  border-color: #d32f2f;
}
.app.red .btn-danger:hover {
  color: #fff;
  background-color: #d32f2f;
  border-color: #d32f2f;
}
.app.red .btn-outline-danger {
  color: #d32f2f;
  border-color: #d32f2f;
}
.app.red .btn-outline-danger:not(:disabled):not(.disabled):active, .app.red .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #d32f2f;
  border-color: #d32f2f;
}
.app.red .btn-outline-danger:hover {
  color: #fff;
  background-color: #d32f2f;
  border-color: #d32f2f;
}
.app.red .pr-clr {
  color: #d32f2f;
}
.app.red .bg-primary {
  background-color: #d32f2f !important;
}
.app.red a {
  color: #d32f2f;
  text-decoration: none;
  background-color: transparent;
}
.app.red .containers input:checked ~ .checkmark {
  background-color: #d32f2f;
}
.app.red .check input:checked ~ .checkmarks {
  background-color: #d32f2f;
}
.app.red .btn-outline-danger:not(:disabled):not(.disabled).active, .app.red .btn-outline-danger:not(:disabled):not(.disabled):active, .app.red .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #d32f2f;
  border-color: #d32f2f;
}
.app.pink {
  /* When the radio button is checked, add a blue background */
  /* When the checkbox is checked, add a blue background */
}
.app.pink .mat-button, .app.pink .mat-icon-button, .app.pink .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.app.pink .mat-button.mat-primary, .app.pink .mat-icon-button.mat-primary, .app.pink .mat-stroked-button.mat-primary {
  color: #c2185b;
}
.app.pink .mat-button.mat-accent, .app.pink .mat-icon-button.mat-accent, .app.pink .mat-stroked-button.mat-accent {
  color: #3949ab;
}
.app.pink .mat-button.mat-warn, .app.pink .mat-icon-button.mat-warn, .app.pink .mat-stroked-button.mat-warn {
  color: #ff3d00;
}
.app.pink .mat-button.mat-primary.mat-button-disabled, .app.pink .mat-button.mat-accent.mat-button-disabled, .app.pink .mat-button.mat-warn.mat-button-disabled, .app.pink .mat-button.mat-button-disabled.mat-button-disabled, .app.pink .mat-icon-button.mat-primary.mat-button-disabled, .app.pink .mat-icon-button.mat-accent.mat-button-disabled, .app.pink .mat-icon-button.mat-warn.mat-button-disabled, .app.pink .mat-icon-button.mat-button-disabled.mat-button-disabled, .app.pink .mat-stroked-button.mat-primary.mat-button-disabled, .app.pink .mat-stroked-button.mat-accent.mat-button-disabled, .app.pink .mat-stroked-button.mat-warn.mat-button-disabled, .app.pink .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.app.pink .mat-button.mat-primary .mat-button-focus-overlay, .app.pink .mat-icon-button.mat-primary .mat-button-focus-overlay, .app.pink .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #c2185b;
}
.app.pink .mat-button.mat-accent .mat-button-focus-overlay, .app.pink .mat-icon-button.mat-accent .mat-button-focus-overlay, .app.pink .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #3949ab;
}
.app.pink .mat-button.mat-warn .mat-button-focus-overlay, .app.pink .mat-icon-button.mat-warn .mat-button-focus-overlay, .app.pink .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #ff3d00;
}
.app.pink .mat-button.mat-button-disabled .mat-button-focus-overlay, .app.pink .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .app.pink .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.app.pink .mat-button .mat-ripple-element, .app.pink .mat-icon-button .mat-ripple-element, .app.pink .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.app.pink .mat-button-focus-overlay {
  background: black;
}
.app.pink .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.pink .mat-flat-button, .app.pink .mat-raised-button, .app.pink .mat-fab, .app.pink .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.app.pink .mat-flat-button.mat-primary, .app.pink .mat-raised-button.mat-primary, .app.pink .mat-fab.mat-primary, .app.pink .mat-mini-fab.mat-primary {
  color: white;
}
.app.pink .mat-flat-button.mat-accent, .app.pink .mat-raised-button.mat-accent, .app.pink .mat-fab.mat-accent, .app.pink .mat-mini-fab.mat-accent {
  color: white;
}
.app.pink .mat-flat-button.mat-warn, .app.pink .mat-raised-button.mat-warn, .app.pink .mat-fab.mat-warn, .app.pink .mat-mini-fab.mat-warn {
  color: white;
}
.app.pink .mat-flat-button.mat-primary.mat-button-disabled, .app.pink .mat-flat-button.mat-accent.mat-button-disabled, .app.pink .mat-flat-button.mat-warn.mat-button-disabled, .app.pink .mat-flat-button.mat-button-disabled.mat-button-disabled, .app.pink .mat-raised-button.mat-primary.mat-button-disabled, .app.pink .mat-raised-button.mat-accent.mat-button-disabled, .app.pink .mat-raised-button.mat-warn.mat-button-disabled, .app.pink .mat-raised-button.mat-button-disabled.mat-button-disabled, .app.pink .mat-fab.mat-primary.mat-button-disabled, .app.pink .mat-fab.mat-accent.mat-button-disabled, .app.pink .mat-fab.mat-warn.mat-button-disabled, .app.pink .mat-fab.mat-button-disabled.mat-button-disabled, .app.pink .mat-mini-fab.mat-primary.mat-button-disabled, .app.pink .mat-mini-fab.mat-accent.mat-button-disabled, .app.pink .mat-mini-fab.mat-warn.mat-button-disabled, .app.pink .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.app.pink .mat-flat-button.mat-primary, .app.pink .mat-raised-button.mat-primary, .app.pink .mat-fab.mat-primary, .app.pink .mat-mini-fab.mat-primary {
  background-color: #c2185b;
}
.app.pink .mat-flat-button.mat-accent, .app.pink .mat-raised-button.mat-accent, .app.pink .mat-fab.mat-accent, .app.pink .mat-mini-fab.mat-accent {
  background-color: #3949ab;
}
.app.pink .mat-flat-button.mat-warn, .app.pink .mat-raised-button.mat-warn, .app.pink .mat-fab.mat-warn, .app.pink .mat-mini-fab.mat-warn {
  background-color: #ff3d00;
}
.app.pink .mat-flat-button.mat-primary.mat-button-disabled, .app.pink .mat-flat-button.mat-accent.mat-button-disabled, .app.pink .mat-flat-button.mat-warn.mat-button-disabled, .app.pink .mat-flat-button.mat-button-disabled.mat-button-disabled, .app.pink .mat-raised-button.mat-primary.mat-button-disabled, .app.pink .mat-raised-button.mat-accent.mat-button-disabled, .app.pink .mat-raised-button.mat-warn.mat-button-disabled, .app.pink .mat-raised-button.mat-button-disabled.mat-button-disabled, .app.pink .mat-fab.mat-primary.mat-button-disabled, .app.pink .mat-fab.mat-accent.mat-button-disabled, .app.pink .mat-fab.mat-warn.mat-button-disabled, .app.pink .mat-fab.mat-button-disabled.mat-button-disabled, .app.pink .mat-mini-fab.mat-primary.mat-button-disabled, .app.pink .mat-mini-fab.mat-accent.mat-button-disabled, .app.pink .mat-mini-fab.mat-warn.mat-button-disabled, .app.pink .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.pink .mat-flat-button.mat-primary .mat-ripple-element, .app.pink .mat-raised-button.mat-primary .mat-ripple-element, .app.pink .mat-fab.mat-primary .mat-ripple-element, .app.pink .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.pink .mat-flat-button.mat-accent .mat-ripple-element, .app.pink .mat-raised-button.mat-accent .mat-ripple-element, .app.pink .mat-fab.mat-accent .mat-ripple-element, .app.pink .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.pink .mat-flat-button.mat-warn .mat-ripple-element, .app.pink .mat-raised-button.mat-warn .mat-ripple-element, .app.pink .mat-fab.mat-warn .mat-ripple-element, .app.pink .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.pink .mat-stroked-button:not([class*=mat-elevation-z]), .app.pink .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-fab:not([class*=mat-elevation-z]), .app.pink .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .app.pink .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .app.pink .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-datepicker-content .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.app.pink .mat-datepicker-content .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #c2185b;
}
.app.pink .mat-datepicker-content .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #3949ab;
}
.app.pink .mat-datepicker-content .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #ff3d00;
}
.app.pink .mat-datepicker-content .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.app.pink .mat-datepicker-content .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.app.pink .mat-datepicker-content .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #c2185b;
  --mdc-filled-button-label-text-color: #fff;
}
.app.pink .mat-datepicker-content .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #3949ab;
  --mdc-filled-button-label-text-color: #fff;
}
.app.pink .mat-datepicker-content .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #ff3d00;
  --mdc-filled-button-label-text-color: #fff;
}
.app.pink .mat-datepicker-content .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.app.pink .mat-datepicker-content .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.app.pink .mat-datepicker-content .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #c2185b;
  --mdc-protected-button-label-text-color: #fff;
}
.app.pink .mat-datepicker-content .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #3949ab;
  --mdc-protected-button-label-text-color: #fff;
}
.app.pink .mat-datepicker-content .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #ff3d00;
  --mdc-protected-button-label-text-color: #fff;
}
.app.pink .mat-datepicker-content .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}
.app.pink .mat-datepicker-content .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.app.pink .mat-datepicker-content .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.app.pink .mat-datepicker-content .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #c2185b;
}
.app.pink .mat-datepicker-content .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #3949ab;
}
.app.pink .mat-datepicker-content .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #ff3d00;
}
.app.pink .mat-datepicker-content .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}
.app.pink .mat-datepicker-content .mat-mdc-button, .app.pink .mat-datepicker-content .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.app.pink .mat-datepicker-content .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .app.pink .mat-datepicker-content .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.app.pink .mat-datepicker-content .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.pink .mat-datepicker-content .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .app.pink .mat-datepicker-content .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.pink .mat-datepicker-content .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.pink .mat-datepicker-content .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .app.pink .mat-datepicker-content .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.pink .mat-datepicker-content .mat-mdc-button.mat-primary, .app.pink .mat-datepicker-content .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #c2185b;
  --mat-mdc-button-ripple-color: rgba(194, 24, 91, 0.1);
}
.app.pink .mat-datepicker-content .mat-mdc-button.mat-accent, .app.pink .mat-datepicker-content .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #3949ab;
  --mat-mdc-button-ripple-color: rgba(57, 73, 171, 0.1);
}
.app.pink .mat-datepicker-content .mat-mdc-button.mat-warn, .app.pink .mat-datepicker-content .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #ff3d00;
  --mat-mdc-button-ripple-color: rgba(255, 61, 0, 0.1);
}
.app.pink .mat-datepicker-content .mat-mdc-raised-button, .app.pink .mat-datepicker-content .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.app.pink .mat-datepicker-content .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .app.pink .mat-datepicker-content .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.app.pink .mat-datepicker-content .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.pink .mat-datepicker-content .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .app.pink .mat-datepicker-content .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.pink .mat-datepicker-content .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.pink .mat-datepicker-content .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .app.pink .mat-datepicker-content .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.pink .mat-datepicker-content .mat-mdc-raised-button.mat-primary, .app.pink .mat-datepicker-content .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.app.pink .mat-datepicker-content .mat-mdc-raised-button.mat-accent, .app.pink .mat-datepicker-content .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.app.pink .mat-datepicker-content .mat-mdc-raised-button.mat-warn, .app.pink .mat-datepicker-content .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.app.pink .mat-datepicker-content .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.app.pink .mat-datepicker-content .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.app.pink .mat-datepicker-content .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.pink .mat-datepicker-content .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.pink .mat-datepicker-content .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.pink .mat-datepicker-content .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #c2185b;
  --mat-mdc-button-ripple-color: rgba(194, 24, 91, 0.1);
}
.app.pink .mat-datepicker-content .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #3949ab;
  --mat-mdc-button-ripple-color: rgba(57, 73, 171, 0.1);
}
.app.pink .mat-datepicker-content .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #ff3d00;
  --mat-mdc-button-ripple-color: rgba(255, 61, 0, 0.1);
}
.app.pink .mat-datepicker-content .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #c2185b;
}
.app.pink .mat-datepicker-content .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #3949ab;
}
.app.pink .mat-datepicker-content .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #ff3d00;
}
.app.pink .mat-datepicker-content .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}
.app.pink .mat-datepicker-content .mat-mdc-button.mat-mdc-button-base,
.app.pink .mat-datepicker-content .mat-mdc-raised-button.mat-mdc-button-base,
.app.pink .mat-datepicker-content .mat-mdc-unelevated-button.mat-mdc-button-base,
.app.pink .mat-datepicker-content .mat-mdc-outlined-button.mat-mdc-button-base {
  height: 36px;
}
.app.pink .mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base {
  width: 48px;
  height: 48px;
  padding: 12px;
}
.app.pink .mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__focus-ring {
  max-height: 48px;
  max-width: 48px;
}
.app.pink .mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 40px;
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 4px;
  margin-left: 4px;
}
.app.pink .mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.app.pink .mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 48px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 48px;
  transform: translate(-50%, -50%);
}
.app.pink .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.app.pink .mat-option:hover:not(.mat-option-disabled), .app.pink .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.app.pink .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.app.pink .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.app.pink .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.app.pink .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #c2185b;
}
.app.pink .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #3949ab;
}
.app.pink .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff3d00;
}
.app.pink .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.pink .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.pink .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.pink .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.app.pink .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.app.pink .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.app.pink .mat-primary .mat-pseudo-checkbox-checked,
.app.pink .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #c2185b;
}
.app.pink .mat-pseudo-checkbox-checked,
.app.pink .mat-pseudo-checkbox-indeterminate,
.app.pink .mat-accent .mat-pseudo-checkbox-checked,
.app.pink .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #3949ab;
}
.app.pink .mat-warn .mat-pseudo-checkbox-checked,
.app.pink .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #ff3d00;
}
.app.pink .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.app.pink .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.app.pink .mat-app-background, .app.pink.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app.pink .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.app.pink .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.pink .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.app.pink .mat-progress-bar-background {
  fill: #ecc2d2;
}
.app.pink .mat-progress-bar-buffer {
  background-color: #ecc2d2;
}
.app.pink .mat-progress-bar-fill::after {
  background-color: #c2185b;
}
.app.pink .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #cacee6;
}
.app.pink .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #cacee6;
}
.app.pink .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #3949ab;
}
.app.pink .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #fbcbbc;
}
.app.pink .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #fbcbbc;
}
.app.pink .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #ff3d00;
}
.app.pink .mat-progress-spinner circle, .app.pink .mat-spinner circle {
  stroke: #c2185b;
}
.app.pink .mat-progress-spinner.mat-accent circle, .app.pink .mat-spinner.mat-accent circle {
  stroke: #3949ab;
}
.app.pink .mat-progress-spinner.mat-warn circle, .app.pink .mat-spinner.mat-warn circle {
  stroke: #ff3d00;
}
.app.pink .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.app.pink .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.app.pink .mat-input-element:disabled,
.app.pink .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.app.pink .mat-input-element {
  caret-color: #c2185b;
}
.app.pink .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.pink .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.pink .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.pink .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.pink .mat-form-field.mat-accent .mat-input-element {
  caret-color: #3949ab;
}
.app.pink .mat-form-field.mat-warn .mat-input-element,
.app.pink .mat-form-field-invalid .mat-input-element {
  caret-color: #ff3d00;
}
.app.pink .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #ff3d00;
}
.app.pink .mat-form-field-disabled .mat-form-field-prefix,
.app.pink .mat-form-field-disabled .mat-form-field-suffix {
  color: rgba(0, 0, 0, 0.38);
}
.app.pink .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.app.pink .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.app.pink .mat-form-field.mat-focused .mat-form-field-label {
  color: #c2185b;
}
.app.pink .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #3949ab;
}
.app.pink .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #ff3d00;
}
.app.pink .mat-focused .mat-form-field-required-marker {
  color: #3949ab;
}
.app.pink .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.app.pink .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #c2185b;
}
.app.pink .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #3949ab;
}
.app.pink .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #ff3d00;
}
.app.pink .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #c2185b;
}
.app.pink .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #3949ab;
}
.app.pink .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #ff3d00;
}
.app.pink .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #ff3d00;
}
.app.pink .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.app.pink .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #ff3d00;
}
.app.pink .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.app.pink .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #ff3d00;
}
.app.pink .mat-error {
  color: #ff3d00;
}
.app.pink .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.pink .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.app.pink .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.app.pink .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app.pink .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.app.pink .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app.pink .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.app.pink .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.app.pink .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.app.pink .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.pink .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.app.pink .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.app.pink .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.app.pink .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #c2185b;
}
.app.pink .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #3949ab;
}
.app.pink .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #ff3d00;
}
.app.pink .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #ff3d00;
}
.app.pink .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.pink .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.app.pink .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.app.pink .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.pink .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.app.pink .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.app.pink .mat-select-panel {
  background: white;
}
.app.pink .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.app.pink .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #c2185b;
}
.app.pink .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #3949ab;
}
.app.pink .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #ff3d00;
}
.app.pink .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #ff3d00;
}
.app.pink .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.app.pink .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.app.pink .mat-checkbox-checkmark {
  fill: #fafafa;
}
.app.pink .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.app.pink .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.app.pink .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .app.pink .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #c2185b;
}
.app.pink .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .app.pink .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #3949ab;
}
.app.pink .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .app.pink .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #ff3d00;
}
.app.pink .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .app.pink .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.app.pink .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.app.pink .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.pink .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.app.pink .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.app.pink .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #c2185b;
}
.app.pink .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.app.pink .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #3949ab;
}
.app.pink .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.app.pink .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #ff3d00;
}
.app.pink .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.pink .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.app.pink .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.app.pink .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.pink .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.app.pink .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.app.pink .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.app.pink .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.app.pink .mat-chip.mat-standard-chip::after {
  background: black;
}
.app.pink .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #c2185b;
  color: white;
}
.app.pink .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.pink .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.pink .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #ff3d00;
  color: white;
}
.app.pink .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.pink .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.pink .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #3949ab;
  color: white;
}
.app.pink .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.pink .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.pink .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #3949ab;
}
.app.pink .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(57, 73, 171, 0.54);
}
.app.pink .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #3949ab;
}
.app.pink .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #c2185b;
}
.app.pink .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(194, 24, 91, 0.54);
}
.app.pink .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #c2185b;
}
.app.pink .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff3d00;
}
.app.pink .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 61, 0, 0.54);
}
.app.pink .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #ff3d00;
}
.app.pink .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.app.pink .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.app.pink .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.pink .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.app.pink .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #c2185b;
}
.app.pink .mat-radio-button.mat-primary .mat-radio-inner-circle,
.app.pink .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.pink .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .app.pink .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #c2185b;
}
.app.pink .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #3949ab;
}
.app.pink .mat-radio-button.mat-accent .mat-radio-inner-circle,
.app.pink .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.pink .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .app.pink .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #3949ab;
}
.app.pink .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff3d00;
}
.app.pink .mat-radio-button.mat-warn .mat-radio-inner-circle,
.app.pink .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.pink .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .app.pink .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #ff3d00;
}
.app.pink .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.app.pink .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.pink .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.app.pink .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.pink .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.app.pink .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.app.pink .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.pink .mat-slider.mat-primary .mat-slider-track-fill,
.app.pink .mat-slider.mat-primary .mat-slider-thumb,
.app.pink .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #c2185b;
}
.app.pink .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.app.pink .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(194, 24, 91, 0.2);
}
.app.pink .mat-slider.mat-accent .mat-slider-track-fill,
.app.pink .mat-slider.mat-accent .mat-slider-thumb,
.app.pink .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #3949ab;
}
.app.pink .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.app.pink .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(57, 73, 171, 0.2);
}
.app.pink .mat-slider.mat-warn .mat-slider-track-fill,
.app.pink .mat-slider.mat-warn .mat-slider-thumb,
.app.pink .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #ff3d00;
}
.app.pink .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.app.pink .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(255, 61, 0, 0.2);
}
.app.pink .mat-slider:hover .mat-slider-track-background,
.app.pink .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.pink .mat-slider.mat-slider-disabled .mat-slider-track-background,
.app.pink .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.app.pink .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.pink .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.pink .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.pink .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.app.pink .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.app.pink .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.app.pink .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.pink .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.app.pink .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .app.pink .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.pink .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .app.pink .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.app.pink .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.app.pink .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.app.pink .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.app.pink .mat-menu-panel {
  background: white;
}
.app.pink .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.app.pink .mat-menu-item[disabled],
.app.pink .mat-menu-item[disabled] .mat-menu-submenu-icon,
.app.pink .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.app.pink .mat-menu-item .mat-icon-no-color,
.app.pink .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.app.pink .mat-menu-item:hover:not([disabled]),
.app.pink .mat-menu-item.cdk-program-focused:not([disabled]),
.app.pink .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.app.pink .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.app.pink .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.app.pink .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.app.pink .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.app.pink .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.app.pink .mat-list-option:hover, .app.pink .mat-list-option:focus,
.app.pink .mat-nav-list .mat-list-item:hover,
.app.pink .mat-nav-list .mat-list-item:focus,
.app.pink .mat-action-list .mat-list-item:hover,
.app.pink .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.app.pink .mat-list-single-selected-option, .app.pink .mat-list-single-selected-option:hover, .app.pink .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.app.pink .mat-paginator {
  background: white;
}
.app.pink .mat-paginator,
.app.pink .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.app.pink .mat-paginator-decrement,
.app.pink .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.app.pink .mat-paginator-first,
.app.pink .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.app.pink .mat-icon-button[disabled] .mat-paginator-decrement,
.app.pink .mat-icon-button[disabled] .mat-paginator-increment,
.app.pink .mat-icon-button[disabled] .mat-paginator-first,
.app.pink .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.pink .mat-paginator-container {
  min-height: 56px;
}
.app.pink .mat-tab-nav-bar,
.app.pink .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.app.pink .mat-tab-group-inverted-header .mat-tab-nav-bar,
.app.pink .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.app.pink .mat-tab-label, .app.pink .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.app.pink .mat-tab-label.mat-tab-disabled, .app.pink .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.app.pink .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.app.pink .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.pink .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.app.pink .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.app.pink .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused, .app.pink .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused,
.app.pink .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused,
.app.pink .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused, .app.pink .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused, .app.pink .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused,
.app.pink .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused,
.app.pink .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused {
  background-color: rgba(252, 228, 236, 0.3);
}
.app.pink .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.pink .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.pink .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.pink .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled, .app.pink .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.pink .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.pink .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.pink .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.pink .mat-tab-group.mat-primary .mat-ink-bar, .app.pink .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #c2185b;
}
.app.pink .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .app.pink .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .app.pink .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .app.pink .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.app.pink .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused, .app.pink .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused,
.app.pink .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused,
.app.pink .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused, .app.pink .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused, .app.pink .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused,
.app.pink .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused,
.app.pink .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused {
  background-color: rgba(197, 202, 233, 0.3);
}
.app.pink .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.pink .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.pink .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.pink .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled, .app.pink .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.pink .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.pink .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.pink .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.pink .mat-tab-group.mat-accent .mat-ink-bar, .app.pink .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #3949ab;
}
.app.pink .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .app.pink .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .app.pink .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .app.pink .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.app.pink .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused, .app.pink .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused,
.app.pink .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused,
.app.pink .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused, .app.pink .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused, .app.pink .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused,
.app.pink .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused,
.app.pink .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused {
  background-color: rgba(255, 204, 188, 0.3);
}
.app.pink .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.pink .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.pink .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.pink .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled, .app.pink .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.pink .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.pink .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.pink .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.pink .mat-tab-group.mat-warn .mat-ink-bar, .app.pink .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #ff3d00;
}
.app.pink .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .app.pink .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .app.pink .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .app.pink .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.app.pink .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused, .app.pink .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused,
.app.pink .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused,
.app.pink .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused, .app.pink .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused, .app.pink .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused,
.app.pink .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused,
.app.pink .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused {
  background-color: rgba(252, 228, 236, 0.3);
}
.app.pink .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.pink .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.pink .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.pink .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled, .app.pink .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.pink .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.pink .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.pink .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.pink .mat-tab-group.mat-background-primary > .mat-tab-header, .app.pink .mat-tab-group.mat-background-primary > .mat-tab-link-container, .app.pink .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .app.pink .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .app.pink .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .app.pink .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #c2185b;
}
.app.pink .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .app.pink .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .app.pink .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .app.pink .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.app.pink .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.pink .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .app.pink .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.pink .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.pink .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.pink .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.pink .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.app.pink .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .app.pink .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.pink .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.pink .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.app.pink .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.app.pink .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.pink .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.pink .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.pink .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.app.pink .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.app.pink .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.app.pink .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .app.pink .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.app.pink .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.app.pink .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.app.pink .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused, .app.pink .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused,
.app.pink .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused,
.app.pink .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused, .app.pink .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused, .app.pink .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused,
.app.pink .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused,
.app.pink .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused {
  background-color: rgba(197, 202, 233, 0.3);
}
.app.pink .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.pink .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.pink .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.pink .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled, .app.pink .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.pink .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.pink .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.pink .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.pink .mat-tab-group.mat-background-accent > .mat-tab-header, .app.pink .mat-tab-group.mat-background-accent > .mat-tab-link-container, .app.pink .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .app.pink .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .app.pink .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .app.pink .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #3949ab;
}
.app.pink .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .app.pink .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .app.pink .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .app.pink .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.app.pink .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.pink .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .app.pink .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.pink .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.pink .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.pink .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.pink .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.app.pink .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .app.pink .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.pink .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.pink .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.app.pink .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.app.pink .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.pink .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.pink .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.pink .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.app.pink .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.app.pink .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.app.pink .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .app.pink .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.app.pink .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.app.pink .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.app.pink .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused, .app.pink .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused,
.app.pink .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused,
.app.pink .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused, .app.pink .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused, .app.pink .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused,
.app.pink .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused,
.app.pink .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused {
  background-color: rgba(255, 204, 188, 0.3);
}
.app.pink .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.pink .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.pink .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.pink .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled, .app.pink .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.pink .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.pink .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.pink .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.pink .mat-tab-group.mat-background-warn > .mat-tab-header, .app.pink .mat-tab-group.mat-background-warn > .mat-tab-link-container, .app.pink .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .app.pink .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .app.pink .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .app.pink .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #ff3d00;
}
.app.pink .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .app.pink .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .app.pink .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .app.pink .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.app.pink .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.pink .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .app.pink .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.pink .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.pink .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.pink .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.pink .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.app.pink .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .app.pink .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.pink .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.pink .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.app.pink .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.app.pink .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.pink .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.pink .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.pink .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.app.pink .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.app.pink .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.app.pink .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .app.pink .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.app.pink .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.app.pink .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.app.pink .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-simple-snackbar-action {
  color: #3949ab;
}
.app.pink .mat-table {
  background: white;
}
.app.pink .mat-table thead, .app.pink .mat-table tbody, .app.pink .mat-table tfoot,
.app.pink mat-header-row, .app.pink mat-row, .app.pink mat-footer-row,
.app.pink [mat-header-row], .app.pink [mat-row], .app.pink [mat-footer-row],
.app.pink .mat-table-sticky {
  background: inherit;
}
.app.pink mat-row, .app.pink mat-header-row, .app.pink mat-footer-row,
.app.pink th.mat-header-cell, .app.pink td.mat-cell, .app.pink td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.app.pink .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.app.pink .mat-cell, .app.pink .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.app.pink .mat-badge {
  position: relative;
}
.app.pink .mat-badge.mat-badge {
  overflow: visible;
}
.app.pink .mat-badge-hidden .mat-badge-content {
  display: none;
}
.app.pink .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.app.pink .ng-animate-disabled .mat-badge-content,
.app.pink .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.app.pink .mat-badge-content.mat-badge-active {
  transform: none;
}
.app.pink .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.app.pink .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.app.pink .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.app.pink .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .app.pink .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.app.pink .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .app.pink .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.app.pink .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .app.pink .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.app.pink .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .app.pink .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.app.pink .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.app.pink .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.app.pink .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.app.pink .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .app.pink .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.app.pink .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .app.pink .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.app.pink .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .app.pink .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.app.pink .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .app.pink .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.app.pink .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.app.pink .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.app.pink .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.app.pink .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .app.pink .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.app.pink .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .app.pink .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.app.pink .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .app.pink .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.app.pink .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .app.pink .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.app.pink .mat-badge-content {
  color: white;
  background: #c2185b;
}
.cdk-high-contrast-active .app.pink .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.app.pink .mat-badge-accent .mat-badge-content {
  background: #3949ab;
  color: white;
}
.app.pink .mat-badge-warn .mat-badge-content {
  color: white;
  background: #ff3d00;
}
.app.pink .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.app.pink .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.pink .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.app.pink .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.app.pink .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.app.pink .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.app.pink .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.pink .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.app.pink .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.app.pink .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.app.pink [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.app.pink .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.app.pink .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.app.pink .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.app.pink .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.app.pink .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.app.pink .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.app.pink .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.app.pink .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.app.pink .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}
.app.pink .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.app.pink .mat-datepicker-toggle,
.app.pink .mat-datepicker-content .mat-calendar-next-button,
.app.pink .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.app.pink .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.app.pink .mat-calendar-table-header,
.app.pink .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.pink .mat-calendar-body-cell-content,
.app.pink .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.app.pink .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.app.pink .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.app.pink .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.app.pink .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.pink .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.app.pink .mat-calendar-body-in-range::before {
  background: rgba(194, 24, 91, 0.2);
}
.app.pink .mat-calendar-body-comparison-identical,
.app.pink .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.pink .mat-calendar-body-comparison-bridge-start::before,
.app.pink [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(194, 24, 91, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.pink .mat-calendar-body-comparison-bridge-end::before,
.app.pink [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(194, 24, 91, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.pink .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.pink .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.pink .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.pink .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.pink .mat-calendar-body-selected {
  background-color: #c2185b;
  color: white;
}
.app.pink .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(194, 24, 91, 0.4);
}
.app.pink .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.pink .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.pink .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(194, 24, 91, 0.3);
}
@media (hover: hover) {
  .app.pink .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(194, 24, 91, 0.3);
  }
}
.app.pink .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.pink .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(57, 73, 171, 0.2);
}
.app.pink .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.app.pink .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.pink .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.app.pink .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(57, 73, 171, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.pink .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.app.pink .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(57, 73, 171, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.pink .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.pink .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.pink .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.pink .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.pink .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #3949ab;
  color: white;
}
.app.pink .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(57, 73, 171, 0.4);
}
.app.pink .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.pink .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.pink .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(57, 73, 171, 0.3);
}
@media (hover: hover) {
  .app.pink .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(57, 73, 171, 0.3);
  }
}
.app.pink .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(255, 61, 0, 0.2);
}
.app.pink .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.app.pink .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.pink .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.app.pink .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 61, 0, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.pink .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.app.pink .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 61, 0, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.pink .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.pink .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.pink .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.pink .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.pink .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #ff3d00;
  color: white;
}
.app.pink .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 61, 0, 0.4);
}
.app.pink .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.pink .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.pink .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 61, 0, 0.3);
}
@media (hover: hover) {
  .app.pink .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 61, 0, 0.3);
  }
}
.app.pink .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-datepicker-toggle-active {
  color: #c2185b;
}
.app.pink .mat-datepicker-toggle-active.mat-accent {
  color: #3949ab;
}
.app.pink .mat-datepicker-toggle-active.mat-warn {
  color: #ff3d00;
}
.app.pink .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.app.pink .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  width: 40px;
  height: 40px;
  padding: 8px;
}
.app.pink .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.app.pink .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 40px;
  height: 40px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  margin-left: 0px;
}
.app.pink .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.app.pink .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 40px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 40px;
  transform: translate(-50%, -50%);
}
.app.pink .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}
.app.pink .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.pink .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.app.pink .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.pink .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.pink .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .app.pink .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .app.pink .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .app.pink .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.app.pink .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.app.pink .mat-expansion-panel-header-description,
.app.pink .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.app.pink .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.app.pink .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.app.pink .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.app.pink .mat-expansion-panel-header {
  height: 48px;
}
.app.pink .mat-expansion-panel-header.mat-expanded {
  height: 64px;
}
.app.pink .mat-icon.mat-primary {
  color: #c2185b;
}
.app.pink .mat-icon.mat-accent {
  color: #3949ab;
}
.app.pink .mat-icon.mat-warn {
  color: #ff3d00;
}
.app.pink .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app.pink .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.pink .mat-drawer.mat-drawer-push {
  background-color: white;
}
.app.pink .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.app.pink [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.app.pink [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app.pink .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.app.pink .mat-step-header.cdk-keyboard-focused, .app.pink .mat-step-header.cdk-program-focused, .app.pink .mat-step-header:hover:not([aria-disabled]), .app.pink .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.app.pink .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .app.pink .mat-step-header:hover {
    background: none;
  }
}
.app.pink .mat-step-header .mat-step-label,
.app.pink .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.app.pink .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.app.pink .mat-step-header .mat-step-icon-selected,
.app.pink .mat-step-header .mat-step-icon-state-done,
.app.pink .mat-step-header .mat-step-icon-state-edit {
  background-color: #c2185b;
  color: white;
}
.app.pink .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.app.pink .mat-step-header.mat-accent .mat-step-icon-selected,
.app.pink .mat-step-header.mat-accent .mat-step-icon-state-done,
.app.pink .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #3949ab;
  color: white;
}
.app.pink .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.app.pink .mat-step-header.mat-warn .mat-step-icon-selected,
.app.pink .mat-step-header.mat-warn .mat-step-icon-state-done,
.app.pink .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #ff3d00;
  color: white;
}
.app.pink .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #ff3d00;
}
.app.pink .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.app.pink .mat-step-header .mat-step-label.mat-step-label-error {
  color: #ff3d00;
}
.app.pink .mat-stepper-horizontal, .app.pink .mat-stepper-vertical {
  background-color: white;
}
.app.pink .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.app.pink .mat-horizontal-stepper-header::before,
.app.pink .mat-horizontal-stepper-header::after,
.app.pink .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.pink .mat-horizontal-stepper-header {
  height: 72px;
}
.app.pink .mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.app.pink .mat-vertical-stepper-header {
  padding: 24px 24px;
}
.app.pink .mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}
.app.pink .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .app.pink .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}
.app.pink .mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}
.app.pink .mat-sort-header-arrow {
  color: #757575;
}
.app.pink .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.app.pink .mat-toolbar.mat-primary {
  background: #c2185b;
  color: white;
}
.app.pink .mat-toolbar.mat-accent {
  background: #3949ab;
  color: white;
}
.app.pink .mat-toolbar.mat-warn {
  background: #ff3d00;
  color: white;
}
.app.pink .mat-toolbar .mat-form-field-underline,
.app.pink .mat-toolbar .mat-form-field-ripple,
.app.pink .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.app.pink .mat-toolbar .mat-form-field-label,
.app.pink .mat-toolbar .mat-focused .mat-form-field-label,
.app.pink .mat-toolbar .mat-select-value,
.app.pink .mat-toolbar .mat-select-arrow,
.app.pink .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.app.pink .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.app.pink .mat-toolbar-multiple-rows {
  min-height: 44px;
}
.app.pink .mat-toolbar-row, .app.pink .mat-toolbar-single-row {
  height: 44px;
}
@media (max-width: 599px) {
  .app.pink .mat-toolbar-multiple-rows {
    min-height: 56px;
  }
  .app.pink .mat-toolbar-row, .app.pink .mat-toolbar-single-row {
    height: 56px;
  }
}
.app.pink .mat-tree {
  background: white;
}
.app.pink .mat-tree-node,
.app.pink .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.app.pink .mat-tree-node {
  min-height: 48px;
}
.app.pink .bg-primary {
  background-color: var(--primary);
  color: white;
}
.app.pink .bg-accent {
  background-color: var(--accent);
  color: white;
}
.app.pink .bg-warn {
  background-color: var(--warn);
  color: white;
}
.app.pink .text-muted {
  color: rgba(0, 0, 0, 0.54) !important;
}
.app.pink .primary-color {
  color: #c2185b;
}
.app.pink .secondary-color {
  color: #f8bbd0;
}
.app.pink .v-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.pink .divider {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.app.pink .top-toolbar.mat-toolbar .v-divider {
  background-color: rgba(252, 228, 236, 0.2);
}
.app.pink .border-bottom-lighter {
  border-bottom: 1px solid rgba(252, 228, 236, 0.2);
}
.app.pink .border-lighter {
  border-color: rgba(252, 228, 236, 0.2);
}
.app.pink .border-accent {
  border-color: #3949ab;
}
.app.pink .border-primary {
  border-color: #c2185b !important;
}
.app.pink #top-toolbar {
  background-color: #e91e63;
  color: white;
}
.app.pink .toolbar-2 #top-toolbar {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.pink #main-toolbar {
  background: white;
}
.app.pink .toolbar-2 #main-toolbar {
  color: var(--primary);
}
.app.pink header .logo svg {
  fill: #c2185b;
}
.app.pink header.toolbar-1.has-bg-image:not(.main-toolbar-fixed) .logo svg, .app.pink header.toolbar-1.has-bg-video:not(.main-toolbar-fixed) .logo svg {
  fill: #fff;
}
.app.pink header.toolbar-1.has-bg-image:not(.main-toolbar-fixed) .horizontal-menu .mat-button, .app.pink header.toolbar-1.has-bg-video:not(.main-toolbar-fixed) .horizontal-menu .mat-button {
  color: #fff;
}
.app.pink header.toolbar-1.has-bg-image:not(.main-toolbar-fixed) .horizontal-menu .mat-button.horizontal-active-link, .app.pink header.toolbar-1.has-bg-video:not(.main-toolbar-fixed) .horizontal-menu .mat-button.horizontal-active-link {
  background: rgba(194, 24, 91, 0.38);
}
.app.pink header.toolbar-1.has-bg-image:not(.main-toolbar-fixed) .mat-icon, .app.pink header.toolbar-1.has-bg-video:not(.main-toolbar-fixed) .mat-icon {
  color: #fff;
}
.app.pink header.toolbar-2 .horizontal-menu .mat-button {
  color: #fff;
}
.app.pink header.toolbar-2 .horizontal-menu .mat-button.horizontal-active-link {
  color: #fff;
}
.app.pink header.toolbar-2.has-bg-image:not(.main-toolbar-fixed) .horizontal-menu .mat-button.horizontal-active-link, .app.pink header.toolbar-2.has-bg-video:not(.main-toolbar-fixed) .horizontal-menu .mat-button.horizontal-active-link {
  background: rgba(194, 24, 91, 0.38);
}
.app.pink .header-carousel .slide-info .mat-card {
  background: rgba(255, 255, 255, 0.85);
}
.app.pink .header-carousel .slide-info .mat-card:hover {
  background: white;
}
.app.pink .user-menu .user-info {
  color: rgba(0, 0, 0, 0.87);
}
.app.pink main.content-offset-to-top .main-content-header {
  border-top: 5px solid #c2185b;
}
.app.pink .map-info-window .si-content-wrapper {
  padding: 2px;
  color: var(--primary);
}
.app.pink .map-info-window .si-content-wrapper .si-content {
  max-width: 100% !important;
  max-height: 100% !important;
}
.app.pink .map-info-window .si-content-wrapper .si-content a {
  color: inherit;
  text-decoration: none;
}
.app.pink .map-info-window .si-pointer-bg-top {
  border-top-color: #c2185b;
}
.app.pink .map-info-window .si-pointer-border-top {
  border-top-color: #c2185b;
}
.app.pink .map-info-window .si-close-button {
  opacity: 1;
  right: 4px;
  top: 4px;
  padding: 0 6px;
  border-radius: 50%;
  color: var(--primary);
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.pink .map-info-window.si-has-border .si-content-wrapper {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.pink .property-item .title a,
.app.pink .compare-item .title a {
  color: rgba(0, 0, 0, 0.87);
}
.app.pink .property-item .title a:hover,
.app.pink .compare-item .title a:hover {
  color: #c2185b;
}
.app.pink .features p:after,
.app.pink .compare-item .details .item {
  border-bottom: 1px dotted rgba(0, 0, 0, 0.12);
}
.app.pink .small-carousel .swiper-slide.active-thumb img {
  border-color: #c2185b;
}
.app.pink .ps__thumb-y {
  background-color: #880e4f;
}
.app.pink .active-link {
  background-color: #c2185b;
  color: #fff;
}
.app.pink .horizontal-active-link {
  background: rgba(0, 0, 0, 0.04);
  color: #c2185b;
}
.app.pink .horizontal-sub-menu .mat-button, .app.pink .horizontal-sub-menu .mat-menu-item {
  color: rgba(0, 0, 0, 0.87);
}
.app.pink .horizontal-sub-menu .mat-button.horizontal-active-link, .app.pink .horizontal-sub-menu .mat-menu-item.horizontal-active-link {
  color: #c2185b;
}
.app.pink .section .section-title:after,
.app.pink .section.testimonials .swiper-pagination-bullet-active {
  background-color: #c2185b;
}
.app.pink .custom-table.mat-table .mat-header-row,
.app.pink .custom-table.mat-table .mat-row {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.pink .submit-property .step-section,
.app.pink .edit-property .step-section {
  background: rgba(250, 250, 250, 0.7);
}
.app.pink .input-file-container .mat-orphan-label {
  color: rgba(0, 0, 0, 0.87) !important;
}
.app.pink .nav-pills .nav-link.active, .app.pink .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #c2185b;
}
.app.pink .btn-danger {
  color: #fff;
  background-color: #c2185b;
  border-color: #c2185b;
}
.app.pink .btn-danger:not(:disabled):not(.disabled):active, .app.pink .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #c2185b;
  border-color: #c2185b;
}
.app.pink .btn-danger:hover {
  color: #fff;
  background-color: #c2185b;
  border-color: #c2185b;
}
.app.pink .btn-outline-danger {
  color: #c2185b;
  border-color: #c2185b;
}
.app.pink .btn-outline-danger:not(:disabled):not(.disabled):active, .app.pink .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #c2185b;
  border-color: #c2185b;
}
.app.pink .btn-outline-danger:hover {
  color: #fff;
  background-color: #c2185b;
  border-color: #c2185b;
}
.app.pink .pr-clr {
  color: #c2185b;
}
.app.pink .bg-primary {
  background-color: #c2185b !important;
}
.app.pink a {
  color: #c2185b;
  text-decoration: none;
  background-color: transparent;
}
.app.pink .containers input:checked ~ .checkmark {
  background-color: #c2185b;
}
.app.pink .check input:checked ~ .checkmarks {
  background-color: #c2185b;
}
.app.pink .btn-outline-danger:not(:disabled):not(.disabled).active, .app.pink .btn-outline-danger:not(:disabled):not(.disabled):active, .app.pink .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #c2185b;
  border-color: #c2185b;
}
.app.purple {
  /* When the radio button is checked, add a blue background */
  /* When the checkbox is checked, add a blue background */
}
.app.purple .mat-button, .app.purple .mat-icon-button, .app.purple .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.app.purple .mat-button.mat-primary, .app.purple .mat-icon-button.mat-primary, .app.purple .mat-stroked-button.mat-primary {
  color: #7b1fa2;
}
.app.purple .mat-button.mat-accent, .app.purple .mat-icon-button.mat-accent, .app.purple .mat-stroked-button.mat-accent {
  color: #43a047;
}
.app.purple .mat-button.mat-warn, .app.purple .mat-icon-button.mat-warn, .app.purple .mat-stroked-button.mat-warn {
  color: #ff5252;
}
.app.purple .mat-button.mat-primary.mat-button-disabled, .app.purple .mat-button.mat-accent.mat-button-disabled, .app.purple .mat-button.mat-warn.mat-button-disabled, .app.purple .mat-button.mat-button-disabled.mat-button-disabled, .app.purple .mat-icon-button.mat-primary.mat-button-disabled, .app.purple .mat-icon-button.mat-accent.mat-button-disabled, .app.purple .mat-icon-button.mat-warn.mat-button-disabled, .app.purple .mat-icon-button.mat-button-disabled.mat-button-disabled, .app.purple .mat-stroked-button.mat-primary.mat-button-disabled, .app.purple .mat-stroked-button.mat-accent.mat-button-disabled, .app.purple .mat-stroked-button.mat-warn.mat-button-disabled, .app.purple .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.app.purple .mat-button.mat-primary .mat-button-focus-overlay, .app.purple .mat-icon-button.mat-primary .mat-button-focus-overlay, .app.purple .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #7b1fa2;
}
.app.purple .mat-button.mat-accent .mat-button-focus-overlay, .app.purple .mat-icon-button.mat-accent .mat-button-focus-overlay, .app.purple .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #43a047;
}
.app.purple .mat-button.mat-warn .mat-button-focus-overlay, .app.purple .mat-icon-button.mat-warn .mat-button-focus-overlay, .app.purple .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #ff5252;
}
.app.purple .mat-button.mat-button-disabled .mat-button-focus-overlay, .app.purple .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .app.purple .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.app.purple .mat-button .mat-ripple-element, .app.purple .mat-icon-button .mat-ripple-element, .app.purple .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.app.purple .mat-button-focus-overlay {
  background: black;
}
.app.purple .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.purple .mat-flat-button, .app.purple .mat-raised-button, .app.purple .mat-fab, .app.purple .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.app.purple .mat-flat-button.mat-primary, .app.purple .mat-raised-button.mat-primary, .app.purple .mat-fab.mat-primary, .app.purple .mat-mini-fab.mat-primary {
  color: white;
}
.app.purple .mat-flat-button.mat-accent, .app.purple .mat-raised-button.mat-accent, .app.purple .mat-fab.mat-accent, .app.purple .mat-mini-fab.mat-accent {
  color: white;
}
.app.purple .mat-flat-button.mat-warn, .app.purple .mat-raised-button.mat-warn, .app.purple .mat-fab.mat-warn, .app.purple .mat-mini-fab.mat-warn {
  color: white;
}
.app.purple .mat-flat-button.mat-primary.mat-button-disabled, .app.purple .mat-flat-button.mat-accent.mat-button-disabled, .app.purple .mat-flat-button.mat-warn.mat-button-disabled, .app.purple .mat-flat-button.mat-button-disabled.mat-button-disabled, .app.purple .mat-raised-button.mat-primary.mat-button-disabled, .app.purple .mat-raised-button.mat-accent.mat-button-disabled, .app.purple .mat-raised-button.mat-warn.mat-button-disabled, .app.purple .mat-raised-button.mat-button-disabled.mat-button-disabled, .app.purple .mat-fab.mat-primary.mat-button-disabled, .app.purple .mat-fab.mat-accent.mat-button-disabled, .app.purple .mat-fab.mat-warn.mat-button-disabled, .app.purple .mat-fab.mat-button-disabled.mat-button-disabled, .app.purple .mat-mini-fab.mat-primary.mat-button-disabled, .app.purple .mat-mini-fab.mat-accent.mat-button-disabled, .app.purple .mat-mini-fab.mat-warn.mat-button-disabled, .app.purple .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.app.purple .mat-flat-button.mat-primary, .app.purple .mat-raised-button.mat-primary, .app.purple .mat-fab.mat-primary, .app.purple .mat-mini-fab.mat-primary {
  background-color: #7b1fa2;
}
.app.purple .mat-flat-button.mat-accent, .app.purple .mat-raised-button.mat-accent, .app.purple .mat-fab.mat-accent, .app.purple .mat-mini-fab.mat-accent {
  background-color: #43a047;
}
.app.purple .mat-flat-button.mat-warn, .app.purple .mat-raised-button.mat-warn, .app.purple .mat-fab.mat-warn, .app.purple .mat-mini-fab.mat-warn {
  background-color: #ff5252;
}
.app.purple .mat-flat-button.mat-primary.mat-button-disabled, .app.purple .mat-flat-button.mat-accent.mat-button-disabled, .app.purple .mat-flat-button.mat-warn.mat-button-disabled, .app.purple .mat-flat-button.mat-button-disabled.mat-button-disabled, .app.purple .mat-raised-button.mat-primary.mat-button-disabled, .app.purple .mat-raised-button.mat-accent.mat-button-disabled, .app.purple .mat-raised-button.mat-warn.mat-button-disabled, .app.purple .mat-raised-button.mat-button-disabled.mat-button-disabled, .app.purple .mat-fab.mat-primary.mat-button-disabled, .app.purple .mat-fab.mat-accent.mat-button-disabled, .app.purple .mat-fab.mat-warn.mat-button-disabled, .app.purple .mat-fab.mat-button-disabled.mat-button-disabled, .app.purple .mat-mini-fab.mat-primary.mat-button-disabled, .app.purple .mat-mini-fab.mat-accent.mat-button-disabled, .app.purple .mat-mini-fab.mat-warn.mat-button-disabled, .app.purple .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.purple .mat-flat-button.mat-primary .mat-ripple-element, .app.purple .mat-raised-button.mat-primary .mat-ripple-element, .app.purple .mat-fab.mat-primary .mat-ripple-element, .app.purple .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.purple .mat-flat-button.mat-accent .mat-ripple-element, .app.purple .mat-raised-button.mat-accent .mat-ripple-element, .app.purple .mat-fab.mat-accent .mat-ripple-element, .app.purple .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.purple .mat-flat-button.mat-warn .mat-ripple-element, .app.purple .mat-raised-button.mat-warn .mat-ripple-element, .app.purple .mat-fab.mat-warn .mat-ripple-element, .app.purple .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.purple .mat-stroked-button:not([class*=mat-elevation-z]), .app.purple .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-fab:not([class*=mat-elevation-z]), .app.purple .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .app.purple .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .app.purple .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-datepicker-content .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.app.purple .mat-datepicker-content .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #7b1fa2;
}
.app.purple .mat-datepicker-content .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #43a047;
}
.app.purple .mat-datepicker-content .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #ff5252;
}
.app.purple .mat-datepicker-content .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.app.purple .mat-datepicker-content .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.app.purple .mat-datepicker-content .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #7b1fa2;
  --mdc-filled-button-label-text-color: #fff;
}
.app.purple .mat-datepicker-content .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #43a047;
  --mdc-filled-button-label-text-color: #fff;
}
.app.purple .mat-datepicker-content .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #ff5252;
  --mdc-filled-button-label-text-color: #fff;
}
.app.purple .mat-datepicker-content .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.app.purple .mat-datepicker-content .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.app.purple .mat-datepicker-content .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #7b1fa2;
  --mdc-protected-button-label-text-color: #fff;
}
.app.purple .mat-datepicker-content .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #43a047;
  --mdc-protected-button-label-text-color: #fff;
}
.app.purple .mat-datepicker-content .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #ff5252;
  --mdc-protected-button-label-text-color: #fff;
}
.app.purple .mat-datepicker-content .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}
.app.purple .mat-datepicker-content .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.app.purple .mat-datepicker-content .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.app.purple .mat-datepicker-content .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #7b1fa2;
}
.app.purple .mat-datepicker-content .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #43a047;
}
.app.purple .mat-datepicker-content .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #ff5252;
}
.app.purple .mat-datepicker-content .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}
.app.purple .mat-datepicker-content .mat-mdc-button, .app.purple .mat-datepicker-content .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.app.purple .mat-datepicker-content .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .app.purple .mat-datepicker-content .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.app.purple .mat-datepicker-content .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.purple .mat-datepicker-content .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .app.purple .mat-datepicker-content .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.purple .mat-datepicker-content .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.purple .mat-datepicker-content .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .app.purple .mat-datepicker-content .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.purple .mat-datepicker-content .mat-mdc-button.mat-primary, .app.purple .mat-datepicker-content .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #7b1fa2;
  --mat-mdc-button-ripple-color: rgba(123, 31, 162, 0.1);
}
.app.purple .mat-datepicker-content .mat-mdc-button.mat-accent, .app.purple .mat-datepicker-content .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #43a047;
  --mat-mdc-button-ripple-color: rgba(67, 160, 71, 0.1);
}
.app.purple .mat-datepicker-content .mat-mdc-button.mat-warn, .app.purple .mat-datepicker-content .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #ff5252;
  --mat-mdc-button-ripple-color: rgba(255, 82, 82, 0.1);
}
.app.purple .mat-datepicker-content .mat-mdc-raised-button, .app.purple .mat-datepicker-content .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.app.purple .mat-datepicker-content .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .app.purple .mat-datepicker-content .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.app.purple .mat-datepicker-content .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.purple .mat-datepicker-content .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .app.purple .mat-datepicker-content .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.purple .mat-datepicker-content .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.purple .mat-datepicker-content .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .app.purple .mat-datepicker-content .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.purple .mat-datepicker-content .mat-mdc-raised-button.mat-primary, .app.purple .mat-datepicker-content .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.app.purple .mat-datepicker-content .mat-mdc-raised-button.mat-accent, .app.purple .mat-datepicker-content .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.app.purple .mat-datepicker-content .mat-mdc-raised-button.mat-warn, .app.purple .mat-datepicker-content .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.app.purple .mat-datepicker-content .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.app.purple .mat-datepicker-content .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.app.purple .mat-datepicker-content .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.purple .mat-datepicker-content .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.purple .mat-datepicker-content .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.purple .mat-datepicker-content .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #7b1fa2;
  --mat-mdc-button-ripple-color: rgba(123, 31, 162, 0.1);
}
.app.purple .mat-datepicker-content .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #43a047;
  --mat-mdc-button-ripple-color: rgba(67, 160, 71, 0.1);
}
.app.purple .mat-datepicker-content .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #ff5252;
  --mat-mdc-button-ripple-color: rgba(255, 82, 82, 0.1);
}
.app.purple .mat-datepicker-content .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #7b1fa2;
}
.app.purple .mat-datepicker-content .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #43a047;
}
.app.purple .mat-datepicker-content .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #ff5252;
}
.app.purple .mat-datepicker-content .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}
.app.purple .mat-datepicker-content .mat-mdc-button.mat-mdc-button-base,
.app.purple .mat-datepicker-content .mat-mdc-raised-button.mat-mdc-button-base,
.app.purple .mat-datepicker-content .mat-mdc-unelevated-button.mat-mdc-button-base,
.app.purple .mat-datepicker-content .mat-mdc-outlined-button.mat-mdc-button-base {
  height: 36px;
}
.app.purple .mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base {
  width: 48px;
  height: 48px;
  padding: 12px;
}
.app.purple .mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__focus-ring {
  max-height: 48px;
  max-width: 48px;
}
.app.purple .mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 40px;
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 4px;
  margin-left: 4px;
}
.app.purple .mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.app.purple .mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 48px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 48px;
  transform: translate(-50%, -50%);
}
.app.purple .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.app.purple .mat-option:hover:not(.mat-option-disabled), .app.purple .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.app.purple .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.app.purple .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.app.purple .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.app.purple .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #7b1fa2;
}
.app.purple .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #43a047;
}
.app.purple .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff5252;
}
.app.purple .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.purple .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.purple .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.purple .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.app.purple .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.app.purple .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.app.purple .mat-primary .mat-pseudo-checkbox-checked,
.app.purple .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #7b1fa2;
}
.app.purple .mat-pseudo-checkbox-checked,
.app.purple .mat-pseudo-checkbox-indeterminate,
.app.purple .mat-accent .mat-pseudo-checkbox-checked,
.app.purple .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #43a047;
}
.app.purple .mat-warn .mat-pseudo-checkbox-checked,
.app.purple .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #ff5252;
}
.app.purple .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.app.purple .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.app.purple .mat-app-background, .app.purple.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app.purple .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.app.purple .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.purple .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.app.purple .mat-progress-bar-background {
  fill: #dac3e4;
}
.app.purple .mat-progress-bar-buffer {
  background-color: #dac3e4;
}
.app.purple .mat-progress-bar-fill::after {
  background-color: #7b1fa2;
}
.app.purple .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #cce4cd;
}
.app.purple .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #cce4cd;
}
.app.purple .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #43a047;
}
.app.purple .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #fbd0d0;
}
.app.purple .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #fbd0d0;
}
.app.purple .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #ff5252;
}
.app.purple .mat-progress-spinner circle, .app.purple .mat-spinner circle {
  stroke: #7b1fa2;
}
.app.purple .mat-progress-spinner.mat-accent circle, .app.purple .mat-spinner.mat-accent circle {
  stroke: #43a047;
}
.app.purple .mat-progress-spinner.mat-warn circle, .app.purple .mat-spinner.mat-warn circle {
  stroke: #ff5252;
}
.app.purple .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.app.purple .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.app.purple .mat-input-element:disabled,
.app.purple .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.app.purple .mat-input-element {
  caret-color: #7b1fa2;
}
.app.purple .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.purple .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.purple .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.purple .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.purple .mat-form-field.mat-accent .mat-input-element {
  caret-color: #43a047;
}
.app.purple .mat-form-field.mat-warn .mat-input-element,
.app.purple .mat-form-field-invalid .mat-input-element {
  caret-color: #ff5252;
}
.app.purple .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #ff5252;
}
.app.purple .mat-form-field-disabled .mat-form-field-prefix,
.app.purple .mat-form-field-disabled .mat-form-field-suffix {
  color: rgba(0, 0, 0, 0.38);
}
.app.purple .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.app.purple .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.app.purple .mat-form-field.mat-focused .mat-form-field-label {
  color: #7b1fa2;
}
.app.purple .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #43a047;
}
.app.purple .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #ff5252;
}
.app.purple .mat-focused .mat-form-field-required-marker {
  color: #43a047;
}
.app.purple .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.app.purple .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #7b1fa2;
}
.app.purple .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #43a047;
}
.app.purple .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #ff5252;
}
.app.purple .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #7b1fa2;
}
.app.purple .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #43a047;
}
.app.purple .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #ff5252;
}
.app.purple .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #ff5252;
}
.app.purple .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.app.purple .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #ff5252;
}
.app.purple .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.app.purple .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #ff5252;
}
.app.purple .mat-error {
  color: #ff5252;
}
.app.purple .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.purple .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.app.purple .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.app.purple .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app.purple .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.app.purple .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app.purple .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.app.purple .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.app.purple .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.app.purple .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.purple .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.app.purple .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.app.purple .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.app.purple .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #7b1fa2;
}
.app.purple .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #43a047;
}
.app.purple .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #ff5252;
}
.app.purple .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #ff5252;
}
.app.purple .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.purple .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.app.purple .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.app.purple .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.purple .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.app.purple .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.app.purple .mat-select-panel {
  background: white;
}
.app.purple .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.app.purple .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #7b1fa2;
}
.app.purple .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #43a047;
}
.app.purple .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #ff5252;
}
.app.purple .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #ff5252;
}
.app.purple .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.app.purple .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.app.purple .mat-checkbox-checkmark {
  fill: #fafafa;
}
.app.purple .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.app.purple .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.app.purple .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .app.purple .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #7b1fa2;
}
.app.purple .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .app.purple .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #43a047;
}
.app.purple .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .app.purple .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #ff5252;
}
.app.purple .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .app.purple .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.app.purple .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.app.purple .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.purple .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.app.purple .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.app.purple .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #7b1fa2;
}
.app.purple .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.app.purple .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #43a047;
}
.app.purple .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.app.purple .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #ff5252;
}
.app.purple .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.purple .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.app.purple .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.app.purple .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.purple .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.app.purple .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.app.purple .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.app.purple .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.app.purple .mat-chip.mat-standard-chip::after {
  background: black;
}
.app.purple .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #7b1fa2;
  color: white;
}
.app.purple .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.purple .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.purple .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #ff5252;
  color: white;
}
.app.purple .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.purple .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.purple .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #43a047;
  color: white;
}
.app.purple .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.purple .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.purple .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #43a047;
}
.app.purple .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(67, 160, 71, 0.54);
}
.app.purple .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #43a047;
}
.app.purple .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #7b1fa2;
}
.app.purple .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(123, 31, 162, 0.54);
}
.app.purple .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #7b1fa2;
}
.app.purple .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff5252;
}
.app.purple .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 82, 82, 0.54);
}
.app.purple .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #ff5252;
}
.app.purple .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.app.purple .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.app.purple .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.purple .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.app.purple .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #7b1fa2;
}
.app.purple .mat-radio-button.mat-primary .mat-radio-inner-circle,
.app.purple .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.purple .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .app.purple .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #7b1fa2;
}
.app.purple .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #43a047;
}
.app.purple .mat-radio-button.mat-accent .mat-radio-inner-circle,
.app.purple .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.purple .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .app.purple .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #43a047;
}
.app.purple .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff5252;
}
.app.purple .mat-radio-button.mat-warn .mat-radio-inner-circle,
.app.purple .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.purple .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .app.purple .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #ff5252;
}
.app.purple .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.app.purple .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.purple .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.app.purple .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.purple .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.app.purple .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.app.purple .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.purple .mat-slider.mat-primary .mat-slider-track-fill,
.app.purple .mat-slider.mat-primary .mat-slider-thumb,
.app.purple .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #7b1fa2;
}
.app.purple .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.app.purple .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(123, 31, 162, 0.2);
}
.app.purple .mat-slider.mat-accent .mat-slider-track-fill,
.app.purple .mat-slider.mat-accent .mat-slider-thumb,
.app.purple .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #43a047;
}
.app.purple .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.app.purple .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(67, 160, 71, 0.2);
}
.app.purple .mat-slider.mat-warn .mat-slider-track-fill,
.app.purple .mat-slider.mat-warn .mat-slider-thumb,
.app.purple .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #ff5252;
}
.app.purple .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.app.purple .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(255, 82, 82, 0.2);
}
.app.purple .mat-slider:hover .mat-slider-track-background,
.app.purple .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.purple .mat-slider.mat-slider-disabled .mat-slider-track-background,
.app.purple .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.app.purple .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.purple .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.purple .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.purple .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.app.purple .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.app.purple .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.app.purple .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.purple .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.app.purple .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .app.purple .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.purple .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .app.purple .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.app.purple .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.app.purple .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.app.purple .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.app.purple .mat-menu-panel {
  background: white;
}
.app.purple .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.app.purple .mat-menu-item[disabled],
.app.purple .mat-menu-item[disabled] .mat-menu-submenu-icon,
.app.purple .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.app.purple .mat-menu-item .mat-icon-no-color,
.app.purple .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.app.purple .mat-menu-item:hover:not([disabled]),
.app.purple .mat-menu-item.cdk-program-focused:not([disabled]),
.app.purple .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.app.purple .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.app.purple .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.app.purple .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.app.purple .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.app.purple .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.app.purple .mat-list-option:hover, .app.purple .mat-list-option:focus,
.app.purple .mat-nav-list .mat-list-item:hover,
.app.purple .mat-nav-list .mat-list-item:focus,
.app.purple .mat-action-list .mat-list-item:hover,
.app.purple .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.app.purple .mat-list-single-selected-option, .app.purple .mat-list-single-selected-option:hover, .app.purple .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.app.purple .mat-paginator {
  background: white;
}
.app.purple .mat-paginator,
.app.purple .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.app.purple .mat-paginator-decrement,
.app.purple .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.app.purple .mat-paginator-first,
.app.purple .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.app.purple .mat-icon-button[disabled] .mat-paginator-decrement,
.app.purple .mat-icon-button[disabled] .mat-paginator-increment,
.app.purple .mat-icon-button[disabled] .mat-paginator-first,
.app.purple .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.purple .mat-paginator-container {
  min-height: 56px;
}
.app.purple .mat-tab-nav-bar,
.app.purple .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.app.purple .mat-tab-group-inverted-header .mat-tab-nav-bar,
.app.purple .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.app.purple .mat-tab-label, .app.purple .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.app.purple .mat-tab-label.mat-tab-disabled, .app.purple .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.app.purple .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.app.purple .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.purple .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.app.purple .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.app.purple .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused, .app.purple .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused,
.app.purple .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused,
.app.purple .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused, .app.purple .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused, .app.purple .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused,
.app.purple .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused,
.app.purple .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused {
  background-color: rgba(243, 229, 245, 0.3);
}
.app.purple .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.purple .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.purple .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.purple .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled, .app.purple .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.purple .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.purple .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.purple .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.purple .mat-tab-group.mat-primary .mat-ink-bar, .app.purple .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #7b1fa2;
}
.app.purple .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .app.purple .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .app.purple .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .app.purple .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.app.purple .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused, .app.purple .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused,
.app.purple .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused,
.app.purple .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused, .app.purple .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused, .app.purple .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused,
.app.purple .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused,
.app.purple .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused {
  background-color: rgba(200, 230, 201, 0.3);
}
.app.purple .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.purple .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.purple .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.purple .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled, .app.purple .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.purple .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.purple .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.purple .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.purple .mat-tab-group.mat-accent .mat-ink-bar, .app.purple .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #43a047;
}
.app.purple .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .app.purple .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .app.purple .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .app.purple .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.app.purple .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused, .app.purple .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused,
.app.purple .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused,
.app.purple .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused, .app.purple .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused, .app.purple .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused,
.app.purple .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused,
.app.purple .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused {
  background-color: rgba(255, 205, 210, 0.3);
}
.app.purple .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.purple .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.purple .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.purple .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled, .app.purple .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.purple .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.purple .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.purple .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.purple .mat-tab-group.mat-warn .mat-ink-bar, .app.purple .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #ff5252;
}
.app.purple .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .app.purple .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .app.purple .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .app.purple .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.app.purple .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused, .app.purple .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused,
.app.purple .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused,
.app.purple .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused, .app.purple .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused, .app.purple .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused,
.app.purple .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused,
.app.purple .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused {
  background-color: rgba(243, 229, 245, 0.3);
}
.app.purple .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.purple .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.purple .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.purple .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled, .app.purple .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.purple .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.purple .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.purple .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.purple .mat-tab-group.mat-background-primary > .mat-tab-header, .app.purple .mat-tab-group.mat-background-primary > .mat-tab-link-container, .app.purple .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .app.purple .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .app.purple .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .app.purple .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #7b1fa2;
}
.app.purple .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .app.purple .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .app.purple .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .app.purple .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.app.purple .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.purple .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .app.purple .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.purple .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.purple .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.purple .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.purple .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.app.purple .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .app.purple .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.purple .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.purple .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.app.purple .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.app.purple .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.purple .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.purple .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.purple .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.app.purple .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.app.purple .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.app.purple .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .app.purple .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.app.purple .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.app.purple .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.app.purple .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused, .app.purple .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused,
.app.purple .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused,
.app.purple .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused, .app.purple .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused, .app.purple .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused,
.app.purple .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused,
.app.purple .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused {
  background-color: rgba(200, 230, 201, 0.3);
}
.app.purple .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.purple .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.purple .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.purple .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled, .app.purple .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.purple .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.purple .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.purple .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.purple .mat-tab-group.mat-background-accent > .mat-tab-header, .app.purple .mat-tab-group.mat-background-accent > .mat-tab-link-container, .app.purple .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .app.purple .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .app.purple .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .app.purple .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #43a047;
}
.app.purple .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .app.purple .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .app.purple .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .app.purple .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.app.purple .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.purple .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .app.purple .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.purple .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.purple .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.purple .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.purple .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.app.purple .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .app.purple .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.purple .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.purple .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.app.purple .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.app.purple .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.purple .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.purple .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.purple .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.app.purple .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.app.purple .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.app.purple .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .app.purple .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.app.purple .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.app.purple .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.app.purple .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused, .app.purple .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused,
.app.purple .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused,
.app.purple .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused, .app.purple .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused, .app.purple .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused,
.app.purple .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused,
.app.purple .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused {
  background-color: rgba(255, 205, 210, 0.3);
}
.app.purple .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.purple .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.purple .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.purple .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled, .app.purple .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.purple .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.purple .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.purple .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.purple .mat-tab-group.mat-background-warn > .mat-tab-header, .app.purple .mat-tab-group.mat-background-warn > .mat-tab-link-container, .app.purple .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .app.purple .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .app.purple .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .app.purple .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #ff5252;
}
.app.purple .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .app.purple .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .app.purple .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .app.purple .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.app.purple .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.purple .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .app.purple .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.purple .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.purple .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.purple .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.purple .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.app.purple .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .app.purple .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.purple .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.purple .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.app.purple .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.app.purple .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.purple .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.purple .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.purple .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.app.purple .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.app.purple .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.app.purple .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .app.purple .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.app.purple .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.app.purple .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.app.purple .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-simple-snackbar-action {
  color: #43a047;
}
.app.purple .mat-table {
  background: white;
}
.app.purple .mat-table thead, .app.purple .mat-table tbody, .app.purple .mat-table tfoot,
.app.purple mat-header-row, .app.purple mat-row, .app.purple mat-footer-row,
.app.purple [mat-header-row], .app.purple [mat-row], .app.purple [mat-footer-row],
.app.purple .mat-table-sticky {
  background: inherit;
}
.app.purple mat-row, .app.purple mat-header-row, .app.purple mat-footer-row,
.app.purple th.mat-header-cell, .app.purple td.mat-cell, .app.purple td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.app.purple .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.app.purple .mat-cell, .app.purple .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.app.purple .mat-badge {
  position: relative;
}
.app.purple .mat-badge.mat-badge {
  overflow: visible;
}
.app.purple .mat-badge-hidden .mat-badge-content {
  display: none;
}
.app.purple .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.app.purple .ng-animate-disabled .mat-badge-content,
.app.purple .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.app.purple .mat-badge-content.mat-badge-active {
  transform: none;
}
.app.purple .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.app.purple .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.app.purple .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.app.purple .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .app.purple .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.app.purple .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .app.purple .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.app.purple .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .app.purple .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.app.purple .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .app.purple .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.app.purple .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.app.purple .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.app.purple .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.app.purple .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .app.purple .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.app.purple .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .app.purple .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.app.purple .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .app.purple .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.app.purple .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .app.purple .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.app.purple .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.app.purple .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.app.purple .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.app.purple .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .app.purple .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.app.purple .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .app.purple .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.app.purple .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .app.purple .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.app.purple .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .app.purple .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.app.purple .mat-badge-content {
  color: white;
  background: #7b1fa2;
}
.cdk-high-contrast-active .app.purple .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.app.purple .mat-badge-accent .mat-badge-content {
  background: #43a047;
  color: white;
}
.app.purple .mat-badge-warn .mat-badge-content {
  color: white;
  background: #ff5252;
}
.app.purple .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.app.purple .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.purple .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.app.purple .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.app.purple .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.app.purple .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.app.purple .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.purple .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.app.purple .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.app.purple .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.app.purple [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.app.purple .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.app.purple .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.app.purple .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.app.purple .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.app.purple .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.app.purple .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.app.purple .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.app.purple .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.app.purple .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}
.app.purple .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.app.purple .mat-datepicker-toggle,
.app.purple .mat-datepicker-content .mat-calendar-next-button,
.app.purple .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.app.purple .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.app.purple .mat-calendar-table-header,
.app.purple .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.purple .mat-calendar-body-cell-content,
.app.purple .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.app.purple .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.app.purple .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.app.purple .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.app.purple .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.purple .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.app.purple .mat-calendar-body-in-range::before {
  background: rgba(123, 31, 162, 0.2);
}
.app.purple .mat-calendar-body-comparison-identical,
.app.purple .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.purple .mat-calendar-body-comparison-bridge-start::before,
.app.purple [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(123, 31, 162, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.purple .mat-calendar-body-comparison-bridge-end::before,
.app.purple [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(123, 31, 162, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.purple .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.purple .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.purple .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.purple .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.purple .mat-calendar-body-selected {
  background-color: #7b1fa2;
  color: white;
}
.app.purple .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(123, 31, 162, 0.4);
}
.app.purple .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.purple .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.purple .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(123, 31, 162, 0.3);
}
@media (hover: hover) {
  .app.purple .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(123, 31, 162, 0.3);
  }
}
.app.purple .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.purple .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(67, 160, 71, 0.2);
}
.app.purple .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.app.purple .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.purple .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.app.purple .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(67, 160, 71, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.purple .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.app.purple .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(67, 160, 71, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.purple .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.purple .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.purple .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.purple .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.purple .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #43a047;
  color: white;
}
.app.purple .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(67, 160, 71, 0.4);
}
.app.purple .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.purple .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.purple .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(67, 160, 71, 0.3);
}
@media (hover: hover) {
  .app.purple .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(67, 160, 71, 0.3);
  }
}
.app.purple .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(255, 82, 82, 0.2);
}
.app.purple .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.app.purple .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.purple .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.app.purple .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 82, 82, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.purple .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.app.purple .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 82, 82, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.purple .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.purple .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.purple .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.purple .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.purple .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #ff5252;
  color: white;
}
.app.purple .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 82, 82, 0.4);
}
.app.purple .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.purple .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.purple .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 82, 82, 0.3);
}
@media (hover: hover) {
  .app.purple .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 82, 82, 0.3);
  }
}
.app.purple .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-datepicker-toggle-active {
  color: #7b1fa2;
}
.app.purple .mat-datepicker-toggle-active.mat-accent {
  color: #43a047;
}
.app.purple .mat-datepicker-toggle-active.mat-warn {
  color: #ff5252;
}
.app.purple .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.app.purple .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  width: 40px;
  height: 40px;
  padding: 8px;
}
.app.purple .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.app.purple .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 40px;
  height: 40px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  margin-left: 0px;
}
.app.purple .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.app.purple .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 40px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 40px;
  transform: translate(-50%, -50%);
}
.app.purple .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}
.app.purple .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.purple .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.app.purple .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.purple .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.purple .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .app.purple .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .app.purple .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .app.purple .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.app.purple .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.app.purple .mat-expansion-panel-header-description,
.app.purple .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.app.purple .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.app.purple .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.app.purple .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.app.purple .mat-expansion-panel-header {
  height: 48px;
}
.app.purple .mat-expansion-panel-header.mat-expanded {
  height: 64px;
}
.app.purple .mat-icon.mat-primary {
  color: #7b1fa2;
}
.app.purple .mat-icon.mat-accent {
  color: #43a047;
}
.app.purple .mat-icon.mat-warn {
  color: #ff5252;
}
.app.purple .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app.purple .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.purple .mat-drawer.mat-drawer-push {
  background-color: white;
}
.app.purple .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.app.purple [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.app.purple [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app.purple .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.app.purple .mat-step-header.cdk-keyboard-focused, .app.purple .mat-step-header.cdk-program-focused, .app.purple .mat-step-header:hover:not([aria-disabled]), .app.purple .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.app.purple .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .app.purple .mat-step-header:hover {
    background: none;
  }
}
.app.purple .mat-step-header .mat-step-label,
.app.purple .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.app.purple .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.app.purple .mat-step-header .mat-step-icon-selected,
.app.purple .mat-step-header .mat-step-icon-state-done,
.app.purple .mat-step-header .mat-step-icon-state-edit {
  background-color: #7b1fa2;
  color: white;
}
.app.purple .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.app.purple .mat-step-header.mat-accent .mat-step-icon-selected,
.app.purple .mat-step-header.mat-accent .mat-step-icon-state-done,
.app.purple .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #43a047;
  color: white;
}
.app.purple .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.app.purple .mat-step-header.mat-warn .mat-step-icon-selected,
.app.purple .mat-step-header.mat-warn .mat-step-icon-state-done,
.app.purple .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #ff5252;
  color: white;
}
.app.purple .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #ff5252;
}
.app.purple .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.app.purple .mat-step-header .mat-step-label.mat-step-label-error {
  color: #ff5252;
}
.app.purple .mat-stepper-horizontal, .app.purple .mat-stepper-vertical {
  background-color: white;
}
.app.purple .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.app.purple .mat-horizontal-stepper-header::before,
.app.purple .mat-horizontal-stepper-header::after,
.app.purple .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.purple .mat-horizontal-stepper-header {
  height: 72px;
}
.app.purple .mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.app.purple .mat-vertical-stepper-header {
  padding: 24px 24px;
}
.app.purple .mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}
.app.purple .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .app.purple .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}
.app.purple .mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}
.app.purple .mat-sort-header-arrow {
  color: #757575;
}
.app.purple .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.app.purple .mat-toolbar.mat-primary {
  background: #7b1fa2;
  color: white;
}
.app.purple .mat-toolbar.mat-accent {
  background: #43a047;
  color: white;
}
.app.purple .mat-toolbar.mat-warn {
  background: #ff5252;
  color: white;
}
.app.purple .mat-toolbar .mat-form-field-underline,
.app.purple .mat-toolbar .mat-form-field-ripple,
.app.purple .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.app.purple .mat-toolbar .mat-form-field-label,
.app.purple .mat-toolbar .mat-focused .mat-form-field-label,
.app.purple .mat-toolbar .mat-select-value,
.app.purple .mat-toolbar .mat-select-arrow,
.app.purple .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.app.purple .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.app.purple .mat-toolbar-multiple-rows {
  min-height: 44px;
}
.app.purple .mat-toolbar-row, .app.purple .mat-toolbar-single-row {
  height: 44px;
}
@media (max-width: 599px) {
  .app.purple .mat-toolbar-multiple-rows {
    min-height: 56px;
  }
  .app.purple .mat-toolbar-row, .app.purple .mat-toolbar-single-row {
    height: 56px;
  }
}
.app.purple .mat-tree {
  background: white;
}
.app.purple .mat-tree-node,
.app.purple .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.app.purple .mat-tree-node {
  min-height: 48px;
}
.app.purple .bg-primary {
  background-color: var(--primary);
  color: white;
}
.app.purple .bg-accent {
  background-color: var(--accent);
  color: white;
}
.app.purple .bg-warn {
  background-color: var(--warn);
  color: white;
}
.app.purple .text-muted {
  color: rgba(0, 0, 0, 0.54) !important;
}
.app.purple .primary-color {
  color: #7b1fa2;
}
.app.purple .secondary-color {
  color: #e1bee7;
}
.app.purple .v-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.purple .divider {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.app.purple .top-toolbar.mat-toolbar .v-divider {
  background-color: rgba(243, 229, 245, 0.2);
}
.app.purple .border-bottom-lighter {
  border-bottom: 1px solid rgba(243, 229, 245, 0.2);
}
.app.purple .border-lighter {
  border-color: rgba(243, 229, 245, 0.2);
}
.app.purple .border-accent {
  border-color: #43a047;
}
.app.purple .border-primary {
  border-color: #7b1fa2 !important;
}
.app.purple #top-toolbar {
  background-color: #9c27b0;
  color: white;
}
.app.purple .toolbar-2 #top-toolbar {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.purple #main-toolbar {
  background: white;
}
.app.purple .toolbar-2 #main-toolbar {
  color: var(--primary);
}
.app.purple header .logo svg {
  fill: #7b1fa2;
}
.app.purple header.toolbar-1.has-bg-image:not(.main-toolbar-fixed) .logo svg, .app.purple header.toolbar-1.has-bg-video:not(.main-toolbar-fixed) .logo svg {
  fill: #fff;
}
.app.purple header.toolbar-1.has-bg-image:not(.main-toolbar-fixed) .horizontal-menu .mat-button, .app.purple header.toolbar-1.has-bg-video:not(.main-toolbar-fixed) .horizontal-menu .mat-button {
  color: #fff;
}
.app.purple header.toolbar-1.has-bg-image:not(.main-toolbar-fixed) .horizontal-menu .mat-button.horizontal-active-link, .app.purple header.toolbar-1.has-bg-video:not(.main-toolbar-fixed) .horizontal-menu .mat-button.horizontal-active-link {
  background: rgba(123, 31, 162, 0.38);
}
.app.purple header.toolbar-1.has-bg-image:not(.main-toolbar-fixed) .mat-icon, .app.purple header.toolbar-1.has-bg-video:not(.main-toolbar-fixed) .mat-icon {
  color: #fff;
}
.app.purple header.toolbar-2 .horizontal-menu .mat-button {
  color: #fff;
}
.app.purple header.toolbar-2 .horizontal-menu .mat-button.horizontal-active-link {
  color: #fff;
}
.app.purple header.toolbar-2.has-bg-image:not(.main-toolbar-fixed) .horizontal-menu .mat-button.horizontal-active-link, .app.purple header.toolbar-2.has-bg-video:not(.main-toolbar-fixed) .horizontal-menu .mat-button.horizontal-active-link {
  background: rgba(123, 31, 162, 0.38);
}
.app.purple .header-carousel .slide-info .mat-card {
  background: rgba(255, 255, 255, 0.85);
}
.app.purple .header-carousel .slide-info .mat-card:hover {
  background: white;
}
.app.purple .user-menu .user-info {
  color: rgba(0, 0, 0, 0.87);
}
.app.purple main.content-offset-to-top .main-content-header {
  border-top: 5px solid #7b1fa2;
}
.app.purple .map-info-window .si-content-wrapper {
  padding: 2px;
  color: var(--primary);
}
.app.purple .map-info-window .si-content-wrapper .si-content {
  max-width: 100% !important;
  max-height: 100% !important;
}
.app.purple .map-info-window .si-content-wrapper .si-content a {
  color: inherit;
  text-decoration: none;
}
.app.purple .map-info-window .si-pointer-bg-top {
  border-top-color: #7b1fa2;
}
.app.purple .map-info-window .si-pointer-border-top {
  border-top-color: #7b1fa2;
}
.app.purple .map-info-window .si-close-button {
  opacity: 1;
  right: 4px;
  top: 4px;
  padding: 0 6px;
  border-radius: 50%;
  color: var(--primary);
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.purple .map-info-window.si-has-border .si-content-wrapper {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.purple .property-item .title a,
.app.purple .compare-item .title a {
  color: rgba(0, 0, 0, 0.87);
}
.app.purple .property-item .title a:hover,
.app.purple .compare-item .title a:hover {
  color: #7b1fa2;
}
.app.purple .features p:after,
.app.purple .compare-item .details .item {
  border-bottom: 1px dotted rgba(0, 0, 0, 0.12);
}
.app.purple .small-carousel .swiper-slide.active-thumb img {
  border-color: #7b1fa2;
}
.app.purple .ps__thumb-y {
  background-color: #4a148c;
}
.app.purple .active-link {
  background-color: #7b1fa2;
  color: #fff;
}
.app.purple .horizontal-active-link {
  background: rgba(0, 0, 0, 0.04);
  color: #7b1fa2;
}
.app.purple .horizontal-sub-menu .mat-button, .app.purple .horizontal-sub-menu .mat-menu-item {
  color: rgba(0, 0, 0, 0.87);
}
.app.purple .horizontal-sub-menu .mat-button.horizontal-active-link, .app.purple .horizontal-sub-menu .mat-menu-item.horizontal-active-link {
  color: #7b1fa2;
}
.app.purple .section .section-title:after,
.app.purple .section.testimonials .swiper-pagination-bullet-active {
  background-color: #7b1fa2;
}
.app.purple .custom-table.mat-table .mat-header-row,
.app.purple .custom-table.mat-table .mat-row {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.purple .submit-property .step-section,
.app.purple .edit-property .step-section {
  background: rgba(250, 250, 250, 0.7);
}
.app.purple .input-file-container .mat-orphan-label {
  color: rgba(0, 0, 0, 0.87) !important;
}
.app.purple .nav-pills .nav-link.active, .app.purple .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #7b1fa2;
}
.app.purple .btn-danger {
  color: #fff;
  background-color: #7b1fa2;
  border-color: #7b1fa2;
}
.app.purple .btn-danger:not(:disabled):not(.disabled):active, .app.purple .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #7b1fa2;
  border-color: #7b1fa2;
}
.app.purple .btn-danger:hover {
  color: #fff;
  background-color: #7b1fa2;
  border-color: #7b1fa2;
}
.app.purple .btn-outline-danger {
  color: #7b1fa2;
  border-color: #7b1fa2;
}
.app.purple .btn-outline-danger:not(:disabled):not(.disabled):active, .app.purple .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #7b1fa2;
  border-color: #7b1fa2;
}
.app.purple .btn-outline-danger:hover {
  color: #fff;
  background-color: #7b1fa2;
  border-color: #7b1fa2;
}
.app.purple .pr-clr {
  color: #7b1fa2;
}
.app.purple .bg-primary {
  background-color: #7b1fa2 !important;
}
.app.purple a {
  color: #7b1fa2;
  text-decoration: none;
  background-color: transparent;
}
.app.purple .containers input:checked ~ .checkmark {
  background-color: #7b1fa2;
}
.app.purple .check input:checked ~ .checkmarks {
  background-color: #7b1fa2;
}
.app.purple .btn-outline-danger:not(:disabled):not(.disabled).active, .app.purple .btn-outline-danger:not(:disabled):not(.disabled):active, .app.purple .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #7b1fa2;
  border-color: #7b1fa2;
}
.app.grey {
  /* When the radio button is checked, add a blue background */
  /* When the checkbox is checked, add a blue background */
}
.app.grey .mat-button, .app.grey .mat-icon-button, .app.grey .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.app.grey .mat-button.mat-primary, .app.grey .mat-icon-button.mat-primary, .app.grey .mat-stroked-button.mat-primary {
  color: #455a64;
}
.app.grey .mat-button.mat-accent, .app.grey .mat-icon-button.mat-accent, .app.grey .mat-stroked-button.mat-accent {
  color: #43a047;
}
.app.grey .mat-button.mat-warn, .app.grey .mat-icon-button.mat-warn, .app.grey .mat-stroked-button.mat-warn {
  color: #f44336;
}
.app.grey .mat-button.mat-primary.mat-button-disabled, .app.grey .mat-button.mat-accent.mat-button-disabled, .app.grey .mat-button.mat-warn.mat-button-disabled, .app.grey .mat-button.mat-button-disabled.mat-button-disabled, .app.grey .mat-icon-button.mat-primary.mat-button-disabled, .app.grey .mat-icon-button.mat-accent.mat-button-disabled, .app.grey .mat-icon-button.mat-warn.mat-button-disabled, .app.grey .mat-icon-button.mat-button-disabled.mat-button-disabled, .app.grey .mat-stroked-button.mat-primary.mat-button-disabled, .app.grey .mat-stroked-button.mat-accent.mat-button-disabled, .app.grey .mat-stroked-button.mat-warn.mat-button-disabled, .app.grey .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.app.grey .mat-button.mat-primary .mat-button-focus-overlay, .app.grey .mat-icon-button.mat-primary .mat-button-focus-overlay, .app.grey .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #455a64;
}
.app.grey .mat-button.mat-accent .mat-button-focus-overlay, .app.grey .mat-icon-button.mat-accent .mat-button-focus-overlay, .app.grey .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #43a047;
}
.app.grey .mat-button.mat-warn .mat-button-focus-overlay, .app.grey .mat-icon-button.mat-warn .mat-button-focus-overlay, .app.grey .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336;
}
.app.grey .mat-button.mat-button-disabled .mat-button-focus-overlay, .app.grey .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .app.grey .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.app.grey .mat-button .mat-ripple-element, .app.grey .mat-icon-button .mat-ripple-element, .app.grey .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.app.grey .mat-button-focus-overlay {
  background: black;
}
.app.grey .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.grey .mat-flat-button, .app.grey .mat-raised-button, .app.grey .mat-fab, .app.grey .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.app.grey .mat-flat-button.mat-primary, .app.grey .mat-raised-button.mat-primary, .app.grey .mat-fab.mat-primary, .app.grey .mat-mini-fab.mat-primary {
  color: white;
}
.app.grey .mat-flat-button.mat-accent, .app.grey .mat-raised-button.mat-accent, .app.grey .mat-fab.mat-accent, .app.grey .mat-mini-fab.mat-accent {
  color: white;
}
.app.grey .mat-flat-button.mat-warn, .app.grey .mat-raised-button.mat-warn, .app.grey .mat-fab.mat-warn, .app.grey .mat-mini-fab.mat-warn {
  color: white;
}
.app.grey .mat-flat-button.mat-primary.mat-button-disabled, .app.grey .mat-flat-button.mat-accent.mat-button-disabled, .app.grey .mat-flat-button.mat-warn.mat-button-disabled, .app.grey .mat-flat-button.mat-button-disabled.mat-button-disabled, .app.grey .mat-raised-button.mat-primary.mat-button-disabled, .app.grey .mat-raised-button.mat-accent.mat-button-disabled, .app.grey .mat-raised-button.mat-warn.mat-button-disabled, .app.grey .mat-raised-button.mat-button-disabled.mat-button-disabled, .app.grey .mat-fab.mat-primary.mat-button-disabled, .app.grey .mat-fab.mat-accent.mat-button-disabled, .app.grey .mat-fab.mat-warn.mat-button-disabled, .app.grey .mat-fab.mat-button-disabled.mat-button-disabled, .app.grey .mat-mini-fab.mat-primary.mat-button-disabled, .app.grey .mat-mini-fab.mat-accent.mat-button-disabled, .app.grey .mat-mini-fab.mat-warn.mat-button-disabled, .app.grey .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.app.grey .mat-flat-button.mat-primary, .app.grey .mat-raised-button.mat-primary, .app.grey .mat-fab.mat-primary, .app.grey .mat-mini-fab.mat-primary {
  background-color: #455a64;
}
.app.grey .mat-flat-button.mat-accent, .app.grey .mat-raised-button.mat-accent, .app.grey .mat-fab.mat-accent, .app.grey .mat-mini-fab.mat-accent {
  background-color: #43a047;
}
.app.grey .mat-flat-button.mat-warn, .app.grey .mat-raised-button.mat-warn, .app.grey .mat-fab.mat-warn, .app.grey .mat-mini-fab.mat-warn {
  background-color: #f44336;
}
.app.grey .mat-flat-button.mat-primary.mat-button-disabled, .app.grey .mat-flat-button.mat-accent.mat-button-disabled, .app.grey .mat-flat-button.mat-warn.mat-button-disabled, .app.grey .mat-flat-button.mat-button-disabled.mat-button-disabled, .app.grey .mat-raised-button.mat-primary.mat-button-disabled, .app.grey .mat-raised-button.mat-accent.mat-button-disabled, .app.grey .mat-raised-button.mat-warn.mat-button-disabled, .app.grey .mat-raised-button.mat-button-disabled.mat-button-disabled, .app.grey .mat-fab.mat-primary.mat-button-disabled, .app.grey .mat-fab.mat-accent.mat-button-disabled, .app.grey .mat-fab.mat-warn.mat-button-disabled, .app.grey .mat-fab.mat-button-disabled.mat-button-disabled, .app.grey .mat-mini-fab.mat-primary.mat-button-disabled, .app.grey .mat-mini-fab.mat-accent.mat-button-disabled, .app.grey .mat-mini-fab.mat-warn.mat-button-disabled, .app.grey .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.grey .mat-flat-button.mat-primary .mat-ripple-element, .app.grey .mat-raised-button.mat-primary .mat-ripple-element, .app.grey .mat-fab.mat-primary .mat-ripple-element, .app.grey .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.grey .mat-flat-button.mat-accent .mat-ripple-element, .app.grey .mat-raised-button.mat-accent .mat-ripple-element, .app.grey .mat-fab.mat-accent .mat-ripple-element, .app.grey .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.grey .mat-flat-button.mat-warn .mat-ripple-element, .app.grey .mat-raised-button.mat-warn .mat-ripple-element, .app.grey .mat-fab.mat-warn .mat-ripple-element, .app.grey .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.grey .mat-stroked-button:not([class*=mat-elevation-z]), .app.grey .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-fab:not([class*=mat-elevation-z]), .app.grey .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .app.grey .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .app.grey .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-datepicker-content .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.app.grey .mat-datepicker-content .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #455a64;
}
.app.grey .mat-datepicker-content .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #43a047;
}
.app.grey .mat-datepicker-content .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
}
.app.grey .mat-datepicker-content .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.app.grey .mat-datepicker-content .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.app.grey .mat-datepicker-content .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #455a64;
  --mdc-filled-button-label-text-color: #fff;
}
.app.grey .mat-datepicker-content .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #43a047;
  --mdc-filled-button-label-text-color: #fff;
}
.app.grey .mat-datepicker-content .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: #fff;
}
.app.grey .mat-datepicker-content .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.app.grey .mat-datepicker-content .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.app.grey .mat-datepicker-content .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #455a64;
  --mdc-protected-button-label-text-color: #fff;
}
.app.grey .mat-datepicker-content .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #43a047;
  --mdc-protected-button-label-text-color: #fff;
}
.app.grey .mat-datepicker-content .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: #fff;
}
.app.grey .mat-datepicker-content .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}
.app.grey .mat-datepicker-content .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.app.grey .mat-datepicker-content .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.app.grey .mat-datepicker-content .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #455a64;
}
.app.grey .mat-datepicker-content .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #43a047;
}
.app.grey .mat-datepicker-content .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
}
.app.grey .mat-datepicker-content .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}
.app.grey .mat-datepicker-content .mat-mdc-button, .app.grey .mat-datepicker-content .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.app.grey .mat-datepicker-content .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .app.grey .mat-datepicker-content .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.app.grey .mat-datepicker-content .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.grey .mat-datepicker-content .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .app.grey .mat-datepicker-content .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.grey .mat-datepicker-content .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.grey .mat-datepicker-content .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .app.grey .mat-datepicker-content .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.grey .mat-datepicker-content .mat-mdc-button.mat-primary, .app.grey .mat-datepicker-content .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #455a64;
  --mat-mdc-button-ripple-color: rgba(69, 90, 100, 0.1);
}
.app.grey .mat-datepicker-content .mat-mdc-button.mat-accent, .app.grey .mat-datepicker-content .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #43a047;
  --mat-mdc-button-ripple-color: rgba(67, 160, 71, 0.1);
}
.app.grey .mat-datepicker-content .mat-mdc-button.mat-warn, .app.grey .mat-datepicker-content .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.app.grey .mat-datepicker-content .mat-mdc-raised-button, .app.grey .mat-datepicker-content .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.app.grey .mat-datepicker-content .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .app.grey .mat-datepicker-content .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.app.grey .mat-datepicker-content .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.grey .mat-datepicker-content .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .app.grey .mat-datepicker-content .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.grey .mat-datepicker-content .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.grey .mat-datepicker-content .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .app.grey .mat-datepicker-content .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.grey .mat-datepicker-content .mat-mdc-raised-button.mat-primary, .app.grey .mat-datepicker-content .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.app.grey .mat-datepicker-content .mat-mdc-raised-button.mat-accent, .app.grey .mat-datepicker-content .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.app.grey .mat-datepicker-content .mat-mdc-raised-button.mat-warn, .app.grey .mat-datepicker-content .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.app.grey .mat-datepicker-content .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.app.grey .mat-datepicker-content .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.app.grey .mat-datepicker-content .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.grey .mat-datepicker-content .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.grey .mat-datepicker-content .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.grey .mat-datepicker-content .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #455a64;
  --mat-mdc-button-ripple-color: rgba(69, 90, 100, 0.1);
}
.app.grey .mat-datepicker-content .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #43a047;
  --mat-mdc-button-ripple-color: rgba(67, 160, 71, 0.1);
}
.app.grey .mat-datepicker-content .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.app.grey .mat-datepicker-content .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #455a64;
}
.app.grey .mat-datepicker-content .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #43a047;
}
.app.grey .mat-datepicker-content .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #f44336;
}
.app.grey .mat-datepicker-content .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}
.app.grey .mat-datepicker-content .mat-mdc-button.mat-mdc-button-base,
.app.grey .mat-datepicker-content .mat-mdc-raised-button.mat-mdc-button-base,
.app.grey .mat-datepicker-content .mat-mdc-unelevated-button.mat-mdc-button-base,
.app.grey .mat-datepicker-content .mat-mdc-outlined-button.mat-mdc-button-base {
  height: 36px;
}
.app.grey .mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base {
  width: 48px;
  height: 48px;
  padding: 12px;
}
.app.grey .mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__focus-ring {
  max-height: 48px;
  max-width: 48px;
}
.app.grey .mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 40px;
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 4px;
  margin-left: 4px;
}
.app.grey .mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.app.grey .mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 48px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 48px;
  transform: translate(-50%, -50%);
}
.app.grey .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.app.grey .mat-option:hover:not(.mat-option-disabled), .app.grey .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.app.grey .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.app.grey .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.app.grey .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.app.grey .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #455a64;
}
.app.grey .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #43a047;
}
.app.grey .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}
.app.grey .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.grey .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.grey .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.grey .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.app.grey .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.app.grey .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.app.grey .mat-primary .mat-pseudo-checkbox-checked,
.app.grey .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #455a64;
}
.app.grey .mat-pseudo-checkbox-checked,
.app.grey .mat-pseudo-checkbox-indeterminate,
.app.grey .mat-accent .mat-pseudo-checkbox-checked,
.app.grey .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #43a047;
}
.app.grey .mat-warn .mat-pseudo-checkbox-checked,
.app.grey .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}
.app.grey .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.app.grey .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.app.grey .mat-app-background, .app.grey.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app.grey .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.app.grey .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.grey .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.app.grey .mat-progress-bar-background {
  fill: #cdd2d5;
}
.app.grey .mat-progress-bar-buffer {
  background-color: #cdd2d5;
}
.app.grey .mat-progress-bar-fill::after {
  background-color: #455a64;
}
.app.grey .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #cce4cd;
}
.app.grey .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #cce4cd;
}
.app.grey .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #43a047;
}
.app.grey .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f9ccc9;
}
.app.grey .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f9ccc9;
}
.app.grey .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336;
}
.app.grey .mat-progress-spinner circle, .app.grey .mat-spinner circle {
  stroke: #455a64;
}
.app.grey .mat-progress-spinner.mat-accent circle, .app.grey .mat-spinner.mat-accent circle {
  stroke: #43a047;
}
.app.grey .mat-progress-spinner.mat-warn circle, .app.grey .mat-spinner.mat-warn circle {
  stroke: #f44336;
}
.app.grey .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.app.grey .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.app.grey .mat-input-element:disabled,
.app.grey .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.app.grey .mat-input-element {
  caret-color: #455a64;
}
.app.grey .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.grey .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.grey .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.grey .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.grey .mat-form-field.mat-accent .mat-input-element {
  caret-color: #43a047;
}
.app.grey .mat-form-field.mat-warn .mat-input-element,
.app.grey .mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}
.app.grey .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}
.app.grey .mat-form-field-disabled .mat-form-field-prefix,
.app.grey .mat-form-field-disabled .mat-form-field-suffix {
  color: rgba(0, 0, 0, 0.38);
}
.app.grey .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.app.grey .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.app.grey .mat-form-field.mat-focused .mat-form-field-label {
  color: #455a64;
}
.app.grey .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #43a047;
}
.app.grey .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}
.app.grey .mat-focused .mat-form-field-required-marker {
  color: #43a047;
}
.app.grey .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.app.grey .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #455a64;
}
.app.grey .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #43a047;
}
.app.grey .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}
.app.grey .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #455a64;
}
.app.grey .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #43a047;
}
.app.grey .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}
.app.grey .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}
.app.grey .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.app.grey .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}
.app.grey .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.app.grey .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}
.app.grey .mat-error {
  color: #f44336;
}
.app.grey .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.grey .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.app.grey .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.app.grey .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app.grey .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.app.grey .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app.grey .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.app.grey .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.app.grey .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.app.grey .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.grey .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.app.grey .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.app.grey .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.app.grey .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #455a64;
}
.app.grey .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #43a047;
}
.app.grey .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}
.app.grey .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}
.app.grey .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.grey .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.app.grey .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.app.grey .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.grey .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.app.grey .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.app.grey .mat-select-panel {
  background: white;
}
.app.grey .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.app.grey .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #455a64;
}
.app.grey .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #43a047;
}
.app.grey .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}
.app.grey .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}
.app.grey .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.app.grey .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.app.grey .mat-checkbox-checkmark {
  fill: #fafafa;
}
.app.grey .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.app.grey .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.app.grey .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .app.grey .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #455a64;
}
.app.grey .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .app.grey .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #43a047;
}
.app.grey .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .app.grey .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}
.app.grey .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .app.grey .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.app.grey .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.app.grey .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.grey .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.app.grey .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.app.grey .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #455a64;
}
.app.grey .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.app.grey .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #43a047;
}
.app.grey .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.app.grey .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}
.app.grey .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.grey .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.app.grey .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.app.grey .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.grey .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.app.grey .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.app.grey .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.app.grey .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.app.grey .mat-chip.mat-standard-chip::after {
  background: black;
}
.app.grey .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #455a64;
  color: white;
}
.app.grey .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.grey .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.grey .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white;
}
.app.grey .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.grey .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.grey .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #43a047;
  color: white;
}
.app.grey .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.grey .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.grey .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #43a047;
}
.app.grey .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(67, 160, 71, 0.54);
}
.app.grey .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #43a047;
}
.app.grey .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #455a64;
}
.app.grey .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(69, 90, 100, 0.54);
}
.app.grey .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #455a64;
}
.app.grey .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336;
}
.app.grey .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54);
}
.app.grey .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336;
}
.app.grey .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.app.grey .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.app.grey .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.grey .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.app.grey .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #455a64;
}
.app.grey .mat-radio-button.mat-primary .mat-radio-inner-circle,
.app.grey .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.grey .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .app.grey .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #455a64;
}
.app.grey .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #43a047;
}
.app.grey .mat-radio-button.mat-accent .mat-radio-inner-circle,
.app.grey .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.grey .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .app.grey .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #43a047;
}
.app.grey .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}
.app.grey .mat-radio-button.mat-warn .mat-radio-inner-circle,
.app.grey .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.grey .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .app.grey .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}
.app.grey .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.app.grey .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.grey .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.app.grey .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.grey .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.app.grey .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.app.grey .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.grey .mat-slider.mat-primary .mat-slider-track-fill,
.app.grey .mat-slider.mat-primary .mat-slider-thumb,
.app.grey .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #455a64;
}
.app.grey .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.app.grey .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(69, 90, 100, 0.2);
}
.app.grey .mat-slider.mat-accent .mat-slider-track-fill,
.app.grey .mat-slider.mat-accent .mat-slider-thumb,
.app.grey .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #43a047;
}
.app.grey .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.app.grey .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(67, 160, 71, 0.2);
}
.app.grey .mat-slider.mat-warn .mat-slider-track-fill,
.app.grey .mat-slider.mat-warn .mat-slider-thumb,
.app.grey .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #f44336;
}
.app.grey .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.app.grey .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}
.app.grey .mat-slider:hover .mat-slider-track-background,
.app.grey .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.grey .mat-slider.mat-slider-disabled .mat-slider-track-background,
.app.grey .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.app.grey .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.grey .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.grey .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.grey .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.app.grey .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.app.grey .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.app.grey .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.grey .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.app.grey .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .app.grey .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.grey .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .app.grey .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.app.grey .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.app.grey .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.app.grey .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.app.grey .mat-menu-panel {
  background: white;
}
.app.grey .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.app.grey .mat-menu-item[disabled],
.app.grey .mat-menu-item[disabled] .mat-menu-submenu-icon,
.app.grey .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.app.grey .mat-menu-item .mat-icon-no-color,
.app.grey .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.app.grey .mat-menu-item:hover:not([disabled]),
.app.grey .mat-menu-item.cdk-program-focused:not([disabled]),
.app.grey .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.app.grey .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.app.grey .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.app.grey .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.app.grey .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.app.grey .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.app.grey .mat-list-option:hover, .app.grey .mat-list-option:focus,
.app.grey .mat-nav-list .mat-list-item:hover,
.app.grey .mat-nav-list .mat-list-item:focus,
.app.grey .mat-action-list .mat-list-item:hover,
.app.grey .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.app.grey .mat-list-single-selected-option, .app.grey .mat-list-single-selected-option:hover, .app.grey .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.app.grey .mat-paginator {
  background: white;
}
.app.grey .mat-paginator,
.app.grey .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.app.grey .mat-paginator-decrement,
.app.grey .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.app.grey .mat-paginator-first,
.app.grey .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.app.grey .mat-icon-button[disabled] .mat-paginator-decrement,
.app.grey .mat-icon-button[disabled] .mat-paginator-increment,
.app.grey .mat-icon-button[disabled] .mat-paginator-first,
.app.grey .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.grey .mat-paginator-container {
  min-height: 56px;
}
.app.grey .mat-tab-nav-bar,
.app.grey .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.app.grey .mat-tab-group-inverted-header .mat-tab-nav-bar,
.app.grey .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.app.grey .mat-tab-label, .app.grey .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.app.grey .mat-tab-label.mat-tab-disabled, .app.grey .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.app.grey .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.app.grey .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.grey .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.app.grey .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.app.grey .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused, .app.grey .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused,
.app.grey .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused,
.app.grey .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused, .app.grey .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused, .app.grey .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused,
.app.grey .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused,
.app.grey .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused {
  background-color: rgba(236, 239, 241, 0.3);
}
.app.grey .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.grey .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.grey .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.grey .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled, .app.grey .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.grey .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.grey .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.grey .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.grey .mat-tab-group.mat-primary .mat-ink-bar, .app.grey .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #455a64;
}
.app.grey .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .app.grey .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .app.grey .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .app.grey .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.app.grey .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused, .app.grey .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused,
.app.grey .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused,
.app.grey .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused, .app.grey .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused, .app.grey .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused,
.app.grey .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused,
.app.grey .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused {
  background-color: rgba(200, 230, 201, 0.3);
}
.app.grey .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.grey .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.grey .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.grey .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled, .app.grey .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.grey .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.grey .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.grey .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.grey .mat-tab-group.mat-accent .mat-ink-bar, .app.grey .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #43a047;
}
.app.grey .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .app.grey .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .app.grey .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .app.grey .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.app.grey .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused, .app.grey .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused,
.app.grey .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused,
.app.grey .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused, .app.grey .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused, .app.grey .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused,
.app.grey .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused,
.app.grey .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused {
  background-color: rgba(255, 82, 82, 0.3);
}
.app.grey .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.grey .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.grey .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.grey .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled, .app.grey .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.grey .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.grey .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.grey .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.grey .mat-tab-group.mat-warn .mat-ink-bar, .app.grey .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336;
}
.app.grey .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .app.grey .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .app.grey .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .app.grey .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.app.grey .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused, .app.grey .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused,
.app.grey .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused,
.app.grey .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused, .app.grey .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused, .app.grey .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused,
.app.grey .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused,
.app.grey .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused {
  background-color: rgba(236, 239, 241, 0.3);
}
.app.grey .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.grey .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.grey .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.grey .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled, .app.grey .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.grey .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.grey .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.grey .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.grey .mat-tab-group.mat-background-primary > .mat-tab-header, .app.grey .mat-tab-group.mat-background-primary > .mat-tab-link-container, .app.grey .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .app.grey .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .app.grey .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .app.grey .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #455a64;
}
.app.grey .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .app.grey .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .app.grey .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .app.grey .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.app.grey .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.grey .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .app.grey .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.grey .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.grey .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.grey .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.grey .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.app.grey .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .app.grey .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.grey .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.grey .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.app.grey .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.app.grey .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.grey .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.grey .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.grey .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.app.grey .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.app.grey .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.app.grey .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .app.grey .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.app.grey .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.app.grey .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.app.grey .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused, .app.grey .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused,
.app.grey .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused,
.app.grey .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused, .app.grey .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused, .app.grey .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused,
.app.grey .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused,
.app.grey .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused {
  background-color: rgba(200, 230, 201, 0.3);
}
.app.grey .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.grey .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.grey .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.grey .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled, .app.grey .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.grey .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.grey .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.grey .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.grey .mat-tab-group.mat-background-accent > .mat-tab-header, .app.grey .mat-tab-group.mat-background-accent > .mat-tab-link-container, .app.grey .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .app.grey .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .app.grey .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .app.grey .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #43a047;
}
.app.grey .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .app.grey .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .app.grey .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .app.grey .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.app.grey .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.grey .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .app.grey .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.grey .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.grey .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.grey .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.grey .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.app.grey .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .app.grey .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.grey .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.grey .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.app.grey .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.app.grey .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.grey .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.grey .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.grey .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.app.grey .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.app.grey .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.app.grey .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .app.grey .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.app.grey .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.app.grey .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.app.grey .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused, .app.grey .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused,
.app.grey .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused,
.app.grey .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused, .app.grey .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused, .app.grey .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused,
.app.grey .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused,
.app.grey .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused {
  background-color: rgba(255, 82, 82, 0.3);
}
.app.grey .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.grey .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.grey .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.grey .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled, .app.grey .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.grey .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.grey .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.grey .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.grey .mat-tab-group.mat-background-warn > .mat-tab-header, .app.grey .mat-tab-group.mat-background-warn > .mat-tab-link-container, .app.grey .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .app.grey .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .app.grey .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .app.grey .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #f44336;
}
.app.grey .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .app.grey .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .app.grey .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .app.grey .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.app.grey .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.grey .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .app.grey .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.grey .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.grey .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.grey .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.grey .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.app.grey .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .app.grey .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.grey .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.grey .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.app.grey .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.app.grey .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.grey .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.grey .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.grey .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.app.grey .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.app.grey .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.app.grey .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .app.grey .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.app.grey .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.app.grey .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.app.grey .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-simple-snackbar-action {
  color: #43a047;
}
.app.grey .mat-table {
  background: white;
}
.app.grey .mat-table thead, .app.grey .mat-table tbody, .app.grey .mat-table tfoot,
.app.grey mat-header-row, .app.grey mat-row, .app.grey mat-footer-row,
.app.grey [mat-header-row], .app.grey [mat-row], .app.grey [mat-footer-row],
.app.grey .mat-table-sticky {
  background: inherit;
}
.app.grey mat-row, .app.grey mat-header-row, .app.grey mat-footer-row,
.app.grey th.mat-header-cell, .app.grey td.mat-cell, .app.grey td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.app.grey .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.app.grey .mat-cell, .app.grey .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.app.grey .mat-badge {
  position: relative;
}
.app.grey .mat-badge.mat-badge {
  overflow: visible;
}
.app.grey .mat-badge-hidden .mat-badge-content {
  display: none;
}
.app.grey .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.app.grey .ng-animate-disabled .mat-badge-content,
.app.grey .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.app.grey .mat-badge-content.mat-badge-active {
  transform: none;
}
.app.grey .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.app.grey .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.app.grey .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.app.grey .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .app.grey .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.app.grey .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .app.grey .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.app.grey .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .app.grey .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.app.grey .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .app.grey .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.app.grey .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.app.grey .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.app.grey .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.app.grey .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .app.grey .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.app.grey .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .app.grey .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.app.grey .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .app.grey .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.app.grey .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .app.grey .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.app.grey .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.app.grey .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.app.grey .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.app.grey .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .app.grey .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.app.grey .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .app.grey .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.app.grey .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .app.grey .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.app.grey .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .app.grey .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.app.grey .mat-badge-content {
  color: white;
  background: #455a64;
}
.cdk-high-contrast-active .app.grey .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.app.grey .mat-badge-accent .mat-badge-content {
  background: #43a047;
  color: white;
}
.app.grey .mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}
.app.grey .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.app.grey .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.grey .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.app.grey .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.app.grey .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.app.grey .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.app.grey .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.grey .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.app.grey .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.app.grey .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.app.grey [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.app.grey .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.app.grey .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.app.grey .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.app.grey .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.app.grey .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.app.grey .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.app.grey .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.app.grey .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.app.grey .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}
.app.grey .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.app.grey .mat-datepicker-toggle,
.app.grey .mat-datepicker-content .mat-calendar-next-button,
.app.grey .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.app.grey .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.app.grey .mat-calendar-table-header,
.app.grey .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.grey .mat-calendar-body-cell-content,
.app.grey .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.app.grey .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.app.grey .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.app.grey .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.app.grey .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.grey .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.app.grey .mat-calendar-body-in-range::before {
  background: rgba(69, 90, 100, 0.2);
}
.app.grey .mat-calendar-body-comparison-identical,
.app.grey .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.grey .mat-calendar-body-comparison-bridge-start::before,
.app.grey [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(69, 90, 100, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.grey .mat-calendar-body-comparison-bridge-end::before,
.app.grey [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(69, 90, 100, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.grey .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.grey .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.grey .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.grey .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.grey .mat-calendar-body-selected {
  background-color: #455a64;
  color: white;
}
.app.grey .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(69, 90, 100, 0.4);
}
.app.grey .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.grey .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.grey .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(69, 90, 100, 0.3);
}
@media (hover: hover) {
  .app.grey .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(69, 90, 100, 0.3);
  }
}
.app.grey .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.grey .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(67, 160, 71, 0.2);
}
.app.grey .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.app.grey .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.grey .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.app.grey .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(67, 160, 71, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.grey .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.app.grey .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(67, 160, 71, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.grey .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.grey .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.grey .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.grey .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.grey .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #43a047;
  color: white;
}
.app.grey .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(67, 160, 71, 0.4);
}
.app.grey .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.grey .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.grey .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(67, 160, 71, 0.3);
}
@media (hover: hover) {
  .app.grey .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(67, 160, 71, 0.3);
  }
}
.app.grey .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.app.grey .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.app.grey .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.grey .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.app.grey .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.grey .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.app.grey .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.grey .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.grey .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.grey .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.grey .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.grey .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.app.grey .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.app.grey .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.grey .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.grey .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}
@media (hover: hover) {
  .app.grey .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 67, 54, 0.3);
  }
}
.app.grey .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-datepicker-toggle-active {
  color: #455a64;
}
.app.grey .mat-datepicker-toggle-active.mat-accent {
  color: #43a047;
}
.app.grey .mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}
.app.grey .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.app.grey .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  width: 40px;
  height: 40px;
  padding: 8px;
}
.app.grey .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.app.grey .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 40px;
  height: 40px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  margin-left: 0px;
}
.app.grey .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.app.grey .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 40px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 40px;
  transform: translate(-50%, -50%);
}
.app.grey .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}
.app.grey .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.grey .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.app.grey .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.grey .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.grey .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .app.grey .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .app.grey .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .app.grey .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.app.grey .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.app.grey .mat-expansion-panel-header-description,
.app.grey .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.app.grey .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.app.grey .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.app.grey .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.app.grey .mat-expansion-panel-header {
  height: 48px;
}
.app.grey .mat-expansion-panel-header.mat-expanded {
  height: 64px;
}
.app.grey .mat-icon.mat-primary {
  color: #455a64;
}
.app.grey .mat-icon.mat-accent {
  color: #43a047;
}
.app.grey .mat-icon.mat-warn {
  color: #f44336;
}
.app.grey .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app.grey .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.grey .mat-drawer.mat-drawer-push {
  background-color: white;
}
.app.grey .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.app.grey [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.app.grey [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app.grey .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.app.grey .mat-step-header.cdk-keyboard-focused, .app.grey .mat-step-header.cdk-program-focused, .app.grey .mat-step-header:hover:not([aria-disabled]), .app.grey .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.app.grey .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .app.grey .mat-step-header:hover {
    background: none;
  }
}
.app.grey .mat-step-header .mat-step-label,
.app.grey .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.app.grey .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.app.grey .mat-step-header .mat-step-icon-selected,
.app.grey .mat-step-header .mat-step-icon-state-done,
.app.grey .mat-step-header .mat-step-icon-state-edit {
  background-color: #455a64;
  color: white;
}
.app.grey .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.app.grey .mat-step-header.mat-accent .mat-step-icon-selected,
.app.grey .mat-step-header.mat-accent .mat-step-icon-state-done,
.app.grey .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #43a047;
  color: white;
}
.app.grey .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.app.grey .mat-step-header.mat-warn .mat-step-icon-selected,
.app.grey .mat-step-header.mat-warn .mat-step-icon-state-done,
.app.grey .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
.app.grey .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.app.grey .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.app.grey .mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}
.app.grey .mat-stepper-horizontal, .app.grey .mat-stepper-vertical {
  background-color: white;
}
.app.grey .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.app.grey .mat-horizontal-stepper-header::before,
.app.grey .mat-horizontal-stepper-header::after,
.app.grey .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.grey .mat-horizontal-stepper-header {
  height: 72px;
}
.app.grey .mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.app.grey .mat-vertical-stepper-header {
  padding: 24px 24px;
}
.app.grey .mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}
.app.grey .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .app.grey .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}
.app.grey .mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}
.app.grey .mat-sort-header-arrow {
  color: #757575;
}
.app.grey .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.app.grey .mat-toolbar.mat-primary {
  background: #455a64;
  color: white;
}
.app.grey .mat-toolbar.mat-accent {
  background: #43a047;
  color: white;
}
.app.grey .mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.app.grey .mat-toolbar .mat-form-field-underline,
.app.grey .mat-toolbar .mat-form-field-ripple,
.app.grey .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.app.grey .mat-toolbar .mat-form-field-label,
.app.grey .mat-toolbar .mat-focused .mat-form-field-label,
.app.grey .mat-toolbar .mat-select-value,
.app.grey .mat-toolbar .mat-select-arrow,
.app.grey .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.app.grey .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.app.grey .mat-toolbar-multiple-rows {
  min-height: 44px;
}
.app.grey .mat-toolbar-row, .app.grey .mat-toolbar-single-row {
  height: 44px;
}
@media (max-width: 599px) {
  .app.grey .mat-toolbar-multiple-rows {
    min-height: 56px;
  }
  .app.grey .mat-toolbar-row, .app.grey .mat-toolbar-single-row {
    height: 56px;
  }
}
.app.grey .mat-tree {
  background: white;
}
.app.grey .mat-tree-node,
.app.grey .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.app.grey .mat-tree-node {
  min-height: 48px;
}
.app.grey .bg-primary {
  background-color: var(--primary);
  color: white;
}
.app.grey .bg-accent {
  background-color: var(--accent);
  color: white;
}
.app.grey .bg-warn {
  background-color: var(--warn);
  color: white;
}
.app.grey .text-muted {
  color: rgba(0, 0, 0, 0.54) !important;
}
.app.grey .primary-color {
  color: #455a64;
}
.app.grey .secondary-color {
  color: #cfd8dc;
}
.app.grey .v-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.grey .divider {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.app.grey .top-toolbar.mat-toolbar .v-divider {
  background-color: rgba(236, 239, 241, 0.2);
}
.app.grey .border-bottom-lighter {
  border-bottom: 1px solid rgba(236, 239, 241, 0.2);
}
.app.grey .border-lighter {
  border-color: rgba(236, 239, 241, 0.2);
}
.app.grey .border-accent {
  border-color: #43a047;
}
.app.grey .border-primary {
  border-color: #455a64 !important;
}
.app.grey #top-toolbar {
  background-color: #607d8b;
  color: white;
}
.app.grey .toolbar-2 #top-toolbar {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.grey #main-toolbar {
  background: white;
}
.app.grey .toolbar-2 #main-toolbar {
  color: var(--primary);
}
.app.grey header .logo svg {
  fill: #455a64;
}
.app.grey header.toolbar-1.has-bg-image:not(.main-toolbar-fixed) .logo svg, .app.grey header.toolbar-1.has-bg-video:not(.main-toolbar-fixed) .logo svg {
  fill: #fff;
}
.app.grey header.toolbar-1.has-bg-image:not(.main-toolbar-fixed) .horizontal-menu .mat-button, .app.grey header.toolbar-1.has-bg-video:not(.main-toolbar-fixed) .horizontal-menu .mat-button {
  color: #fff;
}
.app.grey header.toolbar-1.has-bg-image:not(.main-toolbar-fixed) .horizontal-menu .mat-button.horizontal-active-link, .app.grey header.toolbar-1.has-bg-video:not(.main-toolbar-fixed) .horizontal-menu .mat-button.horizontal-active-link {
  background: rgba(69, 90, 100, 0.38);
}
.app.grey header.toolbar-1.has-bg-image:not(.main-toolbar-fixed) .mat-icon, .app.grey header.toolbar-1.has-bg-video:not(.main-toolbar-fixed) .mat-icon {
  color: #fff;
}
.app.grey header.toolbar-2 .horizontal-menu .mat-button {
  color: #fff;
}
.app.grey header.toolbar-2 .horizontal-menu .mat-button.horizontal-active-link {
  color: #fff;
}
.app.grey header.toolbar-2.has-bg-image:not(.main-toolbar-fixed) .horizontal-menu .mat-button.horizontal-active-link, .app.grey header.toolbar-2.has-bg-video:not(.main-toolbar-fixed) .horizontal-menu .mat-button.horizontal-active-link {
  background: rgba(69, 90, 100, 0.38);
}
.app.grey .header-carousel .slide-info .mat-card {
  background: rgba(255, 255, 255, 0.85);
}
.app.grey .header-carousel .slide-info .mat-card:hover {
  background: white;
}
.app.grey .user-menu .user-info {
  color: rgba(0, 0, 0, 0.87);
}
.app.grey main.content-offset-to-top .main-content-header {
  border-top: 5px solid #455a64;
}
.app.grey .map-info-window .si-content-wrapper {
  padding: 2px;
  color: var(--primary);
}
.app.grey .map-info-window .si-content-wrapper .si-content {
  max-width: 100% !important;
  max-height: 100% !important;
}
.app.grey .map-info-window .si-content-wrapper .si-content a {
  color: inherit;
  text-decoration: none;
}
.app.grey .map-info-window .si-pointer-bg-top {
  border-top-color: #455a64;
}
.app.grey .map-info-window .si-pointer-border-top {
  border-top-color: #455a64;
}
.app.grey .map-info-window .si-close-button {
  opacity: 1;
  right: 4px;
  top: 4px;
  padding: 0 6px;
  border-radius: 50%;
  color: var(--primary);
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.grey .map-info-window.si-has-border .si-content-wrapper {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.grey .property-item .title a,
.app.grey .compare-item .title a {
  color: rgba(0, 0, 0, 0.87);
}
.app.grey .property-item .title a:hover,
.app.grey .compare-item .title a:hover {
  color: #455a64;
}
.app.grey .features p:after,
.app.grey .compare-item .details .item {
  border-bottom: 1px dotted rgba(0, 0, 0, 0.12);
}
.app.grey .small-carousel .swiper-slide.active-thumb img {
  border-color: #455a64;
}
.app.grey .ps__thumb-y {
  background-color: #263238;
}
.app.grey .active-link {
  background-color: #455a64;
  color: #fff;
}
.app.grey .horizontal-active-link {
  background: rgba(0, 0, 0, 0.04);
  color: #455a64;
}
.app.grey .horizontal-sub-menu .mat-button, .app.grey .horizontal-sub-menu .mat-menu-item {
  color: rgba(0, 0, 0, 0.87);
}
.app.grey .horizontal-sub-menu .mat-button.horizontal-active-link, .app.grey .horizontal-sub-menu .mat-menu-item.horizontal-active-link {
  color: #455a64;
}
.app.grey .section .section-title:after,
.app.grey .section.testimonials .swiper-pagination-bullet-active {
  background-color: #455a64;
}
.app.grey .custom-table.mat-table .mat-header-row,
.app.grey .custom-table.mat-table .mat-row {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.grey .submit-property .step-section,
.app.grey .edit-property .step-section {
  background: rgba(250, 250, 250, 0.7);
}
.app.grey .input-file-container .mat-orphan-label {
  color: rgba(0, 0, 0, 0.87) !important;
}
.app.grey .nav-pills .nav-link.active, .app.grey .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #455a64;
}
.app.grey .btn-danger {
  color: #fff;
  background-color: #455a64;
  border-color: #455a64;
}
.app.grey .btn-danger:not(:disabled):not(.disabled):active, .app.grey .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #455a64;
  border-color: #455a64;
}
.app.grey .btn-danger:hover {
  color: #fff;
  background-color: #455a64;
  border-color: #455a64;
}
.app.grey .btn-outline-danger {
  color: #455a64;
  border-color: #455a64;
}
.app.grey .btn-outline-danger:not(:disabled):not(.disabled):active, .app.grey .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #455a64;
  border-color: #455a64;
}
.app.grey .btn-outline-danger:hover {
  color: #fff;
  background-color: #455a64;
  border-color: #455a64;
}
.app.grey .pr-clr {
  color: #455a64;
}
.app.grey .bg-primary {
  background-color: #455a64 !important;
}
.app.grey a {
  color: #455a64;
  text-decoration: none;
  background-color: transparent;
}
.app.grey .containers input:checked ~ .checkmark {
  background-color: #455a64;
}
.app.grey .check input:checked ~ .checkmarks {
  background-color: #455a64;
}
.app.grey .btn-outline-danger:not(:disabled):not(.disabled).active, .app.grey .btn-outline-danger:not(:disabled):not(.disabled):active, .app.grey .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #455a64;
  border-color: #455a64;
}
.app.orange-dark {
  /* When the radio button is checked, add a blue background */
  /* When the checkbox is checked, add a blue background */
}
.app.orange-dark .mat-button, .app.orange-dark .mat-icon-button, .app.orange-dark .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.app.orange-dark .mat-button.mat-primary, .app.orange-dark .mat-icon-button.mat-primary, .app.orange-dark .mat-stroked-button.mat-primary {
  color: #f4511e;
}
.app.orange-dark .mat-button.mat-accent, .app.orange-dark .mat-icon-button.mat-accent, .app.orange-dark .mat-stroked-button.mat-accent {
  color: #43a047;
}
.app.orange-dark .mat-button.mat-warn, .app.orange-dark .mat-icon-button.mat-warn, .app.orange-dark .mat-stroked-button.mat-warn {
  color: #ff5252;
}
.app.orange-dark .mat-button.mat-primary.mat-button-disabled, .app.orange-dark .mat-button.mat-accent.mat-button-disabled, .app.orange-dark .mat-button.mat-warn.mat-button-disabled, .app.orange-dark .mat-button.mat-button-disabled.mat-button-disabled, .app.orange-dark .mat-icon-button.mat-primary.mat-button-disabled, .app.orange-dark .mat-icon-button.mat-accent.mat-button-disabled, .app.orange-dark .mat-icon-button.mat-warn.mat-button-disabled, .app.orange-dark .mat-icon-button.mat-button-disabled.mat-button-disabled, .app.orange-dark .mat-stroked-button.mat-primary.mat-button-disabled, .app.orange-dark .mat-stroked-button.mat-accent.mat-button-disabled, .app.orange-dark .mat-stroked-button.mat-warn.mat-button-disabled, .app.orange-dark .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.app.orange-dark .mat-button.mat-primary .mat-button-focus-overlay, .app.orange-dark .mat-icon-button.mat-primary .mat-button-focus-overlay, .app.orange-dark .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #f4511e;
}
.app.orange-dark .mat-button.mat-accent .mat-button-focus-overlay, .app.orange-dark .mat-icon-button.mat-accent .mat-button-focus-overlay, .app.orange-dark .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #43a047;
}
.app.orange-dark .mat-button.mat-warn .mat-button-focus-overlay, .app.orange-dark .mat-icon-button.mat-warn .mat-button-focus-overlay, .app.orange-dark .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #ff5252;
}
.app.orange-dark .mat-button.mat-button-disabled .mat-button-focus-overlay, .app.orange-dark .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .app.orange-dark .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.app.orange-dark .mat-button .mat-ripple-element, .app.orange-dark .mat-icon-button .mat-ripple-element, .app.orange-dark .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.app.orange-dark .mat-button-focus-overlay {
  background: black;
}
.app.orange-dark .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-flat-button, .app.orange-dark .mat-raised-button, .app.orange-dark .mat-fab, .app.orange-dark .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.app.orange-dark .mat-flat-button.mat-primary, .app.orange-dark .mat-raised-button.mat-primary, .app.orange-dark .mat-fab.mat-primary, .app.orange-dark .mat-mini-fab.mat-primary {
  color: white;
}
.app.orange-dark .mat-flat-button.mat-accent, .app.orange-dark .mat-raised-button.mat-accent, .app.orange-dark .mat-fab.mat-accent, .app.orange-dark .mat-mini-fab.mat-accent {
  color: white;
}
.app.orange-dark .mat-flat-button.mat-warn, .app.orange-dark .mat-raised-button.mat-warn, .app.orange-dark .mat-fab.mat-warn, .app.orange-dark .mat-mini-fab.mat-warn {
  color: white;
}
.app.orange-dark .mat-flat-button.mat-primary.mat-button-disabled, .app.orange-dark .mat-flat-button.mat-accent.mat-button-disabled, .app.orange-dark .mat-flat-button.mat-warn.mat-button-disabled, .app.orange-dark .mat-flat-button.mat-button-disabled.mat-button-disabled, .app.orange-dark .mat-raised-button.mat-primary.mat-button-disabled, .app.orange-dark .mat-raised-button.mat-accent.mat-button-disabled, .app.orange-dark .mat-raised-button.mat-warn.mat-button-disabled, .app.orange-dark .mat-raised-button.mat-button-disabled.mat-button-disabled, .app.orange-dark .mat-fab.mat-primary.mat-button-disabled, .app.orange-dark .mat-fab.mat-accent.mat-button-disabled, .app.orange-dark .mat-fab.mat-warn.mat-button-disabled, .app.orange-dark .mat-fab.mat-button-disabled.mat-button-disabled, .app.orange-dark .mat-mini-fab.mat-primary.mat-button-disabled, .app.orange-dark .mat-mini-fab.mat-accent.mat-button-disabled, .app.orange-dark .mat-mini-fab.mat-warn.mat-button-disabled, .app.orange-dark .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.app.orange-dark .mat-flat-button.mat-primary, .app.orange-dark .mat-raised-button.mat-primary, .app.orange-dark .mat-fab.mat-primary, .app.orange-dark .mat-mini-fab.mat-primary {
  background-color: #f4511e;
}
.app.orange-dark .mat-flat-button.mat-accent, .app.orange-dark .mat-raised-button.mat-accent, .app.orange-dark .mat-fab.mat-accent, .app.orange-dark .mat-mini-fab.mat-accent {
  background-color: #43a047;
}
.app.orange-dark .mat-flat-button.mat-warn, .app.orange-dark .mat-raised-button.mat-warn, .app.orange-dark .mat-fab.mat-warn, .app.orange-dark .mat-mini-fab.mat-warn {
  background-color: #ff5252;
}
.app.orange-dark .mat-flat-button.mat-primary.mat-button-disabled, .app.orange-dark .mat-flat-button.mat-accent.mat-button-disabled, .app.orange-dark .mat-flat-button.mat-warn.mat-button-disabled, .app.orange-dark .mat-flat-button.mat-button-disabled.mat-button-disabled, .app.orange-dark .mat-raised-button.mat-primary.mat-button-disabled, .app.orange-dark .mat-raised-button.mat-accent.mat-button-disabled, .app.orange-dark .mat-raised-button.mat-warn.mat-button-disabled, .app.orange-dark .mat-raised-button.mat-button-disabled.mat-button-disabled, .app.orange-dark .mat-fab.mat-primary.mat-button-disabled, .app.orange-dark .mat-fab.mat-accent.mat-button-disabled, .app.orange-dark .mat-fab.mat-warn.mat-button-disabled, .app.orange-dark .mat-fab.mat-button-disabled.mat-button-disabled, .app.orange-dark .mat-mini-fab.mat-primary.mat-button-disabled, .app.orange-dark .mat-mini-fab.mat-accent.mat-button-disabled, .app.orange-dark .mat-mini-fab.mat-warn.mat-button-disabled, .app.orange-dark .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-flat-button.mat-primary .mat-ripple-element, .app.orange-dark .mat-raised-button.mat-primary .mat-ripple-element, .app.orange-dark .mat-fab.mat-primary .mat-ripple-element, .app.orange-dark .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.orange-dark .mat-flat-button.mat-accent .mat-ripple-element, .app.orange-dark .mat-raised-button.mat-accent .mat-ripple-element, .app.orange-dark .mat-fab.mat-accent .mat-ripple-element, .app.orange-dark .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.orange-dark .mat-flat-button.mat-warn .mat-ripple-element, .app.orange-dark .mat-raised-button.mat-warn .mat-ripple-element, .app.orange-dark .mat-fab.mat-warn .mat-ripple-element, .app.orange-dark .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.orange-dark .mat-stroked-button:not([class*=mat-elevation-z]), .app.orange-dark .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-fab:not([class*=mat-elevation-z]), .app.orange-dark .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .app.orange-dark .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .app.orange-dark .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-datepicker-content .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.app.orange-dark .mat-datepicker-content .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #f4511e;
}
.app.orange-dark .mat-datepicker-content .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #43a047;
}
.app.orange-dark .mat-datepicker-content .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #ff5252;
}
.app.orange-dark .mat-datepicker-content .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.app.orange-dark .mat-datepicker-content .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.app.orange-dark .mat-datepicker-content .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #f4511e;
  --mdc-filled-button-label-text-color: #fff;
}
.app.orange-dark .mat-datepicker-content .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #43a047;
  --mdc-filled-button-label-text-color: #fff;
}
.app.orange-dark .mat-datepicker-content .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #ff5252;
  --mdc-filled-button-label-text-color: #fff;
}
.app.orange-dark .mat-datepicker-content .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.app.orange-dark .mat-datepicker-content .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.app.orange-dark .mat-datepicker-content .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #f4511e;
  --mdc-protected-button-label-text-color: #fff;
}
.app.orange-dark .mat-datepicker-content .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #43a047;
  --mdc-protected-button-label-text-color: #fff;
}
.app.orange-dark .mat-datepicker-content .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #ff5252;
  --mdc-protected-button-label-text-color: #fff;
}
.app.orange-dark .mat-datepicker-content .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}
.app.orange-dark .mat-datepicker-content .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-datepicker-content .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.app.orange-dark .mat-datepicker-content .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #f4511e;
}
.app.orange-dark .mat-datepicker-content .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #43a047;
}
.app.orange-dark .mat-datepicker-content .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #ff5252;
}
.app.orange-dark .mat-datepicker-content .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-datepicker-content .mat-mdc-button, .app.orange-dark .mat-datepicker-content .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.app.orange-dark .mat-datepicker-content .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .app.orange-dark .mat-datepicker-content .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.app.orange-dark .mat-datepicker-content .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.orange-dark .mat-datepicker-content .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .app.orange-dark .mat-datepicker-content .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.orange-dark .mat-datepicker-content .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.orange-dark .mat-datepicker-content .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .app.orange-dark .mat-datepicker-content .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.orange-dark .mat-datepicker-content .mat-mdc-button.mat-primary, .app.orange-dark .mat-datepicker-content .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #f4511e;
  --mat-mdc-button-ripple-color: rgba(244, 81, 30, 0.1);
}
.app.orange-dark .mat-datepicker-content .mat-mdc-button.mat-accent, .app.orange-dark .mat-datepicker-content .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #43a047;
  --mat-mdc-button-ripple-color: rgba(67, 160, 71, 0.1);
}
.app.orange-dark .mat-datepicker-content .mat-mdc-button.mat-warn, .app.orange-dark .mat-datepicker-content .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #ff5252;
  --mat-mdc-button-ripple-color: rgba(255, 82, 82, 0.1);
}
.app.orange-dark .mat-datepicker-content .mat-mdc-raised-button, .app.orange-dark .mat-datepicker-content .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.app.orange-dark .mat-datepicker-content .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .app.orange-dark .mat-datepicker-content .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.app.orange-dark .mat-datepicker-content .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.orange-dark .mat-datepicker-content .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .app.orange-dark .mat-datepicker-content .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.orange-dark .mat-datepicker-content .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.orange-dark .mat-datepicker-content .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .app.orange-dark .mat-datepicker-content .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.orange-dark .mat-datepicker-content .mat-mdc-raised-button.mat-primary, .app.orange-dark .mat-datepicker-content .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.app.orange-dark .mat-datepicker-content .mat-mdc-raised-button.mat-accent, .app.orange-dark .mat-datepicker-content .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.app.orange-dark .mat-datepicker-content .mat-mdc-raised-button.mat-warn, .app.orange-dark .mat-datepicker-content .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.app.orange-dark .mat-datepicker-content .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.app.orange-dark .mat-datepicker-content .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.app.orange-dark .mat-datepicker-content .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .app.orange-dark .mat-datepicker-content .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.orange-dark .mat-datepicker-content .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.app.orange-dark .mat-datepicker-content .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #f4511e;
  --mat-mdc-button-ripple-color: rgba(244, 81, 30, 0.1);
}
.app.orange-dark .mat-datepicker-content .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #43a047;
  --mat-mdc-button-ripple-color: rgba(67, 160, 71, 0.1);
}
.app.orange-dark .mat-datepicker-content .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #ff5252;
  --mat-mdc-button-ripple-color: rgba(255, 82, 82, 0.1);
}
.app.orange-dark .mat-datepicker-content .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #f4511e;
}
.app.orange-dark .mat-datepicker-content .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #43a047;
}
.app.orange-dark .mat-datepicker-content .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #ff5252;
}
.app.orange-dark .mat-datepicker-content .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}
.app.orange-dark .mat-datepicker-content .mat-mdc-button.mat-mdc-button-base,
.app.orange-dark .mat-datepicker-content .mat-mdc-raised-button.mat-mdc-button-base,
.app.orange-dark .mat-datepicker-content .mat-mdc-unelevated-button.mat-mdc-button-base,
.app.orange-dark .mat-datepicker-content .mat-mdc-outlined-button.mat-mdc-button-base {
  height: 36px;
}
.app.orange-dark .mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base {
  width: 48px;
  height: 48px;
  padding: 12px;
}
.app.orange-dark .mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__focus-ring {
  max-height: 48px;
  max-width: 48px;
}
.app.orange-dark .mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 40px;
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 4px;
  margin-left: 4px;
}
.app.orange-dark .mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.app.orange-dark .mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 48px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 48px;
  transform: translate(-50%, -50%);
}
.app.orange-dark .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.app.orange-dark .mat-option:hover:not(.mat-option-disabled), .app.orange-dark .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.app.orange-dark .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.app.orange-dark .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.app.orange-dark .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.app.orange-dark .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f4511e;
}
.app.orange-dark .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #43a047;
}
.app.orange-dark .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff5252;
}
.app.orange-dark .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.orange-dark .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.orange-dark .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.orange-dark .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.app.orange-dark .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.app.orange-dark .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.app.orange-dark .mat-primary .mat-pseudo-checkbox-checked,
.app.orange-dark .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #f4511e;
}
.app.orange-dark .mat-pseudo-checkbox-checked,
.app.orange-dark .mat-pseudo-checkbox-indeterminate,
.app.orange-dark .mat-accent .mat-pseudo-checkbox-checked,
.app.orange-dark .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #43a047;
}
.app.orange-dark .mat-warn .mat-pseudo-checkbox-checked,
.app.orange-dark .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #ff5252;
}
.app.orange-dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.app.orange-dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.app.orange-dark .mat-app-background, .app.orange-dark.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app.orange-dark .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.app.orange-dark .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.orange-dark .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.app.orange-dark .mat-progress-bar-background {
  fill: #f9d0c3;
}
.app.orange-dark .mat-progress-bar-buffer {
  background-color: #f9d0c3;
}
.app.orange-dark .mat-progress-bar-fill::after {
  background-color: #f4511e;
}
.app.orange-dark .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #cce4cd;
}
.app.orange-dark .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #cce4cd;
}
.app.orange-dark .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #43a047;
}
.app.orange-dark .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #fbd0d0;
}
.app.orange-dark .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #fbd0d0;
}
.app.orange-dark .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #ff5252;
}
.app.orange-dark .mat-progress-spinner circle, .app.orange-dark .mat-spinner circle {
  stroke: #f4511e;
}
.app.orange-dark .mat-progress-spinner.mat-accent circle, .app.orange-dark .mat-spinner.mat-accent circle {
  stroke: #43a047;
}
.app.orange-dark .mat-progress-spinner.mat-warn circle, .app.orange-dark .mat-spinner.mat-warn circle {
  stroke: #ff5252;
}
.app.orange-dark .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.app.orange-dark .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.app.orange-dark .mat-input-element:disabled,
.app.orange-dark .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.app.orange-dark .mat-input-element {
  caret-color: #f4511e;
}
.app.orange-dark .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.orange-dark .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.orange-dark .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.orange-dark .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.orange-dark .mat-form-field.mat-accent .mat-input-element {
  caret-color: #43a047;
}
.app.orange-dark .mat-form-field.mat-warn .mat-input-element,
.app.orange-dark .mat-form-field-invalid .mat-input-element {
  caret-color: #ff5252;
}
.app.orange-dark .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #ff5252;
}
.app.orange-dark .mat-form-field-disabled .mat-form-field-prefix,
.app.orange-dark .mat-form-field-disabled .mat-form-field-suffix {
  color: rgba(0, 0, 0, 0.38);
}
.app.orange-dark .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.app.orange-dark .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.app.orange-dark .mat-form-field.mat-focused .mat-form-field-label {
  color: #f4511e;
}
.app.orange-dark .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #43a047;
}
.app.orange-dark .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #ff5252;
}
.app.orange-dark .mat-focused .mat-form-field-required-marker {
  color: #43a047;
}
.app.orange-dark .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.app.orange-dark .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #f4511e;
}
.app.orange-dark .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #43a047;
}
.app.orange-dark .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #ff5252;
}
.app.orange-dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #f4511e;
}
.app.orange-dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #43a047;
}
.app.orange-dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #ff5252;
}
.app.orange-dark .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #ff5252;
}
.app.orange-dark .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.app.orange-dark .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #ff5252;
}
.app.orange-dark .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.app.orange-dark .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #ff5252;
}
.app.orange-dark .mat-error {
  color: #ff5252;
}
.app.orange-dark .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.orange-dark .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.app.orange-dark .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.app.orange-dark .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app.orange-dark .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.app.orange-dark .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app.orange-dark .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.app.orange-dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.app.orange-dark .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.app.orange-dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.orange-dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.app.orange-dark .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.app.orange-dark .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #f4511e;
}
.app.orange-dark .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #43a047;
}
.app.orange-dark .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #ff5252;
}
.app.orange-dark .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #ff5252;
}
.app.orange-dark .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.orange-dark .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.app.orange-dark .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.app.orange-dark .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.orange-dark .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.app.orange-dark .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.app.orange-dark .mat-select-panel {
  background: white;
}
.app.orange-dark .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #f4511e;
}
.app.orange-dark .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #43a047;
}
.app.orange-dark .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #ff5252;
}
.app.orange-dark .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #ff5252;
}
.app.orange-dark .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.app.orange-dark .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.app.orange-dark .mat-checkbox-checkmark {
  fill: #fafafa;
}
.app.orange-dark .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.app.orange-dark .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.app.orange-dark .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .app.orange-dark .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #f4511e;
}
.app.orange-dark .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .app.orange-dark .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #43a047;
}
.app.orange-dark .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .app.orange-dark .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #ff5252;
}
.app.orange-dark .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .app.orange-dark .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.app.orange-dark .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.app.orange-dark .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.orange-dark .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.app.orange-dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.app.orange-dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #f4511e;
}
.app.orange-dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.app.orange-dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #43a047;
}
.app.orange-dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.app.orange-dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #ff5252;
}
.app.orange-dark .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.orange-dark .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.app.orange-dark .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.app.orange-dark .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.orange-dark .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.app.orange-dark .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.app.orange-dark .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.app.orange-dark .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.app.orange-dark .mat-chip.mat-standard-chip::after {
  background: black;
}
.app.orange-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #f4511e;
  color: white;
}
.app.orange-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.orange-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.orange-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #ff5252;
  color: white;
}
.app.orange-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.orange-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.orange-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #43a047;
  color: white;
}
.app.orange-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.orange-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.orange-dark .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #43a047;
}
.app.orange-dark .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(67, 160, 71, 0.54);
}
.app.orange-dark .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #43a047;
}
.app.orange-dark .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #f4511e;
}
.app.orange-dark .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 81, 30, 0.54);
}
.app.orange-dark .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #f4511e;
}
.app.orange-dark .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff5252;
}
.app.orange-dark .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 82, 82, 0.54);
}
.app.orange-dark .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #ff5252;
}
.app.orange-dark .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.app.orange-dark .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.app.orange-dark .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.orange-dark .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.app.orange-dark .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f4511e;
}
.app.orange-dark .mat-radio-button.mat-primary .mat-radio-inner-circle,
.app.orange-dark .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.orange-dark .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .app.orange-dark .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #f4511e;
}
.app.orange-dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #43a047;
}
.app.orange-dark .mat-radio-button.mat-accent .mat-radio-inner-circle,
.app.orange-dark .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.orange-dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .app.orange-dark .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #43a047;
}
.app.orange-dark .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff5252;
}
.app.orange-dark .mat-radio-button.mat-warn .mat-radio-inner-circle,
.app.orange-dark .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.orange-dark .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .app.orange-dark .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #ff5252;
}
.app.orange-dark .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.app.orange-dark .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.orange-dark .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.app.orange-dark .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.orange-dark .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.app.orange-dark .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.app.orange-dark .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.orange-dark .mat-slider.mat-primary .mat-slider-track-fill,
.app.orange-dark .mat-slider.mat-primary .mat-slider-thumb,
.app.orange-dark .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #f4511e;
}
.app.orange-dark .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.app.orange-dark .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(244, 81, 30, 0.2);
}
.app.orange-dark .mat-slider.mat-accent .mat-slider-track-fill,
.app.orange-dark .mat-slider.mat-accent .mat-slider-thumb,
.app.orange-dark .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #43a047;
}
.app.orange-dark .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.app.orange-dark .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(67, 160, 71, 0.2);
}
.app.orange-dark .mat-slider.mat-warn .mat-slider-track-fill,
.app.orange-dark .mat-slider.mat-warn .mat-slider-thumb,
.app.orange-dark .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #ff5252;
}
.app.orange-dark .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.app.orange-dark .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(255, 82, 82, 0.2);
}
.app.orange-dark .mat-slider:hover .mat-slider-track-background,
.app.orange-dark .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.orange-dark .mat-slider.mat-slider-disabled .mat-slider-track-background,
.app.orange-dark .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.app.orange-dark .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.orange-dark .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.orange-dark .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.app.orange-dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.app.orange-dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.app.orange-dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.orange-dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.app.orange-dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .app.orange-dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.orange-dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .app.orange-dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.app.orange-dark .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.app.orange-dark .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.app.orange-dark .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.app.orange-dark .mat-menu-panel {
  background: white;
}
.app.orange-dark .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.app.orange-dark .mat-menu-item[disabled],
.app.orange-dark .mat-menu-item[disabled] .mat-menu-submenu-icon,
.app.orange-dark .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.app.orange-dark .mat-menu-item .mat-icon-no-color,
.app.orange-dark .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.app.orange-dark .mat-menu-item:hover:not([disabled]),
.app.orange-dark .mat-menu-item.cdk-program-focused:not([disabled]),
.app.orange-dark .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.app.orange-dark .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.app.orange-dark .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.app.orange-dark .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.app.orange-dark .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.app.orange-dark .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.app.orange-dark .mat-list-option:hover, .app.orange-dark .mat-list-option:focus,
.app.orange-dark .mat-nav-list .mat-list-item:hover,
.app.orange-dark .mat-nav-list .mat-list-item:focus,
.app.orange-dark .mat-action-list .mat-list-item:hover,
.app.orange-dark .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.app.orange-dark .mat-list-single-selected-option, .app.orange-dark .mat-list-single-selected-option:hover, .app.orange-dark .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-paginator {
  background: white;
}
.app.orange-dark .mat-paginator,
.app.orange-dark .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.app.orange-dark .mat-paginator-decrement,
.app.orange-dark .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.app.orange-dark .mat-paginator-first,
.app.orange-dark .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.app.orange-dark .mat-icon-button[disabled] .mat-paginator-decrement,
.app.orange-dark .mat-icon-button[disabled] .mat-paginator-increment,
.app.orange-dark .mat-icon-button[disabled] .mat-paginator-first,
.app.orange-dark .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.orange-dark .mat-paginator-container {
  min-height: 56px;
}
.app.orange-dark .mat-tab-nav-bar,
.app.orange-dark .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-tab-group-inverted-header .mat-tab-nav-bar,
.app.orange-dark .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.app.orange-dark .mat-tab-label, .app.orange-dark .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.app.orange-dark .mat-tab-label.mat-tab-disabled, .app.orange-dark .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.app.orange-dark .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.app.orange-dark .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.orange-dark .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.app.orange-dark .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.app.orange-dark .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused, .app.orange-dark .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused,
.app.orange-dark .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused,
.app.orange-dark .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused, .app.orange-dark .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused, .app.orange-dark .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused,
.app.orange-dark .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused,
.app.orange-dark .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused {
  background-color: rgba(251, 233, 231, 0.3);
}
.app.orange-dark .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.orange-dark .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.orange-dark .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.orange-dark .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled, .app.orange-dark .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.orange-dark .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.orange-dark .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.orange-dark .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.orange-dark .mat-tab-group.mat-primary .mat-ink-bar, .app.orange-dark .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #f4511e;
}
.app.orange-dark .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .app.orange-dark .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .app.orange-dark .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .app.orange-dark .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.app.orange-dark .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused, .app.orange-dark .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused,
.app.orange-dark .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused,
.app.orange-dark .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused, .app.orange-dark .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused, .app.orange-dark .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused,
.app.orange-dark .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused,
.app.orange-dark .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused {
  background-color: rgba(200, 230, 201, 0.3);
}
.app.orange-dark .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.orange-dark .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.orange-dark .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.orange-dark .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled, .app.orange-dark .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.orange-dark .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.orange-dark .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.orange-dark .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.orange-dark .mat-tab-group.mat-accent .mat-ink-bar, .app.orange-dark .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #43a047;
}
.app.orange-dark .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .app.orange-dark .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .app.orange-dark .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .app.orange-dark .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.app.orange-dark .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused, .app.orange-dark .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused,
.app.orange-dark .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused,
.app.orange-dark .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused, .app.orange-dark .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused, .app.orange-dark .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused,
.app.orange-dark .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused,
.app.orange-dark .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused {
  background-color: rgba(255, 205, 210, 0.3);
}
.app.orange-dark .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.orange-dark .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.orange-dark .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.orange-dark .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled, .app.orange-dark .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.orange-dark .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.orange-dark .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.orange-dark .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.orange-dark .mat-tab-group.mat-warn .mat-ink-bar, .app.orange-dark .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #ff5252;
}
.app.orange-dark .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .app.orange-dark .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .app.orange-dark .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .app.orange-dark .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.app.orange-dark .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused, .app.orange-dark .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused,
.app.orange-dark .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused,
.app.orange-dark .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused, .app.orange-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused, .app.orange-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused,
.app.orange-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused,
.app.orange-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused {
  background-color: rgba(251, 233, 231, 0.3);
}
.app.orange-dark .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.orange-dark .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.orange-dark .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.orange-dark .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled, .app.orange-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.orange-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.orange-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.orange-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.orange-dark .mat-tab-group.mat-background-primary > .mat-tab-header, .app.orange-dark .mat-tab-group.mat-background-primary > .mat-tab-link-container, .app.orange-dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .app.orange-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .app.orange-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .app.orange-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #f4511e;
}
.app.orange-dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .app.orange-dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .app.orange-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .app.orange-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.app.orange-dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.orange-dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .app.orange-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.orange-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.orange-dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.orange-dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.orange-dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.app.orange-dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .app.orange-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.orange-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.orange-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.app.orange-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.app.orange-dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.orange-dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.orange-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.orange-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.app.orange-dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.app.orange-dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.app.orange-dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .app.orange-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.app.orange-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.app.orange-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.app.orange-dark .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused, .app.orange-dark .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused,
.app.orange-dark .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused,
.app.orange-dark .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused, .app.orange-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused, .app.orange-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused,
.app.orange-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused,
.app.orange-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused {
  background-color: rgba(200, 230, 201, 0.3);
}
.app.orange-dark .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.orange-dark .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.orange-dark .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.orange-dark .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled, .app.orange-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.orange-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.orange-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.orange-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.orange-dark .mat-tab-group.mat-background-accent > .mat-tab-header, .app.orange-dark .mat-tab-group.mat-background-accent > .mat-tab-link-container, .app.orange-dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .app.orange-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .app.orange-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .app.orange-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #43a047;
}
.app.orange-dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .app.orange-dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .app.orange-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .app.orange-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.app.orange-dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.orange-dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .app.orange-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.orange-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.orange-dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.orange-dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.orange-dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.app.orange-dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .app.orange-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.orange-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.orange-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.app.orange-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.app.orange-dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.orange-dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.orange-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.orange-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.app.orange-dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.app.orange-dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.app.orange-dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .app.orange-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.app.orange-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.app.orange-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.app.orange-dark .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused, .app.orange-dark .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused,
.app.orange-dark .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused,
.app.orange-dark .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused, .app.orange-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused, .app.orange-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused,
.app.orange-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused,
.app.orange-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused {
  background-color: rgba(255, 205, 210, 0.3);
}
.app.orange-dark .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.orange-dark .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.orange-dark .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.orange-dark .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled, .app.orange-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .app.orange-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.app.orange-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.app.orange-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.orange-dark .mat-tab-group.mat-background-warn > .mat-tab-header, .app.orange-dark .mat-tab-group.mat-background-warn > .mat-tab-link-container, .app.orange-dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .app.orange-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .app.orange-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .app.orange-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #ff5252;
}
.app.orange-dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .app.orange-dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .app.orange-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .app.orange-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.app.orange-dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.orange-dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .app.orange-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.orange-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.orange-dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.orange-dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.orange-dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.app.orange-dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .app.orange-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.orange-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.orange-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.app.orange-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.app.orange-dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.orange-dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.orange-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.orange-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.app.orange-dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.app.orange-dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.app.orange-dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .app.orange-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.app.orange-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.app.orange-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.app.orange-dark .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-simple-snackbar-action {
  color: #43a047;
}
.app.orange-dark .mat-table {
  background: white;
}
.app.orange-dark .mat-table thead, .app.orange-dark .mat-table tbody, .app.orange-dark .mat-table tfoot,
.app.orange-dark mat-header-row, .app.orange-dark mat-row, .app.orange-dark mat-footer-row,
.app.orange-dark [mat-header-row], .app.orange-dark [mat-row], .app.orange-dark [mat-footer-row],
.app.orange-dark .mat-table-sticky {
  background: inherit;
}
.app.orange-dark mat-row, .app.orange-dark mat-header-row, .app.orange-dark mat-footer-row,
.app.orange-dark th.mat-header-cell, .app.orange-dark td.mat-cell, .app.orange-dark td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.app.orange-dark .mat-cell, .app.orange-dark .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.app.orange-dark .mat-badge {
  position: relative;
}
.app.orange-dark .mat-badge.mat-badge {
  overflow: visible;
}
.app.orange-dark .mat-badge-hidden .mat-badge-content {
  display: none;
}
.app.orange-dark .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.app.orange-dark .ng-animate-disabled .mat-badge-content,
.app.orange-dark .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.app.orange-dark .mat-badge-content.mat-badge-active {
  transform: none;
}
.app.orange-dark .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.app.orange-dark .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.app.orange-dark .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.app.orange-dark .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .app.orange-dark .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.app.orange-dark .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .app.orange-dark .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.app.orange-dark .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .app.orange-dark .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.app.orange-dark .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .app.orange-dark .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.app.orange-dark .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.app.orange-dark .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.app.orange-dark .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.app.orange-dark .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .app.orange-dark .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.app.orange-dark .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .app.orange-dark .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.app.orange-dark .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .app.orange-dark .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.app.orange-dark .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .app.orange-dark .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.app.orange-dark .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.app.orange-dark .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.app.orange-dark .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.app.orange-dark .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .app.orange-dark .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.app.orange-dark .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .app.orange-dark .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.app.orange-dark .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .app.orange-dark .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.app.orange-dark .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .app.orange-dark .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.app.orange-dark .mat-badge-content {
  color: white;
  background: #f4511e;
}
.cdk-high-contrast-active .app.orange-dark .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.app.orange-dark .mat-badge-accent .mat-badge-content {
  background: #43a047;
  color: white;
}
.app.orange-dark .mat-badge-warn .mat-badge-content {
  color: white;
  background: #ff5252;
}
.app.orange-dark .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.app.orange-dark .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.orange-dark .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.app.orange-dark .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.app.orange-dark .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.app.orange-dark .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.app.orange-dark .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.app.orange-dark .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.app.orange-dark .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.app.orange-dark [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.app.orange-dark .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.app.orange-dark .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.app.orange-dark .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.app.orange-dark .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.app.orange-dark .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.app.orange-dark .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.app.orange-dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.app.orange-dark .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.app.orange-dark .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}
.app.orange-dark .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.app.orange-dark .mat-datepicker-toggle,
.app.orange-dark .mat-datepicker-content .mat-calendar-next-button,
.app.orange-dark .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.app.orange-dark .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-calendar-table-header,
.app.orange-dark .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.orange-dark .mat-calendar-body-cell-content,
.app.orange-dark .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.app.orange-dark .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.app.orange-dark .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.app.orange-dark .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.app.orange-dark .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.orange-dark .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.app.orange-dark .mat-calendar-body-in-range::before {
  background: rgba(244, 81, 30, 0.2);
}
.app.orange-dark .mat-calendar-body-comparison-identical,
.app.orange-dark .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.orange-dark .mat-calendar-body-comparison-bridge-start::before,
.app.orange-dark [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 81, 30, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.orange-dark .mat-calendar-body-comparison-bridge-end::before,
.app.orange-dark [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 81, 30, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.orange-dark .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.orange-dark .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.orange-dark .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.orange-dark .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.orange-dark .mat-calendar-body-selected {
  background-color: #f4511e;
  color: white;
}
.app.orange-dark .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 81, 30, 0.4);
}
.app.orange-dark .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.orange-dark .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.orange-dark .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 81, 30, 0.3);
}
@media (hover: hover) {
  .app.orange-dark .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 81, 30, 0.3);
  }
}
.app.orange-dark .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.orange-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(67, 160, 71, 0.2);
}
.app.orange-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.app.orange-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.orange-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.app.orange-dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(67, 160, 71, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.orange-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.app.orange-dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(67, 160, 71, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.orange-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.orange-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.orange-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.orange-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.orange-dark .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #43a047;
  color: white;
}
.app.orange-dark .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(67, 160, 71, 0.4);
}
.app.orange-dark .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.orange-dark .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.orange-dark .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(67, 160, 71, 0.3);
}
@media (hover: hover) {
  .app.orange-dark .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(67, 160, 71, 0.3);
  }
}
.app.orange-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(255, 82, 82, 0.2);
}
.app.orange-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.app.orange-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.orange-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.app.orange-dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 82, 82, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.orange-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.app.orange-dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 82, 82, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.orange-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.orange-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.orange-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.orange-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.orange-dark .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #ff5252;
  color: white;
}
.app.orange-dark .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 82, 82, 0.4);
}
.app.orange-dark .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.orange-dark .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.orange-dark .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 82, 82, 0.3);
}
@media (hover: hover) {
  .app.orange-dark .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 82, 82, 0.3);
  }
}
.app.orange-dark .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-datepicker-toggle-active {
  color: #f4511e;
}
.app.orange-dark .mat-datepicker-toggle-active.mat-accent {
  color: #43a047;
}
.app.orange-dark .mat-datepicker-toggle-active.mat-warn {
  color: #ff5252;
}
.app.orange-dark .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.app.orange-dark .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  width: 40px;
  height: 40px;
  padding: 8px;
}
.app.orange-dark .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.app.orange-dark .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 40px;
  height: 40px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  margin-left: 0px;
}
.app.orange-dark .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.app.orange-dark .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 40px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 40px;
  transform: translate(-50%, -50%);
}
.app.orange-dark .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}
.app.orange-dark .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.orange-dark .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .app.orange-dark .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .app.orange-dark .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .app.orange-dark .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.app.orange-dark .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.app.orange-dark .mat-expansion-panel-header-description,
.app.orange-dark .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.app.orange-dark .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.app.orange-dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.app.orange-dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.app.orange-dark .mat-expansion-panel-header {
  height: 48px;
}
.app.orange-dark .mat-expansion-panel-header.mat-expanded {
  height: 64px;
}
.app.orange-dark .mat-icon.mat-primary {
  color: #f4511e;
}
.app.orange-dark .mat-icon.mat-accent {
  color: #43a047;
}
.app.orange-dark .mat-icon.mat-warn {
  color: #ff5252;
}
.app.orange-dark .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app.orange-dark .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.orange-dark .mat-drawer.mat-drawer-push {
  background-color: white;
}
.app.orange-dark .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.app.orange-dark [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.app.orange-dark [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.app.orange-dark .mat-step-header.cdk-keyboard-focused, .app.orange-dark .mat-step-header.cdk-program-focused, .app.orange-dark .mat-step-header:hover:not([aria-disabled]), .app.orange-dark .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.app.orange-dark .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .app.orange-dark .mat-step-header:hover {
    background: none;
  }
}
.app.orange-dark .mat-step-header .mat-step-label,
.app.orange-dark .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.app.orange-dark .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.app.orange-dark .mat-step-header .mat-step-icon-selected,
.app.orange-dark .mat-step-header .mat-step-icon-state-done,
.app.orange-dark .mat-step-header .mat-step-icon-state-edit {
  background-color: #f4511e;
  color: white;
}
.app.orange-dark .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.app.orange-dark .mat-step-header.mat-accent .mat-step-icon-selected,
.app.orange-dark .mat-step-header.mat-accent .mat-step-icon-state-done,
.app.orange-dark .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #43a047;
  color: white;
}
.app.orange-dark .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.app.orange-dark .mat-step-header.mat-warn .mat-step-icon-selected,
.app.orange-dark .mat-step-header.mat-warn .mat-step-icon-state-done,
.app.orange-dark .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #ff5252;
  color: white;
}
.app.orange-dark .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #ff5252;
}
.app.orange-dark .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.app.orange-dark .mat-step-header .mat-step-label.mat-step-label-error {
  color: #ff5252;
}
.app.orange-dark .mat-stepper-horizontal, .app.orange-dark .mat-stepper-vertical {
  background-color: white;
}
.app.orange-dark .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-horizontal-stepper-header::before,
.app.orange-dark .mat-horizontal-stepper-header::after,
.app.orange-dark .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.orange-dark .mat-horizontal-stepper-header {
  height: 72px;
}
.app.orange-dark .mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.app.orange-dark .mat-vertical-stepper-header {
  padding: 24px 24px;
}
.app.orange-dark .mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}
.app.orange-dark .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .app.orange-dark .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}
.app.orange-dark .mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}
.app.orange-dark .mat-sort-header-arrow {
  color: #757575;
}
.app.orange-dark .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.app.orange-dark .mat-toolbar.mat-primary {
  background: #f4511e;
  color: white;
}
.app.orange-dark .mat-toolbar.mat-accent {
  background: #43a047;
  color: white;
}
.app.orange-dark .mat-toolbar.mat-warn {
  background: #ff5252;
  color: white;
}
.app.orange-dark .mat-toolbar .mat-form-field-underline,
.app.orange-dark .mat-toolbar .mat-form-field-ripple,
.app.orange-dark .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.app.orange-dark .mat-toolbar .mat-form-field-label,
.app.orange-dark .mat-toolbar .mat-focused .mat-form-field-label,
.app.orange-dark .mat-toolbar .mat-select-value,
.app.orange-dark .mat-toolbar .mat-select-arrow,
.app.orange-dark .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.app.orange-dark .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.app.orange-dark .mat-toolbar-multiple-rows {
  min-height: 44px;
}
.app.orange-dark .mat-toolbar-row, .app.orange-dark .mat-toolbar-single-row {
  height: 44px;
}
@media (max-width: 599px) {
  .app.orange-dark .mat-toolbar-multiple-rows {
    min-height: 56px;
  }
  .app.orange-dark .mat-toolbar-row, .app.orange-dark .mat-toolbar-single-row {
    height: 56px;
  }
}
.app.orange-dark .mat-tree {
  background: white;
}
.app.orange-dark .mat-tree-node,
.app.orange-dark .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.app.orange-dark .mat-tree-node {
  min-height: 48px;
}
.app.orange-dark .bg-primary {
  background-color: var(--primary);
  color: white;
}
.app.orange-dark .bg-accent {
  background-color: var(--accent);
  color: white;
}
.app.orange-dark .bg-warn {
  background-color: var(--warn);
  color: white;
}
.app.orange-dark .text-muted {
  color: rgba(0, 0, 0, 0.54) !important;
}
.app.orange-dark .primary-color {
  color: #f4511e;
}
.app.orange-dark .secondary-color {
  color: #ffccbc;
}
.app.orange-dark .v-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.orange-dark .divider {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.app.orange-dark .top-toolbar.mat-toolbar .v-divider {
  background-color: rgba(251, 233, 231, 0.2);
}
.app.orange-dark .border-bottom-lighter {
  border-bottom: 1px solid rgba(251, 233, 231, 0.2);
}
.app.orange-dark .border-lighter {
  border-color: rgba(251, 233, 231, 0.2);
}
.app.orange-dark .border-accent {
  border-color: #43a047;
}
.app.orange-dark .border-primary {
  border-color: #f4511e !important;
}
.app.orange-dark #top-toolbar {
  background-color: #ff5722;
  color: white;
}
.app.orange-dark .toolbar-2 #top-toolbar {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.orange-dark #main-toolbar {
  background: white;
}
.app.orange-dark .toolbar-2 #main-toolbar {
  color: var(--primary);
}
.app.orange-dark header .logo svg {
  fill: #f4511e;
}
.app.orange-dark header.toolbar-1.has-bg-image:not(.main-toolbar-fixed) .logo svg, .app.orange-dark header.toolbar-1.has-bg-video:not(.main-toolbar-fixed) .logo svg {
  fill: #fff;
}
.app.orange-dark header.toolbar-1.has-bg-image:not(.main-toolbar-fixed) .horizontal-menu .mat-button, .app.orange-dark header.toolbar-1.has-bg-video:not(.main-toolbar-fixed) .horizontal-menu .mat-button {
  color: #fff;
}
.app.orange-dark header.toolbar-1.has-bg-image:not(.main-toolbar-fixed) .horizontal-menu .mat-button.horizontal-active-link, .app.orange-dark header.toolbar-1.has-bg-video:not(.main-toolbar-fixed) .horizontal-menu .mat-button.horizontal-active-link {
  background: rgba(244, 81, 30, 0.38);
}
.app.orange-dark header.toolbar-1.has-bg-image:not(.main-toolbar-fixed) .mat-icon, .app.orange-dark header.toolbar-1.has-bg-video:not(.main-toolbar-fixed) .mat-icon {
  color: #fff;
}
.app.orange-dark header.toolbar-2 .horizontal-menu .mat-button {
  color: #fff;
}
.app.orange-dark header.toolbar-2 .horizontal-menu .mat-button.horizontal-active-link {
  color: #fff;
}
.app.orange-dark header.toolbar-2.has-bg-image:not(.main-toolbar-fixed) .horizontal-menu .mat-button.horizontal-active-link, .app.orange-dark header.toolbar-2.has-bg-video:not(.main-toolbar-fixed) .horizontal-menu .mat-button.horizontal-active-link {
  background: rgba(244, 81, 30, 0.38);
}
.app.orange-dark .header-carousel .slide-info .mat-card {
  background: rgba(255, 255, 255, 0.85);
}
.app.orange-dark .header-carousel .slide-info .mat-card:hover {
  background: white;
}
.app.orange-dark .user-menu .user-info {
  color: rgba(0, 0, 0, 0.87);
}
.app.orange-dark main.content-offset-to-top .main-content-header {
  border-top: 5px solid #f4511e;
}
.app.orange-dark .map-info-window .si-content-wrapper {
  padding: 2px;
  color: var(--primary);
}
.app.orange-dark .map-info-window .si-content-wrapper .si-content {
  max-width: 100% !important;
  max-height: 100% !important;
}
.app.orange-dark .map-info-window .si-content-wrapper .si-content a {
  color: inherit;
  text-decoration: none;
}
.app.orange-dark .map-info-window .si-pointer-bg-top {
  border-top-color: #f4511e;
}
.app.orange-dark .map-info-window .si-pointer-border-top {
  border-top-color: #f4511e;
}
.app.orange-dark .map-info-window .si-close-button {
  opacity: 1;
  right: 4px;
  top: 4px;
  padding: 0 6px;
  border-radius: 50%;
  color: var(--primary);
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.orange-dark .map-info-window.si-has-border .si-content-wrapper {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.app.orange-dark .property-item .title a,
.app.orange-dark .compare-item .title a {
  color: rgba(0, 0, 0, 0.87);
}
.app.orange-dark .property-item .title a:hover,
.app.orange-dark .compare-item .title a:hover {
  color: #f4511e;
}
.app.orange-dark .features p:after,
.app.orange-dark .compare-item .details .item {
  border-bottom: 1px dotted rgba(0, 0, 0, 0.12);
}
.app.orange-dark .small-carousel .swiper-slide.active-thumb img {
  border-color: #f4511e;
}
.app.orange-dark .ps__thumb-y {
  background-color: #bf360c;
}
.app.orange-dark .active-link {
  background-color: #f4511e;
  color: #fff;
}
.app.orange-dark .horizontal-active-link {
  background: rgba(0, 0, 0, 0.04);
  color: #f4511e;
}
.app.orange-dark .horizontal-sub-menu .mat-button, .app.orange-dark .horizontal-sub-menu .mat-menu-item {
  color: rgba(0, 0, 0, 0.87);
}
.app.orange-dark .horizontal-sub-menu .mat-button.horizontal-active-link, .app.orange-dark .horizontal-sub-menu .mat-menu-item.horizontal-active-link {
  color: #f4511e;
}
.app.orange-dark .section .section-title:after,
.app.orange-dark .section.testimonials .swiper-pagination-bullet-active {
  background-color: #f4511e;
}
.app.orange-dark .custom-table.mat-table .mat-header-row,
.app.orange-dark .custom-table.mat-table .mat-row {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.orange-dark .submit-property .step-section,
.app.orange-dark .edit-property .step-section {
  background: rgba(250, 250, 250, 0.7);
}
.app.orange-dark .input-file-container .mat-orphan-label {
  color: rgba(0, 0, 0, 0.87) !important;
}
.app.orange-dark .nav-pills .nav-link.active, .app.orange-dark .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #f4511e;
}
.app.orange-dark .btn-danger {
  color: #fff;
  background-color: #f4511e;
  border-color: #f4511e;
}
.app.orange-dark .btn-danger:not(:disabled):not(.disabled):active, .app.orange-dark .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #f4511e;
  border-color: #f4511e;
}
.app.orange-dark .btn-danger:hover {
  color: #fff;
  background-color: #f4511e;
  border-color: #f4511e;
}
.app.orange-dark .btn-outline-danger {
  color: #f4511e;
  border-color: #f4511e;
}
.app.orange-dark .btn-outline-danger:not(:disabled):not(.disabled):active, .app.orange-dark .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #f4511e;
  border-color: #f4511e;
}
.app.orange-dark .btn-outline-danger:hover {
  color: #fff;
  background-color: #f4511e;
  border-color: #f4511e;
}
.app.orange-dark .pr-clr {
  color: #f4511e;
}
.app.orange-dark .bg-primary {
  background-color: #f4511e !important;
}
.app.orange-dark a {
  color: #f4511e;
  text-decoration: none;
  background-color: transparent;
}
.app.orange-dark .containers input:checked ~ .checkmark {
  background-color: #f4511e;
}
.app.orange-dark .check input:checked ~ .checkmarks {
  background-color: #f4511e;
}
.app.orange-dark .btn-outline-danger:not(:disabled):not(.disabled).active, .app.orange-dark .btn-outline-danger:not(:disabled):not(.disabled):active, .app.orange-dark .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #f4511e;
  border-color: #f4511e;
}

.app[dir=rtl] .options-icon {
  left: -4px;
}
.app[dir=rtl] .back-to-top {
  right: auto;
  left: 20px;
}
.app[dir=rtl] .user-menu .user-info img {
  margin-right: 0;
  margin-left: 16px;
}
.app[dir=rtl] .flag-name {
  margin-left: 0;
  margin-right: 4px;
}
.app[dir=rtl] .mat-icon.caret {
  direction: rtl;
}
.app[dir=rtl] .header-image-wrapper .bg {
  right: auto;
}
.app[dir=rtl] .swiper-container {
  direction: ltr;
}
.app[dir=rtl] .swiper-slide {
  direction: rtl;
}
.app[dir=rtl] .sidenav button.close {
  margin-left: 0;
  margin-right: 178px;
}
.app[dir=rtl] .all-properties .search-sidenav {
  float: right;
}
.app[dir=rtl] .all-properties .mat-drawer-content.distance {
  padding-left: 0;
  padding-right: 16px;
}
.app[dir=rtl] .property-item .address .mat-icon, .app[dir=rtl] .property-item .date .mat-icon {
  margin-left: 0 !important;
  margin-right: -4px;
}
.app[dir=rtl] .property-item .features p span:first-child {
  float: right !important;
  padding: 0 0 0 0.4em !important;
}
.app[dir=rtl] .property-item .features p span + span {
  float: left !important;
  padding: 0 0.4em 0 0 !important;
}
.app[dir=rtl] .property-item .control-icons {
  right: auto !important;
  left: 2px;
}
.app[dir=rtl] .compare-item .features p span {
  margin: 0 0 0 8px !important;
}
.app[dir=rtl] .single-property .mat-drawer .mat-drawer-inner-container button.close {
  right: auto;
  left: 2px;
}
.app[dir=rtl] .single-property .mat-drawer-content.distance {
  padding-right: 2px;
  padding-left: 16px;
}
.app[dir=rtl] .menu-expand-icon {
  right: auto;
  left: 10px;
}
.app[dir=rtl] .sub-menu .sub-menu .mat-button {
  padding-right: 56px;
}
.app[dir=rtl] .sub-menu .sub-menu .sub-menu .mat-button {
  padding-right: 76px;
}
.app[dir=rtl] .sub-menu .sub-menu .sub-menu .sub-menu .mat-button {
  padding-right: 96px;
}
.app[dir=rtl] .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .mat-button {
  padding-right: 116px;
}
.app[dir=rtl] .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .mat-button {
  padding-right: 136px;
}
.app[dir=rtl] .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .mat-button {
  padding-right: 156px;
}
.app[dir=rtl] .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .mat-button {
  padding-right: 176px;
}
.app[dir=rtl] .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .mat-button {
  padding-right: 196px;
}
.app[dir=rtl] .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .mat-button {
  padding-right: 216px;
}
.app[dir=rtl] .sub-menu .mat-button {
  padding-right: 36px;
}
.app[dir=rtl] .get-in-touch .content {
  padding-left: 0 !important;
  padding-right: 200px;
}
.app[dir=rtl] footer .content .custom-form .subscribe-btn {
  margin-left: 0;
  margin-right: -4px;
  border-radius: 4px 0 0 4px;
}
.app[dir=rtl] .lock-screen form .name {
  right: auto;
  left: 0;
}
.app[dir=rtl] .lock-screen form input {
  padding: 16px 16px 16px 44px;
  margin-left: 0;
  margin-right: -6px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  box-shadow: -3px -2px 4px -2px rgba(0, 0, 0, 0.2), -3px 2px 3px 1px rgba(0, 0, 0, 0.14), -3px 1px 2px -10px rgba(0, 0, 0, 0.12);
}
.app[dir=rtl] .lock-screen form a {
  left: -8px;
  right: auto;
}
.app[dir=rtl] .lock-screen form button.submit {
  margin-right: -44px;
  margin-left: 0;
}
.app[dir=rtl] .account .mat-drawer .mat-drawer-inner-container button.close {
  right: auto;
  left: 2px;
}
.app[dir=rtl] .account .mat-drawer-content.distance {
  padding-left: 2px;
  padding-right: 16px;
}
.app[dir=rtl] .theme-dialog .header {
  padding: 8px 24px 8px 8px;
}
.app[dir=rtl] .ps__rail-y {
  right: auto !important;
  left: 0 !important;
}
.app[dir=rtl] .ps__thumb-y {
  right: auto !important;
  left: 1px !important;
}

/** Colors variables */
/** share button variables */
/** share buttons colors */
/** Colors variables */
/** share button variables */
/** share buttons colors */
.sb-group,
.sb-button {
  display: inline-flex;
  align-items: flex-start;
}

.sb-group {
  flex-wrap: wrap;
}

.sb-button {
  margin: var(--sb-margin);
}

/** Default style */
.sb-wrapper {
  font-size: inherit;
  cursor: pointer;
  position: relative;
  outline: 0;
  min-width: 4.125em;
  height: 2.5em;
  border: none;
  border-radius: 1px;
  padding: 0;
  line-height: 2.571em;
  background-color: transparent;
  -webkit-print-color-adjust: exact;
}
.sb-wrapper .sb-icon,
.sb-wrapper .sb-text {
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}
.sb-wrapper .sb-content {
  flex: 1;
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
}
.sb-wrapper .sb-text {
  flex: 1;
  height: 100%;
  white-space: nowrap;
  padding: 0 0.7em;
}
.sb-wrapper .sb-icon {
  text-align: center;
  width: 100%;
  height: 100%;
  font-size: 1.2em;
  min-width: 2em;
}
.sb-wrapper .sb-text {
  font-weight: bold;
}

.sb-material-dark .sb-wrapper {
  background-color: #2E4057;
  background-color: var(--button-color) !important;
  font-family: "Roboto", sans-serif;
  color: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition: background 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}
.sb-material-dark .sb-wrapper.sb-facebook {
  background-color: #4267B2;
}
.sb-material-dark .sb-wrapper.sb-twitter {
  background-color: #00acee;
}
.sb-material-dark .sb-wrapper.sb-google {
  background-color: #db4437;
}
.sb-material-dark .sb-wrapper.sb-mix {
  background-color: #ff8226;
}
.sb-material-dark .sb-wrapper.sb-line {
  background-color: #00b900;
}
.sb-material-dark .sb-wrapper.sb-linkedin {
  background-color: #006fa6;
}
.sb-material-dark .sb-wrapper.sb-pinterest {
  background-color: #bd081c;
}
.sb-material-dark .sb-wrapper.sb-reddit {
  background-color: #ff4006;
}
.sb-material-dark .sb-wrapper.sb-tumblr {
  background-color: #36465d;
}
.sb-material-dark .sb-wrapper.sb-whatsapp {
  background-color: #25d366;
}
.sb-material-dark .sb-wrapper.sb-messenger {
  background-color: #0080FF;
}
.sb-material-dark .sb-wrapper.sb-telegram {
  background-color: #0088cc;
}
.sb-material-dark .sb-wrapper.sb-xing {
  background-color: #006567;
}
.sb-material-dark .sb-wrapper.sb-sms {
  background-color: #20c16c;
}
.sb-material-dark .sb-wrapper.sb-email {
  background-color: #FF961C;
}
.sb-material-dark .sb-wrapper.sb-viber {
  background-color: #665ca7;
}
.sb-material-dark .sb-wrapper.sb-vk {
  background-color: #4C75A3;
}
.sb-material-dark .sb-wrapper.sb-copy {
  background-color: #607D8B;
}
.sb-material-dark .sb-wrapper.sb-print {
  background-color: #765AA2;
}
.sb-material-dark .sb-wrapper.sb-expand {
  background-color: #FF6651;
}
.sb-material-dark .sb-wrapper:active {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.sb-material-dark .sb-wrapper.sb-show-icon.sb-show-text {
  padding-left: 0.3em;
}
.sb-material-dark .sb-wrapper.sb-show-icon.sb-show-text .sb-text {
  padding-right: 1em;
  padding-left: 0;
}

/** Colors variables */
/** share button variables */
/** share buttons colors */
/** Colors variables */
/** share button variables */
/** share buttons colors */
.sb-group,
.sb-button {
  display: inline-flex;
  align-items: flex-start;
}

.sb-group {
  flex-wrap: wrap;
}

.sb-button {
  margin: var(--sb-margin);
}

/** Default style */
.sb-wrapper {
  font-size: inherit;
  cursor: pointer;
  position: relative;
  outline: 0;
  min-width: 4.125em;
  height: 2.5em;
  border: none;
  border-radius: 1px;
  padding: 0;
  line-height: 2.571em;
  background-color: transparent;
  -webkit-print-color-adjust: exact;
}
.sb-wrapper .sb-icon,
.sb-wrapper .sb-text {
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}
.sb-wrapper .sb-content {
  flex: 1;
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
}
.sb-wrapper .sb-text {
  flex: 1;
  height: 100%;
  white-space: nowrap;
  padding: 0 0.7em;
}
.sb-wrapper .sb-icon {
  text-align: center;
  width: 100%;
  height: 100%;
  font-size: 1.2em;
  min-width: 2em;
}
.sb-wrapper .sb-text {
  font-weight: bold;
}

.sb-modern-light .sb-wrapper {
  color: var(--button-color) !important;
  background-color: #fff;
  transition: box-shadow ease-in 150ms, transform ease-in 150ms;
  border-radius: 3px;
  box-shadow: 0 0.1em 0.5em rgba(165, 165, 165, 0.6);
}
.sb-modern-light .sb-wrapper.sb-facebook {
  color: #4267B2;
}
.sb-modern-light .sb-wrapper.sb-twitter {
  color: #00acee;
}
.sb-modern-light .sb-wrapper.sb-google {
  color: #db4437;
}
.sb-modern-light .sb-wrapper.sb-mix {
  color: #ff8226;
}
.sb-modern-light .sb-wrapper.sb-line {
  color: #00b900;
}
.sb-modern-light .sb-wrapper.sb-linkedin {
  color: #006fa6;
}
.sb-modern-light .sb-wrapper.sb-pinterest {
  color: #bd081c;
}
.sb-modern-light .sb-wrapper.sb-reddit {
  color: #ff4006;
}
.sb-modern-light .sb-wrapper.sb-tumblr {
  color: #36465d;
}
.sb-modern-light .sb-wrapper.sb-whatsapp {
  color: #25d366;
}
.sb-modern-light .sb-wrapper.sb-messenger {
  color: #0080FF;
}
.sb-modern-light .sb-wrapper.sb-telegram {
  color: #0088cc;
}
.sb-modern-light .sb-wrapper.sb-xing {
  color: #006567;
}
.sb-modern-light .sb-wrapper.sb-sms {
  color: #20c16c;
}
.sb-modern-light .sb-wrapper.sb-email {
  color: #FF961C;
}
.sb-modern-light .sb-wrapper.sb-viber {
  color: #665ca7;
}
.sb-modern-light .sb-wrapper.sb-vk {
  color: #4C75A3;
}
.sb-modern-light .sb-wrapper.sb-copy {
  color: #607D8B;
}
.sb-modern-light .sb-wrapper.sb-print {
  color: #765AA2;
}
.sb-modern-light .sb-wrapper.sb-expand {
  color: #FF6651;
}
.sb-modern-light .sb-wrapper:active {
  box-shadow: 0 0.4em 1em rgba(165, 165, 165, 0.9);
}
.sb-modern-light .sb-wrapper .sb-icon {
  min-width: 2em;
}
.sb-modern-light .sb-wrapper.sb-show-text {
  padding: 0;
}
.sb-modern-light .sb-wrapper.sb-show-text .sb-icon {
  box-shadow: 1px 0 1px -1px rgba(0, 0, 0, 0.3);
}

/** Colors variables */
/** share button variables */
/** share buttons colors */
/** Colors variables */
/** share button variables */
/** share buttons colors */
.sb-group,
.sb-button {
  display: inline-flex;
  align-items: flex-start;
}

.sb-group {
  flex-wrap: wrap;
}

.sb-button {
  margin: var(--sb-margin);
}

/** Default style */
.sb-wrapper {
  font-size: inherit;
  cursor: pointer;
  position: relative;
  outline: 0;
  min-width: 4.125em;
  height: 2.5em;
  border: none;
  border-radius: 1px;
  padding: 0;
  line-height: 2.571em;
  background-color: transparent;
  -webkit-print-color-adjust: exact;
}
.sb-wrapper .sb-icon,
.sb-wrapper .sb-text {
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}
.sb-wrapper .sb-content {
  flex: 1;
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
}
.sb-wrapper .sb-text {
  flex: 1;
  height: 100%;
  white-space: nowrap;
  padding: 0 0.7em;
}
.sb-wrapper .sb-icon {
  text-align: center;
  width: 100%;
  height: 100%;
  font-size: 1.2em;
  min-width: 2em;
}
.sb-wrapper .sb-text {
  font-weight: bold;
}

.sb-circles-dark .sb-wrapper {
  color: #fff;
  background: transparent;
  min-width: unset;
  height: unset;
}
.sb-circles-dark .sb-wrapper.sb-facebook .sb-icon {
  background-color: #4267B2;
}
.sb-circles-dark .sb-wrapper.sb-facebook .sb-text {
  color: #4267B2;
}
.sb-circles-dark .sb-wrapper.sb-twitter .sb-icon {
  background-color: #00acee;
}
.sb-circles-dark .sb-wrapper.sb-twitter .sb-text {
  color: #00acee;
}
.sb-circles-dark .sb-wrapper.sb-google .sb-icon {
  background-color: #db4437;
}
.sb-circles-dark .sb-wrapper.sb-google .sb-text {
  color: #db4437;
}
.sb-circles-dark .sb-wrapper.sb-mix .sb-icon {
  background-color: #ff8226;
}
.sb-circles-dark .sb-wrapper.sb-mix .sb-text {
  color: #ff8226;
}
.sb-circles-dark .sb-wrapper.sb-line .sb-icon {
  background-color: #00b900;
}
.sb-circles-dark .sb-wrapper.sb-line .sb-text {
  color: #00b900;
}
.sb-circles-dark .sb-wrapper.sb-linkedin .sb-icon {
  background-color: #006fa6;
}
.sb-circles-dark .sb-wrapper.sb-linkedin .sb-text {
  color: #006fa6;
}
.sb-circles-dark .sb-wrapper.sb-pinterest .sb-icon {
  background-color: #bd081c;
}
.sb-circles-dark .sb-wrapper.sb-pinterest .sb-text {
  color: #bd081c;
}
.sb-circles-dark .sb-wrapper.sb-reddit .sb-icon {
  background-color: #ff4006;
}
.sb-circles-dark .sb-wrapper.sb-reddit .sb-text {
  color: #ff4006;
}
.sb-circles-dark .sb-wrapper.sb-tumblr .sb-icon {
  background-color: #36465d;
}
.sb-circles-dark .sb-wrapper.sb-tumblr .sb-text {
  color: #36465d;
}
.sb-circles-dark .sb-wrapper.sb-whatsapp .sb-icon {
  background-color: #25d366;
}
.sb-circles-dark .sb-wrapper.sb-whatsapp .sb-text {
  color: #25d366;
}
.sb-circles-dark .sb-wrapper.sb-messenger .sb-icon {
  background-color: #0080FF;
}
.sb-circles-dark .sb-wrapper.sb-messenger .sb-text {
  color: #0080FF;
}
.sb-circles-dark .sb-wrapper.sb-telegram .sb-icon {
  background-color: #0088cc;
}
.sb-circles-dark .sb-wrapper.sb-telegram .sb-text {
  color: #0088cc;
}
.sb-circles-dark .sb-wrapper.sb-xing .sb-icon {
  background-color: #006567;
}
.sb-circles-dark .sb-wrapper.sb-xing .sb-text {
  color: #006567;
}
.sb-circles-dark .sb-wrapper.sb-sms .sb-icon {
  background-color: #20c16c;
}
.sb-circles-dark .sb-wrapper.sb-sms .sb-text {
  color: #20c16c;
}
.sb-circles-dark .sb-wrapper.sb-email .sb-icon {
  background-color: #FF961C;
}
.sb-circles-dark .sb-wrapper.sb-email .sb-text {
  color: #FF961C;
}
.sb-circles-dark .sb-wrapper.sb-viber .sb-icon {
  background-color: #665ca7;
}
.sb-circles-dark .sb-wrapper.sb-viber .sb-text {
  color: #665ca7;
}
.sb-circles-dark .sb-wrapper.sb-vk .sb-icon {
  background-color: #4C75A3;
}
.sb-circles-dark .sb-wrapper.sb-vk .sb-text {
  color: #4C75A3;
}
.sb-circles-dark .sb-wrapper.sb-copy .sb-icon {
  background-color: #607D8B;
}
.sb-circles-dark .sb-wrapper.sb-copy .sb-text {
  color: #607D8B;
}
.sb-circles-dark .sb-wrapper.sb-print .sb-icon {
  background-color: #765AA2;
}
.sb-circles-dark .sb-wrapper.sb-print .sb-text {
  color: #765AA2;
}
.sb-circles-dark .sb-wrapper.sb-expand .sb-icon {
  background-color: #FF6651;
}
.sb-circles-dark .sb-wrapper.sb-expand .sb-text {
  color: #FF6651;
}
.sb-circles-dark .sb-wrapper:active .sb-icon {
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 12px 17px 2px rgba(0, 0, 0, 0.14), 0 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.sb-circles-dark .sb-wrapper .sb-icon {
  background-color: #2E4057;
  background-color: var(--button-color) !important;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  font-size: 1.4em;
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
  transition: background 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}
.sb-circles-dark .sb-wrapper .sb-text {
  color: var(--button-color) !important;
  font-size: 0.8em;
  margin-top: 5px;
  border-radius: 1em;
  width: 100%;
  height: 1.8em;
  line-height: 1.8em;
  padding: 0;
  transition: background 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}
.sb-circles-dark .sb-wrapper .sb-content {
  align-items: center;
  flex-direction: column;
}

.img-set {
  width: 100% !important;
  height: 100% !important;
}

.translateBtn {
  position: absolute !important;
  right: 5px;
  top: 5px;
}