@use '@angular/material' as mat;
@mixin theme-reset($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .bg-primary {
       // @include _mat-toolbar-color($primary);
       //@include _mat-toolbar-color($primary);
       background-color: var(--primary);
       color:white;
    }
    .bg-accent {
        //@include _mat-toolbar-color($accent);
        background-color: var(--accent);
       color:white;
    }
    .bg-warn {
        //@include _mat-toolbar-color($warn);
        background-color: var(--warn);
       color:white;
    }

    .text-muted {
        color: mat.get-color-from-palette($foreground, secondary-text) !important;
    }
    .primary-color{
        color: mat.get-color-from-palette($primary);
    }
    .secondary-color{
        color: mat.get-color-from-palette($primary, 100);
    }
    .v-divider{
        background-color: mat.get-color-from-palette($foreground, divider);
    }
    .divider{
        border-top: 1px solid mat.get-color-from-palette($foreground, divider);
    }

    .top-toolbar.mat-toolbar .v-divider{
        background-color: mat.get-color-from-palette($primary, lighter, 0.2);
    }
    .border-bottom-lighter{
        border-bottom: 1px solid mat.get-color-from-palette($primary, lighter, 0.2);
    }
    .border-lighter{
        border-color: mat.get-color-from-palette($primary, lighter, 0.2);
    }
    .border-accent{
        border-color: mat.get-color-from-palette($accent);        
    }
    .border-primary{
        border-color: mat.get-color-from-palette($primary) !important;        
    }

    #top-toolbar{
       // @include _mat-toolbar-color($primary);
       background-color: map-get($primary, 500);
       color:white;
    }
    .toolbar-2 #top-toolbar{
        background-color: mat.get-color-from-palette($background, card);
        color: mat.get-color-from-palette($foreground, text);
    }
    #main-toolbar{
        background: mat.get-color-from-palette($background, card);
    }
    .toolbar-2 #main-toolbar{
        //@include _mat-toolbar-color($primary);
        color: var(--primary);
    }
    header{
        .logo svg{
            fill: mat.get-color-from-palette($primary);
        } 
        &.toolbar-1{        
            &.has-bg-image:not(.main-toolbar-fixed), &.has-bg-video:not(.main-toolbar-fixed){
                .logo svg{
                    fill: #fff;
                }
                .horizontal-menu{
                    .mat-button{
                        color:#fff;
                        &.horizontal-active-link{
                            background: rgba(mat.get-color-from-palette($primary), 0.38);
                        }                       
                    }
                }                
                .mat-icon{
                    color:#fff;
                }
            } 
        }
        &.toolbar-2{
            .horizontal-menu{
                .mat-button{
                    color:#fff;
                    &.horizontal-active-link{
                        color:#fff;
                    }                       
                }
            }
            &.has-bg-image:not(.main-toolbar-fixed), &.has-bg-video:not(.main-toolbar-fixed){
                .horizontal-menu{
                    .mat-button{
                        &.horizontal-active-link{
                            background: rgba(mat.get-color-from-palette($primary), 0.38);
                        }                       
                    }
                }
            }  
            
        }      
    }

    .header-carousel .slide-info .mat-card{
        background: rgba(mat.get-color-from-palette($background, card), 0.85);
        &:hover{
            background: mat.get-color-from-palette($background, card);
        }
    }
    
    .user-menu .user-info {
        color: mat.get-color-from-palette($foreground, text);
    }

    main.content-offset-to-top{
        .main-content-header{
            border-top: 5px solid mat.get-color-from-palette($primary);
        }
    }

    .map-info-window{
        .si-content-wrapper{
            padding: 2px; 
            //@include _mat-toolbar-color($primary);
            color: var(--primary);
            .si-content{ 
                max-width: 100% !important;
                max-height: 100% !important;
                a{ 
                    color: inherit;
                    text-decoration: none; 
                }
            }
        }
        .si-pointer-bg-top {
            border-top-color: mat.get-color-from-palette($primary);
        }
        .si-pointer-border-top { 
            border-top-color: mat.get-color-from-palette($primary);
        }
        .si-close-button{
            opacity: 1;  
            right: 4px;
            top: 4px;  
            padding: 0 6px;
            border-radius: 50%;
            //@include _mat-toolbar-color($primary);
            color: var(--primary);
            box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 
                        0px 4px 5px 0px rgba(0, 0, 0, 0.14), 
                        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
        }
        &.si-has-border{
            .si-content-wrapper{
                border: 1px solid mat.get-color-from-palette($foreground, divider); 
            }
        }
    }

    .property-item .title a,
    .compare-item .title a{
        color: mat.get-color-from-palette($foreground, text);
        &:hover{
            color: mat.get-color-from-palette($primary);
        }        
    }
    .features p:after,
    .compare-item .details .item { 
        border-bottom: 1px dotted mat.get-color-from-palette($foreground, divider); 
    }

    .small-carousel .swiper-slide.active-thumb img{ 
        border-color: mat.get-color-from-palette($primary);
    }
  

    .ps__thumb-y{
        background-color: mat.get-color-from-palette($primary, 900);
    }


    .active-link{
        background-color: mat.get-color-from-palette($primary); 
        color: #fff;
    }
    .horizontal-active-link {
        background: mat.get-color-from-palette($background, hover);
        color: mat.get-color-from-palette($primary); 
    }
    .horizontal-sub-menu{ 
        .mat-button, .mat-menu-item{
            color: mat.get-color-from-palette($foreground, text);
            &.horizontal-active-link { 
                color: mat.get-color-from-palette($primary); 
            }
        }
    }
    
 
    .section .section-title:after,
    .section.testimonials .swiper-pagination-bullet-active {        
        background-color: mat.get-color-from-palette($primary); 
    }
     

    .custom-table.mat-table .mat-header-row, 
    .custom-table.mat-table .mat-row {
        border-color: mat.get-color-from-palette($foreground, divider);
    }

    .submit-property .step-section,
    .edit-property .step-section{
        background: rgba(mat.get-color-from-palette($background, background), 0.7);
    }
    .input-file-container .mat-orphan-label{
        color: mat.get-color-from-palette($foreground, text) !important;
    }

    .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
        color: #fff;
        background-color: mat.get-color-from-palette($primary);
    }
    .btn-danger {
        color: #fff;
        background-color: mat.get-color-from-palette($primary);
        border-color: mat.get-color-from-palette($primary);
    }

    .btn-danger:not(:disabled):not(.disabled):active, .show>.btn-danger.dropdown-toggle {
        color: #fff;
        background-color: mat.get-color-from-palette($primary);
        border-color: mat.get-color-from-palette($primary);
    }

    .btn-danger:hover {
        color: #fff;
        background-color: mat.get-color-from-palette($primary);
        border-color: mat.get-color-from-palette($primary);
    }

    .btn-outline-danger {
        color: mat.get-color-from-palette($primary);
        border-color: mat.get-color-from-palette($primary);
    }

    .btn-outline-danger:not(:disabled):not(.disabled):active, .show>.btn-danger.dropdown-toggle {
        color: #fff;
        background-color: mat.get-color-from-palette($primary);
        border-color: mat.get-color-from-palette($primary);
    }

    .btn-outline-danger:hover {
        color: #fff;
        background-color: mat.get-color-from-palette($primary);
        border-color: mat.get-color-from-palette($primary);
    }
    .pr-clr{
        color: mat.get-color-from-palette($primary);
    }
    .bg-primary{
        background-color: mat.get-color-from-palette($primary) !important;
    }
    a {
         color: mat.get-color-from-palette($primary);
        text-decoration: none;
        background-color: transparent;
    }

    /* When the radio button is checked, add a blue background */
  .containers input:checked ~ .checkmark {
    background-color: mat.get-color-from-palette($primary);
  }

   /* When the checkbox is checked, add a blue background */
   .check input:checked ~ .checkmarks {
    background-color: mat.get-color-from-palette($primary);
  }

  .btn-outline-danger:not(:disabled):not(.disabled).active, .btn-outline-danger:not(:disabled):not(.disabled):active, .show>.btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: mat.get-color-from-palette($primary);
    border-color: mat.get-color-from-palette($primary);
    }
}

.order{
    padding-left: 2rem;
}